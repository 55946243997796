import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Heading,
  Center,
  Button,
} from '@chakra-ui/react';

const PricingModal = ({ isOpen, onClose, userData }) => {
  const [showEnterpriseChart, setShowEnterpriseChart] = useState(false);

  const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const STRIPE_PRICINGTABLE_ID = process.env.REACT_APP_STRIPE_PRICINGTABLE_ID;
  const STRIPE_ENTERPRISETABLE_ID =
    process.env.REACT_APP_STRIPE_ENTERPRISE_PRICINGTABLE_ID;

  const handleEnterpriseChartShow = () => {
    setShowEnterpriseChart(true);
  };

  return (
    <Modal size='5xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center mb={10} mt={'5px'} fontSize={'sm'}>
            Oops! You've reached the limit of features under your current plan.
            Upgrade your Bambu.bot subscription today to enable this feature.{' '}
          </Center>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Heading mb={'10px'}>Upgrade to unlock paid features.</Heading>
            <Text fontSize='md'>
              Choose a plan that best fits your needs. You can upgrade or
              downgrade at any time.
            </Text>
            <Text fontSize='lg' color={'#091D62'}>
              * 14-Day money-back guarantee!
            </Text>
            <Text fontSize='lg' my={'10px'}>
              Get two months free with all Yearly Plans
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <stripe-pricing-table
            pricing-table-id={STRIPE_PRICINGTABLE_ID}
            publishable-key={STRIPE_PUBLISHABLE_KEY}
            client-reference-id={JSON.parse(userData).user_id}
            customer-email={JSON.parse(userData).email}
          ></stripe-pricing-table>
          <Center mb={'5px'}>
            <Text fontSize='xs'>
              Need More?{' '}
              <Button
                onClick={handleEnterpriseChartShow}
                size={'xs'}
                _hover={{ color: '#091D62' }}
                color={'#002298'}
                variant='link'
              >
                View our Entriprise options
              </Button>
            </Text>
          </Center>
          {showEnterpriseChart && (
            <>
              {/* Enterprise pricing table */}
              <stripe-pricing-table
                pricing-table-id={STRIPE_ENTERPRISETABLE_ID}
                publishable-key={STRIPE_PUBLISHABLE_KEY}
                client-reference-id={JSON.parse(userData).user_id}
                customer-email={JSON.parse(userData).email}
              ></stripe-pricing-table>
            </>
          )}
        </ModalBody>

        {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export const PricingChartModel = ({ isOpen, onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const storedData = localStorage.getItem('docsbot-users');
  const navigate = useNavigate();

  const plan_name = JSON.parse(storedData).subscription?.plan_name;
  // const Comp =()=>
  const handleClosePricingModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {plan_name === 'Free' ? (
        <PricingModal isOpen={isOpen} onClose={onClose} userData={storedData} />
      ) : (
        <Navigate to='/admin-dashboard/account' />
      )}

      {/* <PricingModal isOpen={isOpen} onClose={onClose} userData={storedData} /> */}
    </>
  );
};
