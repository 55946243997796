import { useCallback, useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowRight, MdOutlineQrCode } from 'react-icons/md';
import { FaInfo } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import WhatsAppIntegration from './Integration_components/WhatsAppIntegration';
import MessengerIcon from '../assets/integration/messenger.svg';
import LineIcon from '../assets/integration/line.svg';
import InstagramIcon from '../assets/integration/instagram.png';
import ShopeeIcon from '../assets/integration/shopee.png';
import LazadaIcon from '../assets/integration/lazada.png';
import withAccessControl from '../utils/withAccessControl';
import { PricingChartModel } from './PricingChartModel';
import FetchWithToken from '../utils/FetchWithToken';
import LineGuideModal from './Integration_components/LineGuideModal';
import LineIntegration from './Integration_components/LineIntegration';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import BotDropdown from './Integration_components/MenuItems';
import DisconnectPopup from './Integration_components/Disconnectpopup';

const Integration = ({ hasAccess, handleCallback, botData }) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [fbAuthId, setFbAuthId] = useState(searchParams.get('code'));
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const client_id = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_FACEBOOK_REDIRECT_URI;
  const toast = useToast();
  const userData = JSON.parse(localStorage.getItem('docsbot-users'));
  const [lineIntegrationDetails, setLineIntgrationDetails] = useState();
  const [faceBookIntegrationDetails, setFacebookIntgrationDetails] = useState();
  const [QRData, setQRData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBot, setSelectedBot] = useState('View channel');
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [allBotsData, setAllBotsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isConnected, setIsConnected] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const {
    isOpen: isOpenLineGuide,
    onOpen: onOpenLineGuide,
    onClose: onCloseLineGuide,
  } = useDisclosure();
  const {
    isOpen: inOpenLineForm,
    onOpen: onOpenLineForm,
    onClose: onCloseLineForm,
  } = useDisclosure();

  const selectedTeam = JSON.parse(
    localStorage.getItem('docsbot-users')
  ).selectedTeam;

  const handleConnect = () => {
    setLoading(true);
    setError(null);
    Botname();
    const facebookOAuthUrl = `${process.env.REACT_APP_FACEBOOK_OAUTH_URL}`;
    window.open(facebookOAuthUrl);

    setLoading(false);
    setIsConnected(true);
  };

  useEffect(() => {
    if (!fbAuthId) return;

    (() => {
      FetchWithToken(BASE_URL + '/fb/auth', 'POST', {
        auth_code: fbAuthId,
        user_id: userData.user_id,
      }).then((data) => {
        searchParams.delete('code');
        checkConnectionStatus();
      });
    })();
  }, [fbAuthId]);

  const checkConnectionStatus = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        BASE_URL + '/integrations?user_id=' + userData.user_id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch connection status');
      }

      const data = await response.json();
      setLineIntgrationDetails(data.line_integration_details);
      setFacebookIntgrationDetails(data.facebook_integration_details);
      setIsConnected(data.connected);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkConnectionStatus();
  }, []);

  const handleConnection = async () => {
    setLoading(true);

    try {
      setIsConnected(true); // Assume connection was successful
    } catch (err) {
      setError('Failed to connect');
    } finally {
      setLoading(false);
    }
  };

  const handleWhatsAppOpenModal = () => {
    hasAccess?.WHATSAPP_INTEGRATIONS?.enabled
      ? setShowWhatsAppModal(true)
      : setShowPricingModal(true);
  };

  const handleWhatsAppCloseModal = () => {
    setShowWhatsAppModal(false);
  };

  const handleClosePricingModal = () => {
    setShowPricingModal(false);
  };

  const Botname = async (platform) => {
    const payload = {
      platform: platform,
      bot_id: botData.bot_id,
      user_id: userData.user_id,
    };

    setLoadingState(true);
    setError(null);

    try {
      const response = await fetch('/gchat/bot-integration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Disconnected successfully:', data);
      setIsConnected(false);
    } catch (error) {
      console.error('Error disconnecting:', error);
      setError('Failed to disconnect. Please try again.');
    } finally {
      setLoadingState(false);
    }
  };

  const fetchData = useCallback(() => {
    FetchWithToken(
      BASE_URL + `/chatbots?team_id=${selectedTeam.team_id}`,
      'GET'
    ).then((data) => {
      setAllBotsData(data.bots);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   (() => {
  //     FetchWithToken(
  //       process.env.REACT_APP_BASE_URL +
  //         '/integrations/line?user_id=' +
  //         userData.user_id,
  //       'GET'
  //     )
  //       .then((data) => {
  //         if (!data.error) setQRData(data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   })();
  // }, []);

  useEffect(() => {
    const connectWithFacebook = async () => {
      setLoading(true);
      setError(null);

      const queryParams = new URLSearchParams(location.seFacebookarch);
      const authCode = queryParams.get('code');

      if (!authCode) {
        setLoading(false);
        return;
      }

      const userId = JSON.parse(localStorage.getItem('docsbot-users'));

      try {
        const response = await fetch('https://buddly.3exec.com/gchat/fb/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId?.user_id,
            auth_code: authCode,
          }),
        });

        const data = await response.json();

        if (!response.ok || data.error) {
          throw new Error(
            data.error || 'Failed to authenticate with Facebook.'
          );
        }

        console.log('Authentication successful:', data);
      } catch (err) {
        console.error('Error connecting with Facebook:', err);
        setError('Failed to connect to Facebook. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    connectWithFacebook();
  }, [location.search, navigate]);

  const disconnect = (platform) => {
    setLoading(true);
    FetchWithToken(
      `${process.env.REACT_APP_BASE_URL}/integrations/disconnect?user_id=${userData.user_id}&platform=${platform}`,
      'DELETE'
    )
      .then(() => {
        setQRData(null);
        setIsConnected(false);
        setLoading(false);
      })
      .catch((err) => {
        setError('Failed to disconnect');
        setLoading(false);
      });
  };

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: 'ID Copied!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Copy Failed!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  return (
    <Box bg={'#FFFFFF'} borderRadius={'10px'} height={'100%'}>
      <Box p={'10px 20px'}>
        <Text as={'b'}>{t('Integrations.Connected Apps')}</Text>
        <Text mt={1} fontSize="md">
          {t(
            'Integrations.Integrate with your favorite apps and supercharge your workflow'
          )}
        </Text>
        <Divider mt={3} />
        <SimpleGrid
          paddingTop={5}
          spacing={6}
          templateColumns="repeat(auto-fill, minmax(350px, 1fr))"
        >
          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={LineIcon} alt="BotIcon" width="50px" />
              <Box display={'flex'} gap="10px">
                {QRData !== undefined && (
                  <Button
                    bg=""
                    border={'1px solid rgba(33, 33, 33, 0.21)'}
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'full'}
                    _hover={{ bg: 'rgba(33, 33, 33, 0.21)', color: 'white' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding="0"
                    onClick={() => window.open(QRData?.line_bot_url, '_blank')}
                  >
                    <MdOutlineQrCode fill="#718096" />
                  </Button>
                )}
                <Button
                  onClick={onOpenLineGuide}
                  bg=""
                  border={'1px solid rgba(33, 33, 33, 0.21)'}
                  height={'40px'}
                  width={'40px'}
                  borderRadius={'full'}
                  _hover={{ bg: 'rgba(33, 33, 33, 0.21)', color: 'white' }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FaInfo fill="#718096" />
                </Button>
              </Box>
            </CardHeader>

            <CardBody
              m="20px 0 10px 0"
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Line')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Extend your services to Line users with our chatbot integration'
                )}
              </Text>
              {/* {QRData?.line_bot_url && (
                <Text fontSize={13} fontWeight={500} color="gray.500">
                  {t('Integrations.Bot ID')}:
                  <Tooltip label="Click to Copy!" aria-label="Click to Copy!">
                    <Text
                      display="inline"
                      ml="4px"
                      bg="gray.200"
                      fontWeight="semibold"
                      p="2px 8px"
                      rounded="md"
                      cursor="pointer"
                      onClick={() => copyText(QRData?.basic_id)}
                    >
                      {QRData?.basic_id}
                    </Text>
                  </Tooltip>
                </Text>
              )} */}
            </CardBody>

            {/* Footer with Dropdown and Connect Button in Row */}
            <CardFooter p={0}>
              <Flex
                w="100%"
                justify="space-between"
                align="center"
                mt="10px"
                gap={'6px'}
              >
                <BotDropdown
                  platform="Line"
                  integrationDetails={lineIntegrationDetails}
                  checkConnectionStatus={checkConnectionStatus}
                />
                <Button
                  bg="#EDF2F7"
                  h="34px"
                  w="50%"
                  border="1px solid rgba(33, 33, 33, 0.21)"
                  _hover={{ bg: '#002298', color: 'white' }}
                  // rightIcon={
                  //   !QRData?.line_bot_url && <MdOutlineKeyboardArrowRight />
                  // }
                  onClick={() => {
                    if (QRData?.line_bot_url) {
                      disconnect('Line');
                    } else {
                      onOpenLineForm();
                    }
                  }}
                >
                  {t('Integrations.Connect')}
                </Button>
              </Flex>

              {isOpen && (
                <DisconnectPopup
                  platform="Line"
                  onConfirm={() => disconnect('Line')}
                  onCancel={() => setIsOpen(false)}
                />
              )}
            </CardFooter>
          </Card>

          {/* <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={WhatsAPPIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.WhatsApp')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  "Integrations.Connect with billions of users on the world's by integrating with WhatsApp"
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                onClick={() => handleWhatsAppOpenModal()}
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
                rightIcon={<MdOutlineKeyboardArrowRight />}
              >
                {t('Integrations.Connect')}
              </Button>
            </CardFooter>
          </Card> */}

          {/* <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={SlackIcon} alt="BotIcon" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Slack')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  "Integrations.Supercharge your team's productivity by integrating our chatbot into Slack"
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card> */}

          <Card p="20px 25px" shadow={0} variant="outline">
            {/* Card Header with Icon */}
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={MessengerIcon} alt="BotIcon" width="50px" />
            </CardHeader>

            {/* Card Body with Integration Text */}
            <CardBody
              m="20px 0 10px 0"
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              {/* Title Text */}
              <Text fontWeight={700}>{t('Integrations.Messenger')}</Text>
              {/* Description Text */}
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Unlock the power of Messenger by integrating our advanced chatbot'
                )}
              </Text>
            </CardBody>

            {/* Flex container for dropdown and button */}
            <Flex
              w="100%"
              justify="space-between"
              align="center"
              mt="10px"
              gap={2} // Controls the space between the dropdown and button
            >
              <BotDropdown
                platform="Facebook"
                integrationDetails={faceBookIntegrationDetails}
                checkConnectionStatus={checkConnectionStatus}
              />
              <Flex p={0} marginLeft="10px" w="50%">
                {' '}
                {/* Set width as needed for the button container */}
                <Button
                  w="100%"
                  h="34px"
                  border="1px solid rgba(33, 33, 33, 0.21)"
                  _hover={{ bg: '#002298', color: 'white' }}
                  onClick={() =>
                    isConnected ? disconnect('Facebook') : handleConnect()
                  }
                  isLoading={loading}
                >
                  {t(
                    isConnected
                      ? 'Integrations.Disconnect'
                      : 'Integrations.Connect'
                  )}
                </Button>
              </Flex>
            </Flex>

            {/* Disconnect Popup */}
            {isOpen && (
              <DisconnectPopup
                onClose={() => setIsOpen(false)}
                onConfirm={() => {
                  disconnect();
                  setIsOpen(false);
                }}
              />
            )}
          </Card>

          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={InstagramIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Instagram')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Unlock the power of Messenger by integrating our advanced chatbot'
                )}
              </Text>
            </CardBody>

            <CardFooter p={0} display="flex" alignItems="center" gap={2}>
              <Flex w="100%" justify="space-between" align="center">
                <BotDropdown />
                <Button
                  bg=""
                  w="100%" // This can also be adjusted to control button width
                  h="34px"
                  border="1px solid rgba(33, 33, 33, 0.21)"
                  isDisabled
                  _hover={{ bg: '#002298', color: 'white' }}
                >
                  {t('Integrations.Coming Soon')}
                </Button>
              </Flex>
            </CardFooter>
          </Card>

          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={LazadaIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Lazada')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Unlock the power of Lazada by integrating our advanced chatbot'
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card>

          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={ShopeeIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Shopee')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Unlock the power of Shopee by integrating our advanced chatbot'
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card>

          {/* <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={TelegramIcon} alt="BotIcon" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Telegram')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Extend your services to Telegram users with our chatbot integration'
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card> */}
        </SimpleGrid>
        <WhatsAppIntegration
          isOpen={showWhatsAppModal}
          onClose={handleWhatsAppCloseModal}
          allBotsData={allBotsData}
          handleCallback={handleCallback}
        />
      </Box>
      <LineGuideModal isOpen={isOpenLineGuide} onClose={onCloseLineGuide} />
      <LineIntegration
        isOpen={inOpenLineForm}
        onClose={onCloseLineForm}
        setQRData={setQRData}
        checkConnectionStatus={checkConnectionStatus}
      />

      {showPricingModal && (
        <PricingChartModel
          isOpen={showPricingModal}
          onClose={handleClosePricingModal}
        />
      )}
    </Box>
  );
};

const IntegartionsWithAccessControl = withAccessControl(Integration);
export default IntegartionsWithAccessControl;
