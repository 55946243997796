import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';

const WidgetEmbed = ({ isOpen, onClose, botData }) => {
  const CHATBOT_SCRIPT_URL = process.env.REACT_APP_CHATBOT_SCRIPT_URL;

  const textRef = useRef(null);

  const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;

  const [copied, setCopied] = useState(false);
  function handleCopy() {
    if (textRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(textRef.current);
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand('copy');
        setCopied(true);
      } catch (error) {
        console.error('Copy failed:', error);
        setCopied(false);
      }

      selection.removeAllRanges();
    }
  }

  return (
    <Modal
      size={'3xl'}
      isOpen={isOpen}
      onClose={() => {
        setCopied(false);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'24px'} pb={0}>
          Chat Widget Embed Code
        </ModalHeader>
        <Text p={6} pt={0} pb={2} fontSize={13}>
          To integrate this chatbot into your website, simply insert the
          provided code snippet into your HTML page just before the closing{' '}
          {`</body>`} tag.
        </Text>
        <ModalCloseButton />
        <ModalBody p={4}>
          <Box>
            <Box>
              <Box
                bg='rgba(255, 62, 24, 0.05)'
                color='#212121'
                h='100px'
                overflow='auto'
                p='17px'
                borderRadius={'12px'}
              >
                <Text ref={textRef} fontSize={'13px'}>
                  {`<script src="${CHATBOT_SCRIPT_URL}"
                                            data-botid="` +
                    botData.bot_id +
                    `"
                                            data-userid="` +
                    user_id +
                    `">
                                          </script>`}
                </Text>
              </Box>
              <Box
                display='flex'
                justifyContent={'space-between'}
                alignItems={'center'}
                pt={2}
              >
                <Button
                  onClick={handleCopy}
                  borderRadius={'65px'}
                  marginTop={2}
                  _hover={{ bg: copied ? '#002298' : '#000F40' }}
                  leftIcon={copied ? <FaCheck /> : <FaCopy />}
                  bg={copied ? 'green' : '#002298'}
                  color={'white'}
                >
                  {copied ? 'Copied!' : 'Copy to Clipboard'}
                </Button>

                <Button variant={'link'}>Full Widget Documentation </Button>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WidgetEmbed;
