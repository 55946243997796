import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Square,
} from '@chakra-ui/react';
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';
import { FaSquare } from 'react-icons/fa';
import { t } from 'i18next';

const LineGuideModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={'800px'}>
        <ModalHeader>
          {t('Integrations.LineGuide.Line Integration Guide')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb='20px'>
          <Text mb={'10px'}>{t('Integrations.LineGuide.Description')}</Text>
          <Box>
            <Text fontWeight={'medium'} fontSize={'lg'} mb={'10px'}>
              {t('Integrations.LineGuide.Steps for Businesses')}
            </Text>
            <OrderedList display={'flex'} flexDirection={'column'} gap={'10px'}>
              <ListItem>
                <Text fontWeight={'medium'}>
                  {t('Integrations.LineGuide.Create a LINE Developers Account')}
                </Text>

                <UnorderedList>
                  <ListItem>
                    {t('Integrations.LineGuide.Go to LINE Developers.')}
                  </ListItem>
                  <ListItem>
                    {t(
                      "Integrations.LineGuide.Sign in or create a LINE business account if you don't already have one."
                    )}
                  </ListItem>
                </UnorderedList>
              </ListItem>

              <ListItem>
                <Text fontWeight={'medium'}>
                  {t('Integrations.LineGuide.Create a Provider')}
                </Text>
                <UnorderedList>
                  <ListItem>
                    {t('Integrations.LineGuide.After logging in, click on')}{' '}
                    <Text display={'inline'} fontWeight={'medium'}>
                      {t('Integrations.LineGuide.Create a new provider')}
                    </Text>
                  </ListItem>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.A provider is a business entity or service that the LINE app will interact with (e.g., your chatbot platform).'
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Give it a name (e.g., "Your Business Name").'
                    )}
                  </ListItem>
                </UnorderedList>
              </ListItem>

              <ListItem>
                <Text fontWeight={'medium'}>
                  {t('Integrations.LineGuide.Create a Messaging API Channel')}
                </Text>

                <UnorderedList>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Fill all details and click on Continue'
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Click on Go to LINE Official Account'
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Go to Messaging API and click on set Messaging API'
                    )}
                  </ListItem>

                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Add a Webhook url and click on Save'
                    )}
                  </ListItem>
                  <ListItem>
                    {' '}
                    {t(
                      'Integrations.LineGuide.Go to response settings and enable Webhooks'
                    )}{' '}
                  </ListItem>
                  {/* <ListItem>
                    {t('Integrations.LineGuide.Select')}{' '}
                    <Text display={'inline'} fontWeight={'medium'}>
                      {t('Integrations.LineGuide.Messaging API')}
                    </Text>
                  </ListItem>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Fill in the required details (channel name, description, category, etc.).'
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.After submitting, the channel will be created.'
                    )}
                  </ListItem> */}
                </UnorderedList>
              </ListItem>

              <ListItem>
                <Text fontWeight={'medium'}>
                  {t(
                    'Integrations.LineGuide.Obtain Channel ID, Channel Secret, and Access Token'
                  )}
                </Text>
                <UnorderedList>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Go to your created Messaging API channel settings.'
                    )}
                  </ListItem>
                  <ListItem>
                    <Text display={'inline'} fontWeight={'medium'}>
                      {t('Integrations.LineGuide.Channel ID')}:
                    </Text>{' '}
                    {t(
                      'Integrations.LineGuide.You can find this in the basic information section.'
                    )}
                  </ListItem>
                  <ListItem>
                    <Text display={'inline'} fontWeight={'medium'}>
                      {t('Integrations.LineGuide.Channel Secret')}:
                    </Text>{' '}
                    {t(
                      'Integrations.LineGuide.Also available in the same section.'
                    )}
                  </ListItem>
                  <ListItem>
                    <Text fontWeight={'medium'}>
                      {' '}
                      {t('Integrations.LineGuide.Access Token')}:
                    </Text>
                    <UnorderedList styleType={'lower-roman'}>
                      <ListItem>
                        {t(
                          'Integrations.LineGuide.Scroll down to the "Messaging API" section.'
                        )}
                      </ListItem>
                      <ListItem>
                        {t('Integrations.LineGuide.Issue a')}{' '}
                        <Text display={'inline'} fontWeight={'medium'}>
                          {t(
                            'Integrations.LineGuide.Long-lived Channel Access Token'
                          )}
                        </Text>{' '}
                        {t('Integrations.LineGuide.by clicking')}{' '}
                        <Text display={'inline'} fontWeight={'medium'}>
                          {t('Integrations.LineGuide.Issue.')}
                        </Text>
                      </ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Store these credentials safely.'
                    )}
                  </ListItem>
                </UnorderedList>
              </ListItem>

              <ListItem>
                <Text fontWeight={'medium'}>
                  {t(
                    'Integrations.LineGuide.Submit the Credentials on Bambu AI Website'
                  )}
                </Text>
                <UnorderedList>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Guide users to log in to your chatbot platform.'
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      'Integrations.LineGuide.Provide a form where they can submit their'
                    )}{' '}
                    <Text display={'inline'} fontWeight={'medium'}>
                      {t('Integrations.LineGuide.Channel Secret')}
                    </Text>
                    ,{' '}
                    <Text display={'inline'} fontWeight={'medium'}>
                      {t('Integrations.LineGuide.Channel ID')}
                    </Text>
                    , {t('Integrations.LineGuide.and')}{' '}
                    <Text display={'inline'} fontWeight={'medium'}>
                      {t('Integrations.LineGuide.Access Token')}
                    </Text>{' '}
                    {t(
                      'Integrations.LineGuide.for LINE Messenger integration.'
                    )}
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LineGuideModal;
