import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CloseButton,
  DarkMode,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useRef, useEffect, useState } from 'react';
import {
  AiOutlineCodeSandbox,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai';
import {
  BsCheckCircleFill,
  BsFileEarmarkArrowUp,
  BsSliders2,
  BsTrash,
} from 'react-icons/bs';
import {
  MdCancel,
  MdCheckCircle,
  MdInfo,
  MdInfoOutline,
  MdOutlineColorLens,
  MdOutlineSettings,
} from 'react-icons/md';
import { HiOutlineLanguage, HiOutlineUsers } from 'react-icons/hi2';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import WidgetCustomize from './ChatBotDetailsComponent/WidgetCustomize';
import { DefaultPromptModal } from './ChatBotDetailsComponent/DefaultPromptModal';

import { Link } from 'react-router-dom';
import AskQuestion from './AskQuestions';
import CreateNewBot from '../Dashboard_components/createNewBot';
import { RiCodeSSlashLine, RiDatabase2Line } from 'react-icons/ri';
import CalenderIcon from '../../assets/chatbot/calendar-check.png';
import UrlIcon from '../../assets/chatbot/URLIcon.png';
import DocumentIcon from '../../assets/chatbot/DocumentIcon.png';
import SitemapIcon from '../../assets/chatbot/SitemapIcon.png';
import RssfeedIcon from '../../assets/chatbot/RssfeedIcon.png';
import QAndAIcon from '../../assets/chatbot/q&aIcon.png';
import UrlListIcon from '../../assets/chatbot/UrlListIcon.png';
import IndexPageIcon from '../../assets/chatbot/cardSourcesIcon.svg';

import notionIcon from '../../assets/chatbot/notionIcon.png';
import oneDriveIcon from '../../assets/chatbot/oneDriveIcon.png';
import DropBoxIcon from '../../assets/chatbot/DropBoxIcon.png';
import GoogleDriveIcon from '../../assets/chatbot/GoogleDriveIcon.png';
import zendeskIcon from '../../assets/chatbot/zendeskIcon.png';
import boxIcon from '../../assets/chatbot/boxIcon.png';

import withAccessControl from '../../utils/withAccessControl';

import { PricingChartModel } from '../PricingChartModel';
import { ChatHistory } from './ChatHistory';
import ShareAPI from './ShareAPI';
import WidgetEmbed from './ChatBotDetailsComponent/Widgetembed';
import FetchWithToken from '../../utils/FetchWithToken';
import { t } from 'i18next';
import ChartModal from './ChatBotDetailsComponent/ChatModal';
import { IoClose } from 'react-icons/io5';
import { ImStatsDots } from 'react-icons/im';
import SourcesCrawlerModal from './SourcesCrawlerModal';
import { SourcesList } from './ChatBotDetailsComponent/SourcesList';
import LeadsModal from './ChatBotDetailsComponent/LeadsModal';
import { FiShare2 } from 'react-icons/fi';
import { TbMessages } from 'react-icons/tb';

// Card component
const BotCard = ({ icon, isPaid, title, description, isActive, onClick }) => (
  <Box
    maxW={{ base: '160px', md: '198px' }}
    h='145px'
    borderWidth='1px'
    borderRadius='lg'
    bg={isActive ? 'rgba(255, 131, 33, 0.04)' : 'gray.100'}
    display='flex'
    justifyContent='space-around'
    flexDirection='column'
    p='15px'
    cursor='pointer'
    // onClick={onClick}
    borderColor={isActive ? '#FF8321' : 'gray.200'}
  >
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      <Image src={icon} alt='BotIcon' />
      {isActive && <BsCheckCircleFill color='#002298' />}
    </Box>
    <Box display='flex' flexDirection='column' gap={3}>
      <Flex gap={2}>
        <Heading fontSize='15px' color='#212121' fontWeight={500}>
          {title}
        </Heading>
        {isPaid && (
          <Badge
            size='sm'
            variant='subtle'
            colorScheme='linkedin'
            borderRadius='10px'
            fontWeight={700}
          >
            PAID
          </Badge>
        )}
      </Flex>
      <Text fontSize='12px' color='#212121' fontWeight={500} opacity={0.6}>
        {description}
      </Text>
    </Box>
  </Box>
);

const ChatBotDetails = ({ hasAccess, handleCallback, details, Package }) => {
  //api
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [rendering, setRendering] = useState(true);
  const [addURL, setAddURL] = useState(true);
  const [newToast, setShowToast] = useState(false);
  const [message, setmessage] = useState(false);
  const [Toast, setToast] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [addSitemap, setAddSitemap] = useState(false);
  const [addURLList, setAddURLList] = useState(false);
  const [addRSSFeed, setAddRSSFeed] = useState(false);
  const [addQA, setAddQA] = useState(false);

  const [isLoading, setIsloading] = useState(false);
  const [isDeleteLoading, setIsDeleteloading] = useState(false);
  const [questionRole, setQuestionRole] = useState(null);
  const [sourceDetails, setSourceDetails] = useState();
  const [interfaceModal, setOpenInterfaceModal] = useState(false);
  const [addRssFeedURL, setAddRssFeedURL] = useState('');
  const [currentPan, setCurrentPlan] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showChatHistory, setShowChatHistory] = useState(false);

  const [isCardVisible, setCardVisible] = useState(false);
  const [createbotModal, setCreateBotModal] = useState(false);
  const [chartModal, setChartModal] = useState(false);
  const [leadsModal, setLeadsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDefaultPromptModal, setShowDefaultPromptModal] = useState(false);
  const [showShareAPIModal, setshowShareAPIModal] = useState(false);
  const [showAskquestionModal, setAskquestionModal] = useState(false);
  const [showLogModal, setLogModal] = useState(false);

  const [showSourcesModal, setShowSoucesModal] = useState(false);
  // const [isGPTModalOpen, setGPTModalOpen] = useState(false);
  const [sourceId, setSourceId] = useState();
  // const [selectedSource, setSelectdSource] = useState();

  const urlSectionRef = useRef(null);
  const documentSectionRef = useRef(null);
  const sitemapSectionRef = useRef(null);
  const urlListSectionRef = useRef(null);
  const rssFeedSectionRef = useRef(null);

  // const notionSectionRef = useRef(null);
  // const googledriveSectionRef = useRef(null);
  // const dropboxSectionRef = useRef(null);
  // const onedriveSectionRef = useRef(null);
  // const boxSectionRef = useRef(null);
  // const zendeskSectionRef = useRef(null);

  const toast = useToast();

  const [botData, setBotData] = useState();

  const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;
  const selectedTeam = JSON.parse(
    localStorage.getItem('docsbot-users')
  ).selectedTeam;
  // useState(location.state?.botData.bot_id);
  const queryParams = new URLSearchParams(window.location.search);
  const botId = queryParams.get('botId');

  const [showPricingModal, setShowPricingModal] = useState(false);

  const [urlFormData, setURLFormData] = useState({
    sourceURL: '',
    sourceTitle: '',
    isSinglePage: false,
    scheduledRefresh: 'Never',
  });

  const [documentFormData, setDocumentFormData] = useState({
    sourceURL: '',
    sourceTitle: '',
    scheduledRefresh: 'Never',
    sourceFile: null,
  });
  const [sitemapFormData, setSitemapFormData] = useState({
    sourceURL: '',
    sourceTitle: '',
    scheduledRefresh: 'Never',
  });

  const [urlListFormData, setUrlListFormData] = useState({
    sourceFile: null,
    scheduledRefresh: 'Never',
  });

  function formatCreatedAt(createdDate) {
    const formattedCreatedAt = new Date(createdDate).toLocaleString('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });

    return formattedCreatedAt;
  }

  const scrollToURLSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  //open Modals
  //handle on click of cards
  const handleAddURL = () => {
    if (hasAccess?.SOURCES.includes('URL')) {
      scrollToURLSection(urlSectionRef);
      setAddURL(true);
      setAddDocument(false);
      setAddSitemap(false);
      setAddURLList(false);
      setAddRSSFeed(false);
      setAddQA(false);
    } else {
      hasAccess && setShowPricingModal(true);
    }
  };
  // console.log(hasAccess);

  //handle Documentation active on click of the card
  const handleAddDocument = () => {
    if (hasAccess?.SOURCES.includes('DOC')) {
      scrollToURLSection(documentSectionRef);
      setAddDocument(true);
      setAddURL(false);
      setAddSitemap(false);
      setAddURLList(false);
      setAddRSSFeed(false);
      setAddQA(false);
    } else {
      hasAccess && setShowPricingModal(true);
    }
  };

  //handle Sitemap active on click of the card
  const handleAddSitemap = () => {
    if (hasAccess?.SOURCES.includes('SITEMAP')) {
      scrollToURLSection(sitemapSectionRef);
      setAddSitemap(true);
      setAddURL(false);
      setAddDocument(false);
      setAddURLList(false);
      setAddRSSFeed(false);
      setAddQA(false);
    } else {
      hasAccess && setShowPricingModal(true);
    }
  };

  //handle URL List active on click of the card
  const handleAddURLList = () => {
    if (hasAccess?.SOURCES.includes('URL LIST')) {
      scrollToURLSection(urlListSectionRef);
      setAddURLList(true);
      setAddURL(false);
      setAddDocument(false);
      setAddSitemap(false);
      setAddRSSFeed(false);
      setAddQA(false);
    } else {
      hasAccess && setShowPricingModal(true);
    }
  };

  //handle RSS feed active on click of the card
  const handleAddRSSFeed = () => {
    if (hasAccess?.SOURCES.includes('RSS FEED')) {
      scrollToURLSection(rssFeedSectionRef);
      setAddRSSFeed(true);
      setAddURLList(false);
      setAddURL(false);
      setAddDocument(false);
      setAddSitemap(false);
      setAddQA(false);
    } else {
      hasAccess && setShowPricingModal(true);
    }
  };

  //handle Q/A active on click of the card
  const handleAddQA = () => {
    if (hasAccess?.SOURCES.includes('Q&A')) {
      scrollToURLSection(sitemapSectionRef);
      setAddQA(true);
      setAddURL(false);
      setAddDocument(false);
      setAddSitemap(false);
      setAddRSSFeed(false);
      setAddURLList(false);
    } else {
      hasAccess && setShowPricingModal(true);
    }
  };

  //Handle Delete card
  const handleDeleteCardClick = () => {
    setCardVisible(true);
  };

  //handle open modal for interface
  const handleOpenModalInterface = () => {
    setShowModal(true);
  };

  //Handle Ask question modal
  const handleAskquestionModal = (enabledCheck, role) => {
    console.log(enabledCheck, role);
    if (enabledCheck) {
      setAskquestionModal(true);
      setQuestionRole(role);
    } else if (enabledCheck === false) {
      setShowPricingModal(true);
    }
  };

  // const handleShowPricingChart = () => {
  //   setShowPricingModal(true);
  // };

  //Default prompt modal
  const handleDefaultPromptClick = () => {
    if (hasAccess?.PROMPT_CUSTOMIZATION.enabled) {
      setShowDefaultPromptModal(true);
    } else if (hasAccess?.PROMPT_CUSTOMIZATION.enabled === false) {
      // <PricingChartModel isOpen={showPricingModal}
      // onClose={handleClosePricingModal}/>
      // if(user_planName === 'Free')
      setShowPricingModal(true);
    }
  };

  //handle Share page modal
  const handleShareAPIClick = () => {
    setshowShareAPIModal(true);
  };

  //Chat History Modal
  const handleLogPage = (data) => {
    console.log(data);
    if (data) {
      setLogModal(true);
      setShowChatHistory(true);
    } else if (data === false) {
      setShowPricingModal(true);
    }
  };

  //Close modals

  const handleCloseChatHistory = () => {
    setShowChatHistory(false);
  };
  const handleClosePricingModal = () => {
    setShowPricingModal(false);
  };

  const handleDefaultPromptCloseModal = () => {
    setShowDefaultPromptModal(false);
  };
  const handleShareAPICloseModal = () => {
    setshowShareAPIModal(false);
  };
  const handleCloseChartModal = () => {
    setChartModal(false);
  };
  const handleCloseLeadsModal = () => {
    setLeadsModal(false);
  };
  const handleCloseAskquestionModal = () => {
    setAskquestionModal(false);
    handleCallback();
    fetchBotData();
  };
  const handleCloseModal = () => {
    setShowModal(false);
    handleCallback();
  };

  const [isDeleteSourceModalOpen, setIsDeleteSourceModalOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);

  // Function to handle source delete click
  const handleSourceDelete = (source) => {
    setSelectedSource(source);
    setIsDeleteSourceModalOpen(true);
  };

  // Handle Input change for  different input field for URL
  const handleUrlSwitchChange = (e, identifier) => {
    const value = e.target.checked;
    // console.log(value, e.target.checked);
    setURLFormData((prevFormData) => ({
      ...prevFormData,
      [identifier]: value,
    }));
    console.log(urlFormData);
  };
  const handleInputChange = (event, identifier) => {
    const value = event.target.value;
    // console.log(value, event);
    // Update the URL form data state based on the identifier
    setURLFormData((prevFormData) => ({
      ...prevFormData,
      [identifier]: value,
    }));
    console.log(urlFormData);
  };

  // Handle Input change for  different input field for Document
  const handleDocumentInputChange = (e, fieldName) => {
    console.log(e.target.files);
    if (fieldName === 'sourceFile') {
      setDocumentFormData((prevFormData) => ({
        ...prevFormData,
        sourceFile: e.target.files[0], // Store the selected file
      }));
    } else {
      setDocumentFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: e.target.value,
      }));
    }
  };

  // Handle Input change for  different input field for URL List
  const handleURLListInputChange = (e, fieldName) => {
    if (fieldName === 'sourceFile') {
      setUrlListFormData((prevFormData) => ({
        ...prevFormData,
        sourceFile: e.target.files[0], // Store the selected file
      }));
    } else {
      setUrlListFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: e.target.value,
      }));
    }
  };

  // url list csv change drop
  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setUrlListFormData((prevFormData) => ({
      ...prevFormData,
      sourceFile: file,
    }));
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Handle Input change for  different input field for Sitemap
  const handleSiteMapInputChange = (event, identifier) => {
    const value = event.target.value;
    // Update the URL form data state based on the identifier
    setSitemapFormData((prevFormData) => ({
      ...prevFormData,
      [identifier]: value,
    }));
  };

  const toastIdRef = React.useRef();
  function closeToast() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  const showToast = (title, description, status) => {
    toastIdRef.current = toast({
      position: 'top-right',
      render: () => (
        <Flex
          gap={2}
          justifyContent={'space-between'}
          borderRadius={'10px'}
          color='white'
          p={3}
          bg='blackAlpha.700'
        >
          {/* <MdInfo size={20} color="#FF8321" /> */}
          <Box display={'flex'} gap={2}>
            <Box mt={1}>
              {status === 'info' && <MdInfo color={'#FF8321'} size={20} />}
              {status === 'success' && (
                <MdCheckCircle color={'green'} size={20} />
              )}
              {status === 'error' && <MdCancel color={'red'} size={20} />}
            </Box>

            <Box>
              <Text fontSize={'16px'} fontWeight={700}>
                {title}
              </Text>
              <Text color={'whiteAlpha.700'} fontSize={'15px'} fontWeight={500}>
                {description}
              </Text>
            </Box>
          </Box>

          <IoClose
            onClick={closeToast}
            color={'RGBA(255, 255, 255, 0.60)'}
            size={20}
            cursor={'pointer'}
          />
        </Flex>
      ),
    });
  };

  const customToast = (title, description, duration = 5000) => {
    if (Toast) {
      return;
    }

    setToast(true);

    toastIdRef.current = toast({
      position: 'top-right',
      render: () => (
        <Box
          position='absolute'
          top='10px'
          right='10px'
          backgroundColor='black'
          color='white'
          padding='10px'
          borderRadius='8px'
          zIndex='9999'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            marginBottom='5px'
          >
            <Box fontWeight='700' fontSize='16px' fontFamily='satoshi'>
              {title}
            </Box>
            <CloseButton
              color='white'
              onClick={() => {
                setShowToast(false);
                clearTimeout(toastTimer); // Clear the timeout when the toast is closed manually
              }}
              fontSize='14px'
            />
          </Box>
          <Box fontWeight='500' fontSize='15px' fontFamily='satoshi'>
            {description}
          </Box>
        </Box>
      ),
    });

    // Automatically hide the toast after 'duration' milliseconds
    const toastTimer = setTimeout(() => {
      setShowToast(false);
    }, duration);
  };

  //Uplaod URL Source
  const handleAddURLSource = async (event) => {
    event.preventDefault();
    // console.log(urlFormData);
    if (hasAccess?.SOURCE_PAGES.enabled) {
      const data = {
        user_id: user_id,
        team_id: selectedTeam.team_id,
        current_plan: currentPan,
        source_type: 'url',
        source_title: urlFormData.sourceTitle,
        scheduling: urlFormData.scheduledRefresh,
        bot_id: botData.bot_id,
        source_url: urlFormData.sourceURL,
      };

      const data2 = {
        scheduling: urlFormData.scheduledRefresh,
        bot_id: botData.bot_id,
        source_url: urlFormData.sourceURL,
        source_type: 'url',
        is_single_page: urlFormData.isSinglePage,
        user_id: user_id,
        team_id: selectedTeam.team_id,
        current_plan: currentPan,
      };
      setURLFormData({
        sourceURL: '',
        sourceTitle: '',
        isSinglePage: false,
        scheduledRefresh: 'Never',
      });

      setIsloading(true);
      FetchWithToken(
        BASE_URL + '/chatbot/bot-sources',
        'POST',
        data2,
        true
      ).then((data) => {
        setSourceId(data.source_id);
        setIsloading(false);
        setSelectedSource(data);
        fetchSourceCategory();
        setShowSoucesModal(true);
        if (data.message) {
          setTimeout(() => {
            showToast(
              'Scanning In Progress',
              'Scanning might take a couple of minutes.',
              'success'
            );
          }, 2000);
          fetchSourceCategory();
          fetchSource();
          fetchBotData();
          showToast(data.message, '', 'info');
          // toast({
          //   title: data.message,
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
          handleCallback();
        } else if (data.error) {
          showToast(data.error, '', 'error');
          // toast({
          //   title: data.error,
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
        }
        //console.log(data)
      });
      // FetchWithToken(BASE_URL + "/chatbot/sources", "POST", data, true).then(
      //   (data) => {
      //     setIsloading(false);
      //     if (data.message) {
      //       setTimeout(() => {
      //         toast({
      //           title:
      //             "Indexing of large websites might take up to 20 minutes due to the amount of content",
      //           status: "warning",
      //           duration: 6000,
      //           isClosable: true,
      //         });
      //       }, 2000);
      //       fetchSource();
      //       fetchBotData();
      //       toast({
      //         title: data.message,
      //         status: "warning",
      //         duration: 4000,
      //         isClosable: true,
      //       });
      //       handleCallback();
      //     } else if (data.error) {
      //       toast({
      //         title: data.error,
      //         status: "warning",
      //         duration: 4000,
      //         isClosable: true,
      //       });
      //     }
      //     //console.log(data)
      //   }
      // );
    } else {
      setShowPricingModal(true);
    }
  };

  //Upload Sitemap Source
  const handleAddSitemapSource = async (event) => {
    event.preventDefault();
    // if ((sitemapFormData.sourceURL.endsWith('/sitemap.xml/') || sitemapFormData.sourceURL.endsWith('/sitemap.xml'))) {
    if (hasAccess?.SOURCE_PAGES.enabled) {
      const data = {
        user_id: user_id,
        team_id: selectedTeam.team_id,
        current_plan: currentPan,
        source_type: 'sitemap',
        source_title: sitemapFormData.sourceTitle,
        scheduling: sitemapFormData.scheduledRefresh,
        bot_id: botData.bot_id,
        source_url: sitemapFormData.sourceURL,
      };
      //console.log(data);
      setSitemapFormData({
        sourceURL: '',
        sourceTitle: '',
        scheduledRefresh: 'Never',
      });
      setIsloading(true);
      FetchWithToken(
        BASE_URL + '/chatbot/bot-sources',
        'POST',
        data,
        true
      ).then((data) => {
        setIsloading(false);
        if (data.message) {
          setTimeout(() => {
            showToast(
              'Scanning In Progress',
              'Scanning might take a couple of minutes.',
              'success'
            );
          }, 2000);
          // setTimeout(() => {
          //   toast({
          //     title:
          //       "Indexing of large websites might take up to 20 minutes due to the amount of content",
          //     status: "warning",
          //     duration: 6000,
          //     isClosable: true,
          //   });
          // }, 2000);
          fetchSource();
          fetchSourceCategory();
          fetchBotData();
          setShowSoucesModal(true);
          showToast(data.message, '', 'info');
          // toast({
          //   title: data.message,
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
          handleCallback();
        } else if (data.error) {
          showToast('', data.error, 'error');
          // toast({
          //   title: data.error,
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
        }
      });
    } else {
      setShowPricingModal(true);
    }
    // }
    // else {
    //   setShowErrorMessage(true);
    // }
  };

  //Upload Document source
  const handleAddDocumentSource = async (event) => {
    event.preventDefault();

    if (hasAccess?.SOURCE_PAGES.enabled) {
      const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;
      const formData = new FormData();
      //formData.append('sourceURL', documentFormData.sourceURL);
      formData.append('source_title', documentFormData.sourceTitle);
      //formData.append('scheduledRefresh', documentFormData.scheduledRefresh);
      formData.append('file', documentFormData.sourceFile);
      formData.append('source_type', 'document');
      formData.append('user_id', user_id);
      formData.append('bot_id', botData.bot_id);
      formData.append('team_id', selectedTeam.team_id);
      formData.append('current_plan', currentPan);
      setIsloading(true);

      const jwtToken = JSON.parse(
        localStorage.getItem('docsbot-users')
      ).jwt_token;
      const headers = {
        jwtToken: jwtToken,
      };

      await fetch(BASE_URL + '/chatbot/upload_file', {
        method: 'POST',
        headers: headers,
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setIsloading(false);
          if (data.error) {
            console.log(data.error);
            showToast('Failed', data.error, 'error');
            // toast({
            //   title: data.error,
            //   status: "warning",
            //   duration: 4000,
            //   isClosable: true,
            // });
            return;
          }
          fetchSource();
          fetchSourceCategory();
          fetchBotData();
          setShowSoucesModal(true);
          showToast(data.message, '', 'info');
          // toast({
          //   title: data.message,
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
          handleCallback();
          setDocumentFormData({
            sourceURL: '',
            sourceTitle: '',
            scheduledRefresh: 'Never',
            sourceFile: null,
          });
        })
        .catch((err) => {
          setIsloading(false);
          showToast('Failed', 'Error Submitting data.', 'error');
          // toast({
          //   title: "Error submitting data",
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
        });
    } else {
      setShowPricingModal(true);
    }
  };

  //upload  Url List source
  const handleAddNewURLList = async (event) => {
    event.preventDefault();

    if (hasAccess?.SOURCE_PAGES.enabled) {
      const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;
      const formData = new FormData();

      formData.append('file', urlListFormData.sourceFile);
      formData.append('bot_id', botData?.bot_id);
      formData.append('source_type', 'url_list');
      formData.append('user_id', user_id);
      formData.append('source_title', 'URL List');
      formData.append('team_id', selectedTeam.team_id);
      formData.append('current_plan', currentPan);
      formData.append('scheduling', urlListFormData.scheduledRefresh);

      setIsloading(true);
      const jwtToken = JSON.parse(
        localStorage.getItem('docsbot-users')
      ).jwt_token;
      const headers = {
        jwtToken: jwtToken,
      };

      await fetch(BASE_URL + '/chatbot/sources/ulist', {
        method: 'POST',
        headers: headers,
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setIsloading(false);
          setUrlListFormData({
            sourceFile: null,
            scheduledRefresh: 'Never',
          });
          if (data.error) {
            showToast('Failed', data.error, 'error');
            // toast({
            //   title: data.error,
            //   status: "warning",
            //   duration: 4000,
            //   isClosable: true,
            // });
            return;
          }
          setTimeout(() => {
            showToast(
              'Scanning In Progress',
              'Indexing of large websites might take up to 20 minutes due to the amount of content',
              'success'
            );
            // toast({
            //   title:
            //     "Indexing of large websites might take up to 20 minutes due to the amount of content",
            //   status: "warning",
            //   duration: 6000,
            //   isClosable: true,
            // });
          }, 2000);
          fetchSource();
          fetchSourceCategory();
          fetchBotData();
          setShowSoucesModal(true);
          showToast('', data.message, 'info');
          // toast({
          //   title: data.message,
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
          handleCallback();
          setUrlListFormData({
            sourceFile: null,
            scheduledRefresh: 'Never',
          });
        })
        .catch((err) => {
          setIsloading(false);
          showToast('Failed', 'Error Submitting data.', 'error');
          // toast({
          //   title: "Error submitting data",
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
        });
    } else {
      setShowPricingModal(true);
      toast({
        title: 'Update your plan',
        status: 'info',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  //Upload RSS Feed Source
  const handleAddRSSFeedSource = async (event) => {
    event.preventDefault();
    // if (addRssFeedURL.endsWith("/feed/") || addRssFeedURL.endsWith("/feed")) {
    if (hasAccess?.SOURCE_PAGES.enabled) {
      setIsloading(true);

      const data = {
        source_url: addRssFeedURL,
        bot_id: botData.bot_id,
        source_type: 'rss_feed',
        user_id: user_id,
        source_title: 'RSS Feed',
        team_id: selectedTeam.team_id,
        current_plan: currentPan,
      };

      setAddRssFeedURL('');
      FetchWithToken(BASE_URL + '/chatbot/bot-sources', 'POST', data)
        .then((data) => {
          setIsloading(false);
          if (data.message) {
            setTimeout(() => {
              showToast(
                'Uploaded',
                'Indexing of large websites might take up to 20 minutes due to the amount of content',
                'info'
              );
            }, 2000);
            fetchSource();
            fetchSourceCategory();
            fetchBotData();
            setShowSoucesModal(true);
            showToast('', data.message, 'info');
            // toast({
            //   title: data.message,
            //   status: "warning",
            //   duration: 4000,
            //   isClosable: true,
            // });
            handleCallback();
          } else if (data.error) {
            showToast(
              'Failed',
              'Something Unexpected error occurred.',
              'error'
            );
            // toast({
            //   title: data.error,
            //   status: "warning",
            //   duration: 4000,
            //   isClosable: true,
            // });
          }
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
    } else {
      setShowPricingModal(true);
    }
    // } else {
    //   setShowErrorMessage(true);
    // }
  };

  //Upload Q&A Source
  const handleSubmitQA = (event) => {
    event.preventDefault();
    setIsloading(true);
    const allQuestionWithAnswers = questions.map((qna) => ({
      question: qna.question,
      answer: qna.answer,
    }));

    const data = {
      QA: allQuestionWithAnswers,
      bot_id: botData.bot_id,
      source_type: 'Q&A',
      user_id: user_id,
      source_title: 'Q&A',
      team_id: selectedTeam.team_id,
      current_plan: currentPan,
    };
    setQuestions([{ question: '', answer: '' }]);
    FetchWithToken(BASE_URL + '/chatbot/sources/QA', 'POST', data)
      .then((data) => {
        setIsloading(false);
        if (data.message) {
          fetchSource();
          fetchBotData();
          fetchSourceCategory();
          setShowSoucesModal(true);
          showToast('Uploaded', data.message, 'info');
          // toast({
          //   title: data.message,
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
        } else if (data.error) {
          showToast('Failed', data.error, 'error');
          // toast({
          //   title: data.error,
          //   status: "warning",
          //   duration: 4000,
          //   isClosable: true,
          // });
        }
      })
      .catch((err) => {
        setIsloading(false);
        console.log(err);
      });
  };
  //Q&A question answer filed adding dynamically with a click of a button
  const [questions, setQuestions] = useState([{ question: '', answer: '' }]);
  const handleAddQuestion = () => {
    setQuestions([...questions, { question: '', answer: '' }]);
  };
  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = value;
    setQuestions(updatedQuestions);
  };
  const handleAnswerChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].answer = value;
    setQuestions(updatedQuestions);
  };
  const handleDeleteQuestion = (index) => {
    if (questions.length === 1) {
      return; // Prevent deleting the only question-answer block
    }
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  //Delete source
  const deleteSource = async (source) => {
    const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;
    const PageCount = parseInt(source?.page_count, 10);

    const data = {
      source_id: source.source_id,
      bot_id: botData.bot_id,
      user_id: user_id,
      page_count: PageCount,
    };
    setIsDeleteloading(true);
    console.log('data', data);
    FetchWithToken(BASE_URL + '/chatbot/deletesource', 'DELETE', data).then(
      (data) => {
        setIsDeleteloading(false);
        setIsDeleteSourceModalOpen(false);
        setCardVisible(false);
        fetchSource();
        fetchBotData();
        handleCallback();
      }
    );
  };

  //Fetch source
  function fetchSource() {
    FetchWithToken(
      //location.state?.botData.bot_id,
      BASE_URL + '/chatbot/source?bot_id=' + botId,
      'GET'
    ).then((data) => {
      setSourceDetails(data.Chatbots);
      // if(selectedSource){
      //   const source = data.Chatbots.find((source)=> source.source_id === selectedSource.source_id)
      //   setSelectedSource(source)
      // }
    });
  }

  //Fetch Bot Data
  const fetchBotData = () => {
    FetchWithToken(
      BASE_URL + `/chatbots?bot_id=${botId}&team_id=${selectedTeam.team_id}`, //location.state?.botData?.bot_id
      'GET'
    ).then((data) => {
      setBotData(data?.bots[0]);
    });
  };

  //Handle a example csv downlaod for url list file upload
  const handleExampleDownload = () => {
    const urls =
      'https://growth-loop.io\nhttps://www.google.com\nhttps://www.openai.com\nhttps://github.com\nhttps://www.wikipedia.org';
    const csvContent = `${urls}`;

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'example_urls.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenCreatebotModal = () => {
    setCreateBotModal(true);
  };
  const handleOpenChartModal = () => {
    if (hasAccess?.ADVANCED_ANALYTICS.enabled) {
      setChartModal(true);
    } else if (hasAccess?.ADVANCED_ANALYTICS.enabled == false) {
      setShowPricingModal(true);
    }
  };

  const handleOpenLeadsModal = () => {
    setLeadsModal(true);
  };

  const handleCreatebotCloseModal = () => {
    setCreateBotModal(false);
    fetchBotData();
  };

  const handleOpenInterface = () => {
    setOpenInterfaceModal(true);
  };
  const handleCloseInterface = () => {
    setOpenInterfaceModal(false);
  };
  const handleCancel = () => {
    setCreateBotModal(false);
  };

  const handleOpenSourceCrawlerModal = (source) => {
    setSelectedSource(source);
    setSourceId(source.source_id);
    setShowSoucesModal(true);
  };

  //Fetch Stats Data
  // function fetchdata() {
  //   FetchWithToken(
  //     BASE_URL + `/stats?team_id=${selectedTeam.team_id}`,
  //     "GET"
  //   ).then((data) => {
  //     setCurrentPlan(data?.current_plan);
  //   });
  // }

  useEffect(() => {
    if (details) {
      setCurrentPlan(details?.current_plan);
    }
    if (hasAccess && botData) {
      setRendering(false);
    }
  }, [hasAccess, botData]);

  useEffect(() => {
    fetchSource();
    fetchBotData();
    // fetchdata();
    // setCurrentPlan(details?.current_plan);
  }, []);

  //Job status Check for source pending states
  const [sourceCategoryDetails, setSourceCategoryDetails] = useState();
  const [currentBotState, setCurrentBotState] = useState();
  const fetchSourceCategory = async () => {
    try {
      const response = await FetchWithToken(
        BASE_URL + `/chatbot/source-category?bot_id=${botId}`,
        'GET'
      );
      // console.log(response);
      if (response?.Chatbots) {
        setSourceCategoryDetails(response.Chatbots);
        const hasScanningOrTraining = response?.Chatbots?.some(
          (obj) =>
            obj.category_status === 'Processing' ||
            obj.category_status === 'Training'
        );
        if (hasScanningOrTraining) {
          setCurrentBotState('Processing');
        } else {
          setCurrentBotState('Processed');
        }
      } else {
        setSourceCategoryDetails([]);
      }

      return response;
    } catch (error) {
      console.error('Failed to fetch source status:', error);
      return '';
    }
  };

  const fetchSourceStatus = async (sourceId, botId) => {
    const timestamp = new Date().getTime();
    try {
      const response = await FetchWithToken(
        BASE_URL +
          `/job-status?source_id=${sourceId}&bot_id=${botId}&_=${timestamp}`,
        'GET'
      );

      return response.status;
    } catch (error) {
      console.error('Failed to fetch source status:', error);
      return '';
    }
  };

  //handle on Change of Source data creates a setInterval for sources which are on 'Pending' State to do a job status update call in every 10 sec
  // useEffect(() => {
  //   if (sourceDetails?.length > 0 && selectedSource) {
  //     const src_id = selectedSource.source_id;
  //     const foundSource = sourceDetails.find(
  //       (source) => source.source_id === src_id
  //     );
  //     setSelectedSource(foundSource);
  //   }

  //   const intervalIds = {};

  //   const updateSourceStatus = async (sourceId, botId) => {
  //     const status = await fetchSourceStatus(sourceId, botId);
  //     if (
  //       status === "Completed" ||
  //       status === "Scrapped" ||
  //       status === "Scanned" ||
  //       status === "Trained" ||
  //       status === "Failed"
  //     ) {
  //       clearInterval(intervalIds[sourceId]);
  //       fetchSource();
  //       fetchBotData();
  //       // fetchdata()
  //     }
  //   };
  //   // Loop through the sourceDetails and start setInterval for pending sources
  //   sourceDetails?.forEach((source) => {
  //     if (
  //       source.job_status === "Pending" ||
  //       source.job_status === "Processing" ||
  //       source.job_status === "Training"
  //     ) {
  //       const intervalId = setInterval(() => {
  //         updateSourceStatus(source.source_id, source.bot_id);
  //       }, 10000);
  //       intervalIds[source.source_id] = intervalId;
  //     }
  //   });
  //   return () => {
  //     // Clear all the interval timers when the component unmounts
  //     Object.values(intervalIds)?.forEach((intervalId) =>
  //       clearInterval(intervalId)
  //     );
  //   };
  // }, [sourceDetails]);
  useEffect(() => {
    fetchSourceCategory();
    // console.log("rendering");
  }, []);
  useEffect(() => {
    // console.log("useEffect cat");
    const updateSourceCategory = async () => {
      const response = await fetchSourceCategory();
      if (!response) {
        clearInterval(intervalId);
      } else if (response.Chatbots) {
        const hasScanningOrTraining = response.Chatbots.some(
          (obj) =>
            obj.category_status === 'Processing' ||
            obj.category_status === 'Training'
        );
        // console.log(hasScanningOrTraining);
        if (!hasScanningOrTraining) {
          // console.log("clearing category call", intervalId);
          clearInterval(intervalId);
          fetchBotData();
        }
      }
    };

    let intervalId = '';
    // Start polling when the component mounts
    if (currentBotState === 'Processing' || currentBotState === 'Training') {
      intervalId = setInterval(() => updateSourceCategory(), 10000); // Poll every 10 second
    }

    return () => {
      // console.log("unmounting");
      clearInterval(intervalId);
    };
  }, [sourceCategoryDetails, currentBotState]);

  const isChatDisabled =
    sourceDetails && sourceDetails.length > 0
      ? sourceDetails.length === 1 && sourceDetails[0].job_status === 'Pending'
        ? true
        : false
      : true;

  // NEW cards
  const [addNotion, setAddNotion] = useState(false);
  const [addGoogleDrive, setAddGoogleDrive] = useState(false);
  const [addDropBox, setAddDropbox] = useState(false);
  const [addOneDrive, setAddOneDrive] = useState(false);
  const [addBox, setAddBox] = useState(false);
  const [addZendesk, setAddZendesk] = useState(false);

  const cardData = [
    {
      type: 'Notion',
      icon: notionIcon,
      isPaid: true,
      title: 'Notion',
      description: t(
        'Chatbots.Details.Answer from the content from a single webpage'
      ),
    },
    {
      type: 'GoogleDrive',
      icon: GoogleDriveIcon,
      isPaid: true,
      title: 'Google Drive',
      description: t(
        'Chatbots.Details.Answer from the content from a single webpage'
      ),
    },
    {
      type: 'DropBox',
      icon: DropBoxIcon,
      isPaid: true,
      title: 'Drop Box',
      description: t(
        'Chatbots.Details.Answer from the content from a single webpage'
      ),
    },
    {
      type: 'OneDrive',
      icon: oneDriveIcon,
      isPaid: true,
      title: 'OneDrive',
      description: t(
        'Chatbots.Details.Answer from the content from a single webpage'
      ),
    },
    {
      type: 'Box',
      icon: boxIcon,
      isPaid: true,
      title: 'Box',
      description: t(
        'Chatbots.Details.Answer from the content from a single webpage'
      ),
    },
    {
      type: 'Zendesk',
      icon: zendeskIcon,
      isPaid: true,
      title: 'Zendesk',
      description: t(
        'Chatbots.Details.Answer from the content from a single webpage'
      ),
    },
    // Add other card data similarly
  ];

  const handleCardClick = (type, ref) => {
    console.log(type, 'clicked');
    if (hasAccess?.SOURCES.includes(type)) {
      scrollToURLSection(ref);
      setAddURL(false);
      setAddDocument(false);
      setAddSitemap(false);
      setAddURLList(false);
      setAddRSSFeed(false);
      setAddQA(false);

      setAddNotion(false);
      setAddGoogleDrive(false);
      setAddDropbox(false);
      setAddOneDrive(false);
      setAddBox(false);
      setAddZendesk(false);
      // Set the clicked card to active
      setCardState(type, true);
    } else {
      hasAccess && setShowPricingModal(true);
    }
  };

  const setCardState = (type, value) => {
    const stateSetter = {
      URL: setAddURL,
      DOC: setAddDocument,
      SITEMAP: setAddSitemap,
      URL_LIST: setAddURLList,
      RSS_FEED: setAddRSSFeed,
      QA: setAddQA,
      Notion: setAddNotion,
      GoogleDrive: setAddGoogleDrive,
      DropBox: setAddDropbox,
      OneDrive: setAddOneDrive,
      Box: setAddBox,
      Zendesk: setAddZendesk,
    }[type];
    stateSetter(value);
  };

  useEffect(() => {
    if (newToast) {
      if (message == 'enabled') {
        showToast(
          'Leads Capture Enabled!',
          'Your Chatbot is now able to capture leads.',
          'success'
        );
      } else if (message == 'disabled') {
        showToast('Leads Capture Disabled', '', 'success');
      }
      setShowToast(false);
    }
  }, [newToast, message]);

  return (
    <Box>
      {rendering ? (
        <Box display='flex' alignItems='center' justifyContent='center' mt={10}>
          <Spinner color='blue.500' />
        </Box>
      ) : (
        <>
          <Flex gap={5} flexDirection={{ base: 'column', md: 'row' }}>
            <Card shadow={0} width={'100%'}>
              <CardBody>
                <Box>
                  <Flex
                    justifyContent={'space-between'}
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap={2}
                  >
                    <Box>
                      <Text fontWeight='700' fontSize={'24px'}>
                        {botData?.bot_name &&
                          botData?.bot_name.charAt(0).toUpperCase() +
                            botData?.bot_name.slice(1)}
                        <Badge
                          color={'#507D58'}
                          p={'3px 13px'}
                          borderRadius={50}
                          ml='2'
                          fontWeight={500}
                          fontSize={'10px'}
                          bg={
                            botData?.source_count > 0 ? ' #E2FBE8 ;' : '#FDF8C9'
                          }
                        >
                          {botData?.source_count > 0
                            ? 'Ready'
                            : 'Awaiting for source'}
                        </Badge>
                      </Text>
                    </Box>
                    <Box display='flex' alignItems='center' gap={5}>
                      {/* <Box display="flex" alignItems="center">
                      <FiChevronDown size={20} color="#999999" />
                        <Box
                          display="flex"
                          alignItems="center"
                          color={"#999999"}
                          cursor="pointer"
                          onClick={() => setGPTModalOpen(true)}
                          _hover={{ color: "#001970" }}
                        >
                          <Text fontSize="12px" fontWeight="500" marginRight={2}>
                            Select Platform
                          </Text>
                          
                        </Box>

                      
                        <GPTDropdown
                          isOpen={isGPTModalOpen}
                          onClose={() => setGPTModalOpen(false)}
                          backgroundColor="#fff"
                          boxShadow="lg"
                          borderRadius="md"
                          padding="1rem"
                        />
                      </Box> */}

                      <Box
                        _hover={{ color: '#002298' }}
                        color={'#999999'}
                        fontSize={'12px'}
                        fontWeight={500}
                        onClick={handleOpenInterface}
                        display='flex'
                        alignItems='center'
                        gap={1.5}
                        cursor='pointer'
                        pb={2}
                      >
                        <RiCodeSSlashLine size={20} />
                        <Text>{t('Chatbots.Details.Widget Embed')}</Text>
                      </Box>
                      <Box
                        onClick={handleShareAPIClick}
                        _hover={{ color: '#002298' }}
                        color={'#999999'}
                        fontSize={'12px'}
                        fontWeight={500}
                        display='flex'
                        alignItems='center'
                        gap={1.5}
                        cursor='pointer'
                        pb={2}
                      >
                        <FiShare2 size={20} />
                        <Text>{t('Chatbots.Details.Sharing & API')}</Text>
                      </Box>
                      {/* <Box onClick={handleOpenCreatebotModal} fontSize={'12px'} color={'#999999'} fontWeight={500} display="flex" alignItems="center" gap={1.5} cursor="pointer" pb={2}>
                                        <TbPencil size={20} />
                                        <Text>Edit</Text>
                                    </Box> */}
                    </Box>
                  </Flex>
                  <Box mt={3}>
                    <Text color={'#999999'} fontWeight={500} fontSize='14px'>
                      {botData?.description &&
                        botData?.description.charAt(0).toUpperCase() +
                          botData?.description.slice(1)}
                    </Text>
                  </Box>
                </Box>

                <Box
                  mt={4}
                  display='flex'
                  justifyContent={'space-between'}
                  flexDirection={{ base: 'column', md: 'row' }}
                  alignItems={'center'}
                  color='#7c7c7c'
                >
                  <Box
                    display='flex'
                    gap={{ base: 3, md: 5 }}
                    flexDirection={'row'}
                    alignItems='flex-start'
                    w={'100%'}
                  >
                    {/* <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      <Image src={SourcesIcon} alt="BotIcon" />
                      <Text>{t("Chatbots.Details.Sources")}:</Text>
                      <Text fontWeight="700" color="#212121">
                        {botData?.source_count}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      <Image src={IndexPageIcon} alt="BotIcon" />
                      <Text>{t("Chatbots.Details.Indexed pages")}:</Text>
                      <Text fontWeight="700" color="#212121">
                        {botData?.index_page}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      <Image src={QuestionIocn} alt="BotIcon" />
                      <Text>{t("Chatbots.Details.Questions")}:</Text>
                      <Text fontWeight="700" color="#212121">
                        {botData?.question_count}
                      </Text>
                    </Box> */}

                    {/* total trained pages */}
                    <Box
                      display='flex'
                      alignItems='center'
                      gap={1}
                      fontSize={'12px'}
                      fontWeight={500}
                    >
                      <Image src={IndexPageIcon} alt='BotIcon' />
                      <Text>{t('Chatbots.Details.Total Trained Pages')}:</Text>
                      <Text fontWeight='700' color='#212121'>
                        {botData?.trained_pages || botData?.page_count}
                      </Text>
                    </Box>
                  </Box>

                  <Box display='flex' gap={3} mt={{ base: 4, md: 0 }}>
                    {/* leads */}

                    <Button
                      leftIcon={<HiOutlineUsers size={16} />}
                      variant='outline'
                      onClick={() => handleOpenLeadsModal()}
                      borderRadius={65}
                      border={'1px solid rgba(33, 33, 33, 0.25)'}
                      p={'6px 15px'}
                      fontSize={'12px'}
                      fontWeight={500}
                      h={'28px'}
                      _hover={{ bg: '#002298', color: 'white' }}
                    >
                      {t('Chatbots.Details.Leads')}
                    </Button>
                    <Button
                      leftIcon={<RiDatabase2Line size={16} />}
                      onClick={() =>
                        handleLogPage(hasAccess?.CHAT_HISTORY.enabled)
                      }
                      variant='outline'
                      // isDisabled={!sourceDetails || sourceDetails.length === 0}
                      isDisabled={isChatDisabled}
                      borderRadius={65}
                      border={'1px solid rgba(33, 33, 33, 0.25)'}
                      p={'6px 15px'}
                      fontSize={'12px'}
                      fontWeight={500}
                      // w={"131px"}
                      h={'28px'}
                      _hover={{ bg: '#002298', color: 'white' }}
                    >
                      {t('Chatbots.Details.Chat History')}
                    </Button>

                    <Button
                      leftIcon={<ImStatsDots size={16} />}
                      variant='outline'
                      onClick={() => handleOpenChartModal()}
                      borderRadius={65}
                      border={'1px solid rgba(33, 33, 33, 0.25)'}
                      p={'6px 15px'}
                      fontSize={'12px'}
                      fontWeight={500}
                      h={'28px'}
                      _hover={{ bg: '#002298', color: 'white' }}
                    >
                      {t('Chatbots.Details.Analytics')}
                    </Button>

                    {/* <Button
                      leftIcon={<FiMessageSquare size={16} />}
                      variant="outline"
                      onClick={() =>
                        handleAskquestionModal(
                          hasAccess?.QUESTIONS.enabled,
                          "question_answering"
                        )
                      }
                      // isDisabled={!sourceDetails || sourceDetails.length === 0}
                      isDisabled={isChatDisabled}
                      borderRadius={65}
                      border={"1px solid rgba(33, 33, 33, 0.25)"}
                      p={"6px 15px"}
                      fontSize={"12px"}
                      fontWeight={500}
                      w={"75px"}
                      h={"28px"}
                      _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                    >
                      Q/A
                    </Button> */}

                    <Button
                      leftIcon={<TbMessages size={16} />}
                      _hover={{ bg: '#002298', color: 'white' }}
                      variant='outline'
                      onClick={() =>
                        handleAskquestionModal(
                          hasAccess?.QUESTIONS.enabled,
                          'chat'
                        )
                      }
                      // isDisabled={!sourceDetails || sourceDetails.length === 0}
                      isDisabled={isChatDisabled}
                      borderRadius={65}
                      border={'1px solid rgba(33, 33, 33, 0.25)'}
                      p={'6px 15px'}
                      fontSize={'12px'}
                      fontWeight={500}
                      // w={"75px"}
                      h={'28px'}
                    >
                      {/* Chat */}
                      {t('Chatbots.Details.Test Chatbot')}
                    </Button>
                  </Box>
                </Box>
              </CardBody>

              <CardFooter
                display='flex'
                flexDirection={'column'}
                p={'15px'}
                pt={0}
              >
                <Divider mb={3} />
                <SimpleGrid minChildWidth='70px' spacing='0'>
                  <Box
                    display='flex'
                    alignItems='center'
                    gap={1.3}
                    fontSize={'12px'}
                  >
                    <Image src={CalenderIcon} alt='CalenderIcon' />
                    <Text>
                      {new Date(botData?.created_ts).toLocaleDateString()}
                    </Text>
                  </Box>

                  <Box
                    display='flex'
                    alignItems='center'
                    gap={1.3}
                    fontSize={'12px'}
                  >
                    {botData?.privacy === 'public' ? (
                      <AiOutlineEye size={16} />
                    ) : (
                      <AiOutlineEyeInvisible size={16} />
                    )}
                    <Text>{botData?.privacy}</Text>
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    gap={1.3}
                    fontSize={'12px'}
                  >
                    <AiOutlineCodeSandbox size={16} />
                    <Text>{botData?.openai_model}</Text>
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    gap={1.3}
                    fontSize={'12px'}
                  >
                    <HiOutlineLanguage size={16} />
                    <Text>{botData?.bot_language}</Text>
                  </Box>

                  <Box
                    onClick={
                      (e) => handleDefaultPromptClick(e)
                      // user_planName === "Free"
                      //   ? handleShowPricingChart
                      //   : handleDefaultPromptClick
                    }
                    _hover={{ color: '#002298' }}
                    display='flex'
                    alignItems='center'
                    gap={1.3}
                    fontSize={'12px'}
                    cursor='pointer'
                  >
                    <MdOutlineSettings size={16} />
                    <Text>{t('Chatbots.Details.Default prompt')}</Text>
                  </Box>

                  <Box
                    _hover={{ color: '#002298' }}
                    display='flex'
                    alignItems='center'
                    gap={1.3}
                    fontSize={'12px'}
                    cursor='pointer'
                    onClick={handleOpenCreatebotModal}
                  >
                    <BsSliders2 size={16} />
                    <Text>{t('Chatbots.Details.General Settings')}</Text>
                  </Box>
                  <Box
                    _hover={{ color: '#002298' }}
                    display='flex'
                    alignItems='center'
                    gap={2.3}
                    fontSize={'12px'}
                    cursor='pointer'
                    onClick={handleOpenModalInterface}
                  >
                    <MdOutlineColorLens size={16} />
                    <Text>{t('Chatbots.Details.Customize')}</Text>
                  </Box>
                </SimpleGrid>
              </CardFooter>
            </Card>
            {/* SourcesList */}
            <Box
              w={{ base: '100%', md: '350px' }}
              h={'246px'}
              borderRadius={'10px'}
            >
              <SourcesList
                // sourceDetails={sourceDetails}
                sourceCategoryDetails={sourceCategoryDetails}
                setSourceId={setSourceId}
                setSelectedSource={setSelectedSource}
                setShowSoucesModal={setShowSoucesModal}
              />
            </Box>
          </Flex>

          <Box mt={5}>
            <Card mt={2}>
              <CardHeader>
                <Text fontWeight={500} fontSize={'18px'} color='#212121'>
                  {t('Chatbots.Details.Source type')}
                </Text>
                <Text fontSize={'15px'} color='#212121' opacity={0.6}>
                  {t(
                    'Chatbots.Details.Add any content sources you want your bot to be able to answer questions about. You can always add more later on'
                  )}
                </Text>
              </CardHeader>
              <CardBody pt={0}>
                <Divider />
                <Box
                  mt={7}
                  display={'flex'}
                  justifyContent={'space-around'}
                  flexDirection={{ base: 'column', md: 'row' }}
                  gap={2}
                  h={'full'}
                  w={'100%'}
                  // border={"1px solid red"}
                >
                  <Box w={{ base: '100%', md: '626px' }}>
                    <SimpleGrid
                      spacing={1}
                      templateColumns={{
                        base: 'repeat(auto-fill, minmax(150px, 1fr))',
                        md: 'repeat(auto-fill, minmax(200px, 1fr))',
                      }}
                      rowGap={3}
                    >
                      <Box
                        maxW={{ base: '160px', md: '198px' }}
                        h='145px'
                        borderWidth={'1px'}
                        borderRadius='lg'
                        bg={addURL ? 'blue.50' : ''}
                        display='flex'
                        justifyContent={'space-around'}
                        flexDirection={'column'}
                        p='15px'
                        cursor='pointer'
                        onClick={handleAddURL}
                        borderColor={addURL ? 'blue' : 'gray.200'}
                      >
                        <Box
                          display='flex'
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                        >
                          <Image src={UrlIcon} alt='BotIcon' />
                          {addURL ? <BsCheckCircleFill color='#002298' /> : ''}
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                          <Heading
                            fontSize={'15px'}
                            color='#212121'
                            fontWeight={500}
                          >
                            {t('Chatbots.Details.URL')}
                          </Heading>
                          <Text
                            fontSize={'12px'}
                            color='#212121'
                            fontWeight={500}
                            opacity={0.6}
                          >
                            {t(
                              'Chatbots.Details.Answer from the content from a single webpage'
                            )}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        maxW={{ base: '160px', md: '198px' }}
                        h='145px'
                        borderWidth={'1px'}
                        borderRadius='lg'
                        bg={addDocument ? 'blue.50' : ''}
                        display='flex'
                        justifyContent={'space-around'}
                        flexDirection={'column'}
                        p='15px'
                        cursor='pointer'
                        onClick={handleAddDocument}
                        borderColor={addDocument ? 'blue' : 'gray.200'}
                      >
                        <Box
                          display='flex'
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                        >
                          <Image src={DocumentIcon} alt='BotIcon' />
                          {addDocument ? (
                            <BsCheckCircleFill color='#002298' />
                          ) : (
                            ''
                          )}
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                          <Heading
                            fontSize={'15px'}
                            color='#212121'
                            fontWeight={500}
                          >
                            {t('Chatbots.Details.Document')}
                          </Heading>
                          <Text
                            fontSize={'12px'}
                            color='#212121'
                            fontWeight={500}
                            opacity={0.6}
                          >
                            {t(
                              'Chatbots.Details.Upload document files containing text'
                            )}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        maxW={{ base: '160px', md: '198px' }}
                        h='145px'
                        borderWidth={'1px'}
                        borderRadius='lg'
                        bg={addSitemap ? 'rgba(255, 131, 33, 0.04)' : ''}
                        display='flex'
                        justifyContent={'space-around'}
                        flexDirection={'column'}
                        p='15px'
                        cursor='pointer'
                        onClick={handleAddSitemap}
                        borderColor={addSitemap ? '#FF8321' : 'gray.200'}
                      >
                        <Box
                          display='flex'
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                        >
                          <Image src={SitemapIcon} alt='BotIcon' />
                          {addSitemap ? (
                            <BsCheckCircleFill color='#002298' />
                          ) : (
                            ''
                          )}
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                          <Flex gap={2}>
                            <Heading
                              fontSize={'15px'}
                              color='#212121'
                              fontWeight={500}
                            >
                              {/* Sitemap */}
                              {'Sitemap'}
                            </Heading>
                            {!hasAccess?.SOURCES.includes('SITEMAP') && (
                              <Badge
                                size='sm'
                                variant='subtle'
                                colorScheme='linkedin'
                                borderRadius='10px'
                              >
                                PAID
                              </Badge>
                            )}
                          </Flex>
                          <Text
                            fontSize={'12px'}
                            color='#212121'
                            fontWeight={500}
                            opacity={0.6}
                          >
                            {t(
                              'Chatbots.Details.Answer from all content on a website with its XML sitemap'
                            )}
                          </Text>
                        </Box>
                      </Box>

                      <Box
                        maxW={{ base: '160px', md: '198px' }}
                        h='145px'
                        borderWidth={'1px'}
                        borderRadius='lg'
                        bg={addURLList ? 'rgba(255, 131, 33, 0.04)' : ''}
                        display='flex'
                        justifyContent={'space-around'}
                        flexDirection={'column'}
                        p='15px'
                        cursor='pointer'
                        onClick={handleAddURLList}
                        borderColor={addURLList ? '#FF8321' : 'gray.200'}
                      >
                        <Box
                          display='flex'
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                        >
                          <Image src={UrlListIcon} alt='BotIcon' />
                          {addURLList ? (
                            <BsCheckCircleFill color='#002298' />
                          ) : (
                            ''
                          )}
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                          <Flex gap={2}>
                            <Heading
                              fontSize={'15px'}
                              color='#212121'
                              fontWeight={500}
                            >
                              {t('Chatbots.Details.URL List')}
                            </Heading>
                            {!hasAccess?.SOURCES.includes('URL LIST') && (
                              <Badge
                                size='sm'
                                variant='subtle'
                                colorScheme='linkedin'
                                borderRadius='10px'
                              >
                                PAID
                              </Badge>
                            )}
                          </Flex>
                          <Text
                            fontSize={'12px'}
                            color='#212121'
                            fontWeight={500}
                            opacity={0.6}
                          >
                            {t(
                              'Chatbots.Details.Index all content from a bulk list of URLs'
                            )}
                          </Text>
                        </Box>
                      </Box>

                      <Box
                        maxW={{ base: '160px', md: '198px' }}
                        h='145px'
                        borderWidth={'1px'}
                        borderRadius='lg'
                        bg={addRSSFeed ? 'rgba(255, 131, 33, 0.04)' : ''}
                        display='flex'
                        justifyContent={'space-around'}
                        flexDirection={'column'}
                        p='15px'
                        cursor='pointer'
                        onClick={handleAddRSSFeed}
                        borderColor={addRSSFeed ? '#FF8321' : 'gray.200'}
                      >
                        <Box
                          display='flex'
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                        >
                          <Image src={RssfeedIcon} alt='BotIcon' />
                          {addRSSFeed ? (
                            <BsCheckCircleFill color='#002298' />
                          ) : (
                            ''
                          )}
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                          <Flex gap={2}>
                            <Heading
                              fontSize={'15px'}
                              color='#212121'
                              fontWeight={500}
                            >
                              RSS Feed
                            </Heading>
                            {!hasAccess?.SOURCES.includes('RSS FEED') && (
                              <Badge
                                size='sm'
                                variant='subtle'
                                colorScheme='linkedin'
                                borderRadius='10px'
                              >
                                PAID
                              </Badge>
                            )}
                          </Flex>
                          <Text
                            fontSize={'12px'}
                            color='#212121'
                            fontWeight={500}
                            opacity={0.6}
                          >
                            {t(
                              'Chatbots.Details.Answer from post content from a blog RSS feed url'
                            )}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        maxW={{ base: '160px', md: '198px' }}
                        h={{ base: '145px', md: '145px' }}
                        borderWidth={'1px'}
                        borderRadius='lg'
                        display='flex'
                        justifyContent={'space-between'}
                        flexDirection={'column'}
                        p='15px'
                        cursor='pointer'
                        bg={addQA ? 'rgba(255, 131, 33, 0.04)' : ''}
                        borderColor={addQA ? '#FF8321' : 'gray.200'}
                        onClick={handleAddQA}
                      >
                        <Box
                          display='flex'
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                        >
                          <Image src={QAndAIcon} alt='BotIcon' />
                          {addQA ? <BsCheckCircleFill color='#002298' /> : ''}
                        </Box>
                        <Box>
                          <Heading
                            fontSize={'15px'}
                            color='#212121'
                            fontWeight={500}
                          >
                            Q&A
                          </Heading>
                          <Text
                            fontSize={'12px'}
                            color='#212121'
                            fontWeight={500}
                            opacity={0.6}
                          >
                            {t(
                              "Chatbots.Details.Improve bot's performance with common questions and answers"
                            )}{' '}
                          </Text>
                        </Box>
                      </Box>
                      {/* new upcoming sources */}
                      {cardData.map(
                        ({ type, icon, isPaid, title, description }) => (
                          <BotCard
                            key={type}
                            icon={icon}
                            isPaid={isPaid}
                            title={title}
                            description={description}
                            isActive={eval(`add${type}`)}
                            // isActive= {notion? true: false}
                            onClick={() =>
                              handleCardClick(
                                type,
                                eval(`${type.toLowerCase()}SectionRef`)
                              )
                            }
                          />
                        )
                      )}
                    </SimpleGrid>
                  </Box>
                  <Box w={{ base: '100%', md: '415px' }} height={'100%'}>
                    {addURL ? (
                      <CardBody
                        background={'rgba(64, 140, 253, 0.06)'}
                        ref={urlSectionRef}
                        display='flex'
                        flexDirection={'column'}
                        gap={5}
                        height={'100%'}
                      >
                        <Box pb={'18px'} borderBottom={'1px solid #8d8d8d'}>
                          <FormLabel>{t('Chatbots.Details.Source')}</FormLabel>
                          <Text
                            textAlign='justify'
                            fontSize={' 14px'}
                            fontWeight={500}
                            color='#212121'
                          >
                            {t(
                              "Chatbots.Details.Enter the URL of a webpage to extract knowledge from. It can be a blog post, news article, or any web page. We'll download and parse the content, adding it to your bot. Maximum file size is 100MB"
                            )}
                          </Text>
                        </Box>
                        <FormControl as='form' onSubmit={handleAddURLSource}>
                          <Box mb={5}>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel fontSize={'15px'}>
                                {t('Chatbots.Details.Source URL')}
                              </FormLabel>
                            </Box>
                            <Input
                              bg={'white'}
                              type='url'
                              placeholder='https://example.com/page/'
                              value={urlFormData.sourceURL}
                              required
                              onChange={(e) =>
                                handleInputChange(e, 'sourceURL')
                              }
                            />
                            <Text
                              fontSize={'12px'}
                              color='#212121'
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                'Chatbots.Details.Clickable URL of source link displayed with answers'
                              )}
                              .
                            </Text>
                          </Box>

                          <Flex
                            mb={5}
                            p={3}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            border={'1px solid'}
                            borderColor={'gray.200'}
                            borderRadius={'10px'}
                          >
                            <Flex>
                              <FormLabel
                                fontSize={'12px'}
                                htmlFor='singlePage'
                                mb={0}
                              >
                                Add Single URL From This Website
                              </FormLabel>
                              <MdInfoOutline size={18} />
                            </Flex>
                            <Switch
                              id='singlePage'
                              value={urlFormData.isSinglePage}
                              checked={urlFormData.isSinglePage}
                              onChange={(e) =>
                                handleUrlSwitchChange(e, 'isSinglePage')
                              }
                            />
                          </Flex>

                          {/* <Box mb={5}>
                            <Box display="flex" justifyContent="space-between">
                              <FormLabel fontSize={"15px"}>
                                {t("Chatbots.Details.Source title")}
                              </FormLabel>
                            </Box>
                            <Input
                              bg={"white"}
                              value={urlFormData.sourceTitle}
                              onChange={(e) =>
                                handleInputChange(e, "sourceTitle")
                              }
                            />
                            <Text
                              fontSize={"12px"}
                              color="#212121"
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                "Chatbots.Details.Title of source displayed alongside answers. Defaults to page title or file name"
                              )}
                            </Text>
                          </Box> */}
                          <Box mb={10}>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel fontSize={'15px'}>
                                {t('Chatbots.Details.Scheduled refresh')}
                              </FormLabel>
                            </Box>
                            <Select
                              bg={'white'}
                              required
                              onChange={(e) =>
                                handleInputChange(e, 'scheduledRefresh')
                              }
                              defaultValue='Never'
                            >
                              <option disabled>
                                {t('Chatbots.Details.Select an option')}
                              </option>
                              <option value='Never'>
                                {t('Chatbots.Details.Never')}
                              </option>
                              <option value='Monthly'>
                                {t('Chatbots.Details.Monthly')}
                              </option>
                              <option value='Weekly'>
                                {t('Chatbots.Details.Weekly')}
                              </option>
                            </Select>
                            <Text
                              fontSize={'12px'}
                              color='#212121'
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                'Chatbots.Details.This will automatically refresh the source at the selected interval'
                              )}
                            </Text>
                          </Box>
                          <ButtonGroup
                            spacing='2'
                            pt={4}
                            display={'flex'}
                            justifyContent={'flex-end'}
                          >
                            <Button
                              border={'1px solid rgba(33, 33, 33, 0.15)'}
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              variant='outline'
                              borderRadius={'65px'}
                              color='#979797'
                            >
                              {t('Chatbots.Details.Cancel')}
                              {/* Cancel */}
                            </Button>
                            <Button
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              _hover={{ bg: '#f97913' }}
                              // isLoading={isLoading}
                              loadingText='Indexing'
                              type='submit'
                              borderRadius={'65px'}
                              variant='solid'
                              bg={'#002298'}
                              color={'white'}
                            >
                              {t('Chatbots.Details.Add source')}
                              {/* Add source */}
                            </Button>
                          </ButtonGroup>
                        </FormControl>
                      </CardBody>
                    ) : addDocument ? (
                      <CardBody
                        background={'rgba(64, 140, 253, 0.06)'}
                        ref={documentSectionRef}
                        display='flex'
                        flexDirection={'column'}
                        gap={5}
                      >
                        <Box pb={'18px'} borderBottom={'1px solid #8d8d8d'}>
                          <FormLabel>{t('Chatbots.Details.Source')}</FormLabel>
                          <Text
                            textAlign='justify'
                            fontSize={' 14px'}
                            fontWeight={500}
                            color='#212121'
                          >
                            {t(
                              'Chatbots.Details.Upload a text document to extract knowledge from. Examples include product documentation, slide decks, research papers, employee handbooks, ebooks, or any text-based content. We support .txt, .docx, .pptx, .eml, .html, and .pdf file formats. Our system will parse the content and add it to the bot for you.'
                            )}
                          </Text>

                          {/* <Text cursor={'pointer'} textDecoration={'underline'} >Download an exmaple PDF document</Text> */}
                        </Box>
                        <FormControl
                          as='form'
                          onSubmit={handleAddDocumentSource}
                        >
                          <Box mb={5}>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel>
                                {t('Chatbots.Details.Source title')}
                              </FormLabel>
                            </Box>
                            <Input
                              bg={'white'}
                              required
                              value={documentFormData.sourceTitle}
                              onChange={(e) =>
                                handleDocumentInputChange(e, 'sourceTitle')
                              }
                            />
                            <Text
                              fontSize={'12px'}
                              color='#212121'
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                'Chatbots.Details.Title of source displayed alongside answers. Defaults to page title or file name'
                              )}
                            </Text>
                          </Box>
                          <Box>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel>
                                {t('Chatbots.Details.Source file')}
                              </FormLabel>
                            </Box>
                            <label htmlFor='sourceFileUpload'>
                              <input
                                bg={'white'}
                                type='file'
                                id='sourceFileUpload'
                                accept='.txt, .docx, .pptx, .eml, .html, .pdf'
                                onChange={(e) =>
                                  handleDocumentInputChange(e, 'sourceFile')
                                }
                                style={{ display: 'none' }}
                                required
                              />
                              <Box
                                border='1px dashed #979797'
                                borderRadius={5}
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                p={10}
                                cursor='pointer'
                              >
                                <BsFileEarmarkArrowUp
                                  size={40}
                                  color='#979797'
                                />
                                <Text mt={2}>
                                  {t(
                                    'Chatbots.Details.Upload your source file'
                                  )}
                                </Text>
                                <Text
                                  color='#979797'
                                  fontSize='xs'
                                  fontWeight={500}
                                >
                                  TXT, DOCX, PPTX, EML, HTML, PDF
                                </Text>
                                {documentFormData.sourceFile && (
                                  <Text
                                    color={'green.700'}
                                    fontSize='sm'
                                    fontWeight={600}
                                    mt={2}
                                  >
                                    {documentFormData.sourceFile.name}
                                  </Text>
                                )}
                              </Box>
                            </label>
                          </Box>
                          <ButtonGroup
                            spacing='2'
                            pt={4}
                            display={'flex'}
                            justifyContent={'flex-end'}
                          >
                            <Button
                              border={'1px solid rgba(33, 33, 33, 0.15)'}
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              variant='outline'
                              borderRadius={'65px'}
                              color='#979797'
                            >
                              {t('Chatbots.Details.Cancel')}
                            </Button>
                            <Button
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              _hover={{ bg: '#f97913' }}
                              isLoading={isLoading}
                              loadingText='Indexing'
                              type='submit'
                              borderRadius={'65px'}
                              variant='solid'
                              bg={'#002298'}
                              color={'white'}
                            >
                              {t('Chatbots.Details.Add source')}
                            </Button>
                          </ButtonGroup>
                        </FormControl>
                      </CardBody>
                    ) : addSitemap ? (
                      <CardBody
                        background={'rgba(64, 140, 253, 0.06)'}
                        ref={sitemapSectionRef}
                        display='flex'
                        flexDirection={'column'}
                        gap={5}
                      >
                        <Box pb={'18px'} borderBottom={'1px solid #8d8d8d'}>
                          <FormLabel>{t('Chatbots.Details.Source')}</FormLabel>
                          <Text
                            textAlign='justify'
                            fontSize={' 14px'}
                            fontWeight={500}
                            color='#212121'
                          >
                            {t(
                              "Chatbots.Details.Enter the URL of a website's sitemap to extract knowledge from. Our system will parse all sitemaps on the domain, download the HTML pages, extract the content, and add it to the bot's index."
                            )}
                          </Text>
                        </Box>
                        <FormControl
                          as='form'
                          onSubmit={handleAddSitemapSource}
                        >
                          <Box mb={5}>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel fontSize={'15px'}>
                                {t('Chatbots.Details.Source URL')}
                              </FormLabel>
                            </Box>
                            <Input
                              bg={'white'}
                              type='url'
                              placeholder='https://example.com/sitemap.xml'
                              value={sitemapFormData.sourceURL}
                              required
                              onChange={(e) => {
                                handleSiteMapInputChange(e, 'sourceURL');
                                setShowErrorMessage(false);
                              }}
                            />
                            {showErrorMessage && (
                              <Text
                                fontSize={'12px'}
                                color={'red'}
                                fontWeight={500}
                                opacity={0.6}
                              >
                                {t(
                                  'Chatbots.Details.URL end must contains with /sitemap.xml/ or /sitemap.xml'
                                )}
                              </Text>
                            )}
                            <Text
                              fontSize={'12px'}
                              color='#212121'
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                'Chatbots.Details.Clickable URL of source link displayed with answers'
                              )}
                            </Text>
                          </Box>
                          <Box mb={5}>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel fontSize={'15px'}>
                                {t('Chatbots.Details.Source title')}
                              </FormLabel>
                            </Box>
                            <Input
                              bg={'white'}
                              value={sitemapFormData.sourceTitle}
                              onChange={(e) =>
                                handleSiteMapInputChange(e, 'sourceTitle')
                              }
                            />
                            <Text
                              fontSize={'12px'}
                              color='#212121'
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                'Chatbots.Details.Title of source displayed alongside answers. Defaults to page title or file name'
                              )}
                            </Text>
                          </Box>
                          <Box>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel fontSize={'15px'}>
                                {t('Chatbots.Details.Scheduled refresh')}
                              </FormLabel>
                            </Box>
                            <Select
                              bg={'white'}
                              required
                              onChange={(e) =>
                                handleSiteMapInputChange(e, 'scheduledRefresh')
                              }
                              defaultValue='Never'
                            >
                              <option disabled>
                                {t('Chatbots.Details.Select an option')}
                              </option>
                              <option value='Never'>
                                {t('Chatbots.Details.Never')}
                              </option>
                              <option value='Monthly'>
                                {t('Chatbots.Details.Monthly')}
                              </option>
                              <option value='Weekly'>
                                {t('Chatbots.Details.Weekly')}
                              </option>
                            </Select>
                            <Text
                              fontSize={'12px'}
                              color='#212121'
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                'Chatbots.Details.This will automatically refresh the source at the selected interval'
                              )}
                            </Text>
                          </Box>
                          <ButtonGroup
                            spacing='2'
                            pt={4}
                            display={'flex'}
                            justifyContent={'flex-end'}
                          >
                            <Button
                              border={'1px solid rgba(33, 33, 33, 0.15)'}
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              variant='outline'
                              borderRadius={'65px'}
                              color='#979797'
                            >
                              {t('Chatbots.Details.Cancel')}
                            </Button>
                            <Button
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              _hover={{ bg: '#f97913' }}
                              isLoading={isLoading}
                              loadingText='Indexing'
                              type='submit'
                              borderRadius={'65px'}
                              variant='solid'
                              bg={'#002298'}
                              color={'white'}
                            >
                              {t('Chatbots.Details.Add source')}
                            </Button>
                          </ButtonGroup>
                        </FormControl>
                      </CardBody>
                    ) : addURLList ? (
                      <CardBody
                        background={'rgba(64, 140, 253, 0.06)'}
                        ref={urlListSectionRef}
                        display='flex'
                        flexDirection={'column'}
                        gap={5}
                      >
                        <Box pb={'18px'} borderBottom={'1px solid #8d8d8d'}>
                          <FormLabel>
                            {t('Chatbots.Details.URL List')}
                          </FormLabel>
                          <Text
                            textAlign='justify'
                            fontSize={' 14px'}
                            fontWeight={500}
                            color='#212121'
                          >
                            {t(
                              "Chatbots.Details.Please upload TXT or CSV file containing URLs. We'll download and parse the HTML content of the listed pages to add them to this bot's index"
                            )}{' '}
                            <Link
                              to='#'
                              onClick={handleExampleDownload}
                              style={{
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                              }}
                            >
                              {t(
                                'Chatbots.Details.Download an example CSV urls file'
                              )}
                            </Link>
                          </Text>
                        </Box>
                        <FormControl
                          as='form'
                          onSubmit={handleAddNewURLList}
                          isRequired
                        >
                          <Box mb={5}>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel fontSize={'15px'}>
                                {t('Chatbots.Details.Upload Source File')}
                              </FormLabel>
                            </Box>
                            <label htmlFor='sourceFileUpload'>
                              <Box
                                height={200}
                                width={'100%'}
                                position='absolute'
                                cursor='pointer'
                              >
                                <input
                                  bg={'white'}
                                  type='file'
                                  id='sourceFileUpload'
                                  accept='.csv, .txt'
                                  onChange={(e) =>
                                    handleURLListInputChange(e, 'sourceFile')
                                  }
                                  style={{ display: 'none' }}
                                  required
                                />
                              </Box>
                              <Box
                                height={200}
                                width={'100%'}
                                border='2px dashed #408CFD'
                                borderRadius={10}
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                p={10}
                                cursor='pointer'
                                onDrop={handleFileDrop}
                                onDragOver={handleDragOver}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  alignItems='center'
                                  mt={5}
                                >
                                  <BsFileEarmarkArrowUp
                                    size={30}
                                    color='#408CFD'
                                  />
                                  <Text mt={2}>
                                    {t('Chatbots.Details.Drag & drop, or')}{' '}
                                    <Link
                                      to=''
                                      style={{
                                        textDecoration: 'underline',
                                        color: '#5699FD',
                                      }}
                                    >
                                      {t('Chatbots.Details.Browse')}
                                    </Link>
                                  </Text>
                                  <Text
                                    color='#979797'
                                    fontSize='xs'
                                    fontWeight={500}
                                  >
                                    {t('Chatbots.Details.Supported')}: CSV, TXT
                                  </Text>
                                  {urlListFormData.sourceFile && (
                                    <Text
                                      color={'green.700'}
                                      fontSize='sm'
                                      fontWeight={600}
                                      mt={2}
                                    >
                                      {urlListFormData.sourceFile.name}
                                    </Text>
                                  )}
                                </Box>
                              </Box>
                            </label>
                          </Box>

                          <Box>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel fontSize={'15px'}>
                                {t('Chatbots.Details.Scheduled refresh')}
                              </FormLabel>
                            </Box>
                            <Select
                              bg={'white'}
                              required
                              onChange={(e) =>
                                handleURLListInputChange(e, 'scheduledRefresh')
                              }
                              defaultValue='Never'
                            >
                              <option disabled>
                                {t('Chatbots.Details.Select an option')}
                              </option>
                              <option value='Never'>
                                {t('Chatbots.Details.Never')}
                              </option>
                              <option value='Monthly'>
                                {t('Chatbots.Details.Monthly')}
                              </option>
                              <option value='Weekly'>
                                {t('Chatbots.Details.Weekly')}
                              </option>
                            </Select>
                            <Text
                              fontSize={'12px'}
                              color='#212121'
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                'Chatbots.Details.This will automatically refresh the source at the selected interval'
                              )}
                            </Text>
                          </Box>
                          <ButtonGroup
                            spacing='2'
                            pt={4}
                            display={'flex'}
                            justifyContent={'flex-end'}
                          >
                            <Button
                              border={'1px solid rgba(33, 33, 33, 0.15)'}
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              variant='outline'
                              borderRadius={'65px'}
                              color='#979797'
                            >
                              {t('Chatbots.Details.Cancel')}
                            </Button>
                            <Button
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              _hover={{ bg: '#f97913' }}
                              isLoading={isLoading}
                              loadingText='Indexing'
                              type='submit'
                              borderRadius={'65px'}
                              variant='solid'
                              bg={'#002298'}
                              color={'white'}
                            >
                              {t('Chatbots.Details.Add source')}
                            </Button>
                          </ButtonGroup>
                        </FormControl>
                      </CardBody>
                    ) : addRSSFeed ? (
                      <CardBody
                        background={'rgba(64, 140, 253, 0.06)'}
                        ref={rssFeedSectionRef}
                        display='flex'
                        flexDirection={'column'}
                        gap={5}
                      >
                        <Box pb={'18px'} borderBottom={'1px solid #8d8d8d'}>
                          <FormLabel>{t('Chatbots.Details.Source')}</FormLabel>
                          <Text
                            textAlign='justify'
                            fontSize={' 14px'}
                            fontWeight={500}
                            color='#212121'
                          >
                            {t(
                              'Chatbots.Details.Enter the URL of a webpage to extract knowledge from. It can be a blog post, news article, or any web page. Our system will download and parse the content, adding it to the bot'
                            )}
                          </Text>
                        </Box>
                        <FormControl
                          as='form'
                          onSubmit={handleAddRSSFeedSource}
                        >
                          <Box mb={5}>
                            <Box display='flex' justifyContent='space-between'>
                              <FormLabel fontSize={'15px'}>
                                {t('Chatbots.Details.Source URL')}
                              </FormLabel>
                            </Box>
                            <Input
                              bg={'white'}
                              type='url'
                              placeholder='https://example.com/page/feed'
                              value={addRssFeedURL}
                              required
                              onChange={(e) => {
                                setAddRssFeedURL(e.target.value);
                                setShowErrorMessage(false);
                              }}
                            />
                            {showErrorMessage && (
                              <Text
                                fontSize={'12px'}
                                color={'red'}
                                fontWeight={500}
                                opacity={0.6}
                              >
                                URL end must contains with /feed/ or /feed
                              </Text>
                            )}
                            <Text
                              fontSize={'12px'}
                              color='#212121'
                              fontWeight={500}
                              opacity={0.6}
                            >
                              {t(
                                'Chatbots.Details.Clickable URL of source link displayed with answers'
                              )}
                              .
                            </Text>
                          </Box>

                          <ButtonGroup
                            spacing='2'
                            pt={4}
                            display={'flex'}
                            justifyContent={'flex-end'}
                          >
                            <Button
                              border={'1px solid rgba(33, 33, 33, 0.15)'}
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              variant='outline'
                              borderRadius={'65px'}
                              color='#979797'
                            >
                              {t('Chatbots.Details.Cancel')}
                            </Button>
                            <Button
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              _hover={{ bg: '#f97913' }}
                              isLoading={isLoading}
                              loadingText='Indexing'
                              type='submit'
                              borderRadius={'65px'}
                              variant='solid'
                              bg={'#002298'}
                              color={'white'}
                            >
                              {t('Chatbots.Details.Add source')}
                            </Button>
                          </ButtonGroup>
                        </FormControl>
                      </CardBody>
                    ) : addQA ? (
                      <CardBody
                        background={'rgba(64, 140, 253, 0.06)'}
                        ref={sitemapSectionRef}
                        display='flex'
                        flexDirection={'column'}
                        gap={5}
                      >
                        <Box pb={'18px'} borderBottom={'1px solid #8d8d8d'}>
                          <FormLabel>
                            {t('Chatbots.Details.Questions & Answers')}
                          </FormLabel>
                          <Text
                            textAlign='justify'
                            fontSize={' 14px'}
                            fontWeight={500}
                            color='#212121'
                          >
                            {t(
                              "Chatbots.Details.Craft your own list of frequently asked questions (FAQs) manually. This powerful method allows you to train your bot to provide accurate responses to common inquiries about your product or service. It's also an effective way to refine the answers for specific challenging questions."
                            )}
                          </Text>
                        </Box>
                        <FormControl as='form' onSubmit={handleSubmitQA}>
                          <div>
                            {questions.map((qna, index) => (
                              <div key={index}>
                                <Box mb={5}>
                                  <Box
                                    display='flex'
                                    justifyContent='space-between'
                                  >
                                    <FormLabel fontSize='15px'>
                                      {t('Chatbots.Details.Question')}
                                    </FormLabel>
                                    {index !== 0 && (
                                      <DeleteIcon
                                        color={'red'}
                                        onClick={() =>
                                          handleDeleteQuestion(index)
                                        }
                                        size='sm'
                                        colorScheme='red'
                                      />
                                    )}
                                  </Box>
                                  <Input
                                    bg='white'
                                    placeholder={t(
                                      'Chatbots.Details.Ex How can I reset my password?'
                                    )}
                                    value={qna.question}
                                    onChange={(e) =>
                                      handleQuestionChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                </Box>
                                <Box mb={5}>
                                  <Box
                                    display='flex'
                                    justifyContent='space-between'
                                  >
                                    <FormLabel fontSize='15px'>
                                      {t('Chatbots.Details.Answer')}
                                    </FormLabel>
                                  </Box>
                                  <Textarea
                                    bg='white'
                                    placeholder={t(
                                      'Chatbots.Details.Enter your answer here'
                                    )}
                                    value={qna.answer}
                                    onChange={(e) =>
                                      handleAnswerChange(index, e.target.value)
                                    }
                                    required
                                  />
                                </Box>
                              </div>
                            ))}
                            <Flex
                              alignItems='center'
                              justifyContent='center'
                              mt={5}
                            >
                              <Divider borderColor={'black'} flex='1' />
                              <Button
                                leftIcon={<AddIcon />}
                                border={'1px solid rgba(33, 33, 33, 0.15)'}
                                h={'33px'}
                                fontSize={'14px'}
                                variant='outline'
                                borderRadius={'65px'}
                                color='#979797'
                                onClick={handleAddQuestion}
                              >
                                {t('Chatbots.Details.Add')}
                              </Button>
                              <Divider borderColor={'black'} flex='1' />
                            </Flex>
                          </div>
                          <ButtonGroup
                            spacing='2'
                            pt={4}
                            display={'flex'}
                            justifyContent={'flex-end'}
                          >
                            <Button
                              border={'1px solid rgba(33, 33, 33, 0.15)'}
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              variant='outline'
                              borderRadius={'65px'}
                              color='#979797'
                            >
                              {t('Chatbots.Details.Cancel')}
                            </Button>
                            <Button
                              w={'128px'}
                              h={'33px'}
                              fontSize={'14px'}
                              _hover={{ bg: '#f97913' }}
                              isLoading={isLoading}
                              loadingText='Indexing'
                              type='submit'
                              borderRadius={'65px'}
                              variant='solid'
                              bg={'#002298'}
                              color={'white'}
                            >
                              {t('Chatbots.Details.Add source')}
                            </Button>
                          </ButtonGroup>
                        </FormControl>
                      </CardBody>
                    ) : (
                      ''
                    )}{' '}
                  </Box>
                </Box>
              </CardBody>
            </Card>
          </Box>

          {/* All the modals/pop-up  are below here*/}

          {/* Data Sources Crawling Modal */}

          {showSourcesModal && (
            <SourcesCrawlerModal
              setIsloading={setIsloading}
              isOpen={showSourcesModal}
              botId={botId}
              fetchSourceCategory={fetchSourceCategory}
              details={details}
              Package={Package}
              showToast={showToast}
              onClose={() => {
                setShowSoucesModal(false);
                fetchSourceCategory();
                setIsloading(false);
                fetchSource();
                fetchBotData();
                fetchBotData();
              }}
              handleCallback={handleCallback}
            />
          )}

          {/* Pricing Chart modal for users to upgrade the plan */}
          {showPricingModal && (
            <PricingChartModel
              isOpen={showPricingModal}
              onClose={handleClosePricingModal}
            />
          )}
          {/* Chat/Conversation History modal */}
          {botData && (
            <ChatHistory
              isOpen={showChatHistory}
              onClose={handleCloseChatHistory}
              botData={botData}
            />
          )}
          {/* Widget Customization modal */}
          {botData && (
            <WidgetCustomize
              isOpen={showModal}
              onClose={handleCloseModal}
              botData={botData}
              hasAccess={hasAccess}
            />
          )}
          {/* ChatBot Ask question modal . question_role has either roles for q/n or chat */}
          {botData && (
            <AskQuestion
              isOpen={showAskquestionModal}
              onClose={handleCloseAskquestionModal}
              botData={botData}
              question_role={questionRole}
            />
          )}

          {/* <LogDetails isOpen={showLogModal} onClose={handleCloseLogModal} botData={botData} /> */}

          {/* Modal to create a new chat bot */}
          {botData && (
            <CreateNewBot
              isOpen={createbotModal}
              onClose={handleCreatebotCloseModal}
              onCancel={handleCancel}
              botData={botData}
            />
          )}

          {/* Default Prompt modal */}
          {botData && (
            <DefaultPromptModal
              isOpen={showDefaultPromptModal}
              onClose={handleDefaultPromptCloseModal}
              botData={botData}
            />
          )}

          {/* Modal for share API page */}
          {botData && (
            <ShareAPI
              isOpen={showShareAPIModal}
              onClose={handleShareAPICloseModal}
              botData={botData}
            />
          )}

          {/* Modal for widget script  embed */}
          {botData && (
            <WidgetEmbed
              isOpen={interfaceModal}
              onClose={handleCloseInterface}
              botData={botData}
            />
          )}

          {/* modal that show the Reports or the Analytics for teh chatbot */}
          {chartModal && (
            <ChartModal
              isOpen={chartModal}
              onClose={handleCloseChartModal}
              botData={botData}
            />
          )}

          {leadsModal && (
            <LeadsModal
              isOpen={leadsModal}
              onClose={handleCloseLeadsModal}
              botData={botData}
              setShowToast={setShowToast}
              setmessage={setmessage}
            />
          )}

          {/* Delete source modal */}
          <Modal
            size={'3xl'}
            isOpen={isDeleteSourceModalOpen}
            onClose={() => {
              setIsDeleteSourceModalOpen(false);
              setCardVisible(false);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader display={'flex'} alignItems={'baseline'} gap={1}>
                <Text>
                  {selectedSource?.source_title
                    ? selectedSource?.source_title
                    : selectedSource?.source_url}
                </Text>
                <Text fontSize={'13px'} color={'#b1b1b1'}>
                  {selectedSource?.source_type === 'file'
                    ? 'DOCUMENT'
                    : selectedSource?.source_type?.toUpperCase()}
                </Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  direction={['column', 'row']}
                  justifyContent='space-between'
                  alignItems={['flex-start', 'center']}
                  spacing={2}
                >
                  <Text
                    p='3px 10px'
                    fontSize='xs'
                    background='#BFFFBF'
                    color='#007500'
                    fontWeight='500'
                    borderRadius='100vh'
                  >
                    {t('Chatbots.Details.Indexed')}
                  </Text>
                  <Text fontSize='sm' fontWeight='400'>
                    {selectedSource?.page_count} Pages
                  </Text>
                  <Text fontSize='sm' fontWeight='400'>
                    {t('Chatbots.Details.Created At')}:{' '}
                    {formatCreatedAt(selectedSource?.created_ts)}
                  </Text>
                </Stack>

                <Box>
                  {!isCardVisible && (
                    <Box
                      pb={5}
                      display='flex'
                      alignItems='center'
                      color='red'
                      mt={3}
                      onClick={handleDeleteCardClick}
                      cursor='pointer'
                    >
                      <BsTrash />
                      <Text ml={1} fontSize='sm'>
                        {/* Delete */}
                        {t('Chatbots.Details.Delete')}
                      </Text>
                    </Box>
                  )}
                  {isCardVisible && (
                    <Box p={2}>
                      <Card mt={2}>
                        <CardHeader
                          fontSize={'18px'}
                          fontWeight={600}
                          p={'10px 20px'}
                        >
                          {t('Chatbots.Details.Delete Source Document?')}
                        </CardHeader>
                        <CardBody color={'gray.600'} p={'10px 20px'}>
                          {t(
                            "Chatbots.Details.By choosing to delete this source, you'll be removing all of its pages from your bot's index. Please be aware that it might take alittle while for the changes to take effect across our services, and for the sources to be fully removed from chat results. Are you certain you want to proceed with deleting this source?"
                          )}
                        </CardBody>
                        <CardFooter>
                          <Button
                            onClick={() => {
                              setIsDeleteSourceModalOpen(false);
                              setCardVisible(false);
                            }}
                            mr={1}
                          >
                            {t('Chatbots.Details.Cancel')}
                          </Button>
                          <DarkMode>
                            <Button
                              isLoading={isDeleteLoading}
                              onClick={() => deleteSource(selectedSource)}
                              colorScheme='red'
                              ml={1}
                            >
                              {t('Chatbots.Details.Delete')}
                            </Button>
                          </DarkMode>
                        </CardFooter>
                      </Card>
                    </Box>
                  )}
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
};

const ChatBotDetailsWithAccessControl = withAccessControl(ChatBotDetails);
export default ChatBotDetailsWithAccessControl;
