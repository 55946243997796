import React, { Suspense, memo, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Flex,
  Progress,
  Tag,
  InputGroup,
  InputLeftElement,
  Input,
  Tooltip,
  useToast,
  InputRightElement,
  FormLabel,
  Switch,
} from '@chakra-ui/react';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import { HiLink } from 'react-icons/hi2';
import { MdInfoOutline, MdOutlineSync } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';

import CustomLoader from './SourcesCrawlerComponents/CustomLoader';
import { SearchIcon } from '@chakra-ui/icons';
import { MdInfo } from 'react-icons/md';
import { UrlsTable } from './SourcesCrawlerComponents/UrlsTable';
import FetchWithToken from '../../utils/FetchWithToken';
import { ScanningProgressBar } from './SourcesCrawlerComponents/ScanningProgressBar';
import { IoMdInformationCircle } from 'react-icons/io';

// const UrlsTableComponent = React.lazy(()=> import('./SourcesCrawlerComponents/UrlsTable'));

const SourcesCrawlerModal = ({
  botId,
  fetchSourceCategory,
  onClose,
  showToast,
  isOpen,
  handleCallback,
  details,
  Package,
}) => {
  const [status, setStatus] = useState('');
  const [sources, setSources] = useState();
  const [inputUrl, setInputUrl] = useState('');
  const [isSinglePage, setIsSinglePage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [isAlreadyTrained, setIsAlreadyTrained] = useState(false);
  const toast = useToast();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;
  const selectedTeam = JSON.parse(
    localStorage.getItem('docsbot-users')
  ).selectedTeam;

  const fetchSources = async () => {
    try {
      setIsLoading(true);
      const response = await FetchWithToken(
        BASE_URL + '/chatbot/source?bot_id=' + botId,
        'GET'
      );
      if (response.Chatbots) {
        // console.log(response.Chatbots);
        setSources(response.Chatbots);
        setIsLoading(false);
        const hasScanning = response.Chatbots.some(
          (obj) => obj.job_status === 'Scanning'
        );
        const hasTraining = response.Chatbots.some(
          (obj) => obj.job_status === 'Training'
        );
        const hasTrained = response.Chatbots.some(
          (obj) => obj.job_status === 'Trained'
        );
        // console.log(hasTraining, hasScanning);
        if (hasScanning) {
          setStatus('Scanning');
        } else if (hasTraining) {
          setStatus('Training');
        } else {
          // console.log("ready");
          setStatus('Ready');
        }
        if (hasTrained) {
          setIsAlreadyTrained(true);
        }
      }
      return response;
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch source status:', error);
      return null;
    }
    // FetchWithToken(
    //   //location.state?.botData.bot_id,
    //   BASE_URL + "/chatbot/source?bot_id=" + botId,
    //   "GET"
    // ).then((data) => {
    //   setSources(data.Chatbots);
    // });
  };

  useEffect(() => {
    handleCallback();
  }, [sources]);

  useEffect(() => {
    fetchSources();
  }, []);

  useEffect(() => {
    // console.log(source, source.source_id);
    // const job_status = source.job_status;
    // setStatus(job_status)
    const timestamp = new Date().getTime();
    let fetchUrl = '';

    const updateSources = async () => {
      const response = await fetchSources();
      // console.log(response);
      if (!response) {
        clearInterval(intervalId);
      } else if (response.Chatbots) {
        // Check if any object has status 'scanning' or 'training'
        // if () {
        //   clearInterval(intervalId);
        // }
      }
    };

    let intervalId = '';
    // Start polling when the component mounts
    intervalId = setInterval(() => updateSources(), 10000); // Poll every 10 second
    return () => {
      clearInterval(intervalId);
    };
  }, [sources]);

  const handleScanClick = () => {
    const data = {
      scheduling: 'Never',
      bot_id: botId,
      source_url: inputUrl,
      source_type: 'url',
      is_single_page: isSinglePage,
      user_id: user_id,
      team_id: selectedTeam.team_id,
      current_plan: details?.current_plan,
    };
    setIsScanning(true);
    FetchWithToken(BASE_URL + '/chatbot/bot-sources', 'POST', data, true)
      .then((data) => {
        setIsScanning(false);
        if (data.message) {
          showToast(
            'Scan In Progress',
            'Scanning might take a couple of minutes depending on the amount of content.',
            'info'
          );
          // setStatus("Processing")
          fetchSources();
          fetchSourceCategory();
        } else if (data.error) {
          showToast('Something went Wrong', data.error, 'error');
        }
      })
      .catch((err) => {
        setIsScanning(false);
        showToast('Something went Wrong', err, 'error');
        console.log(err);
      });
  };

  const handleTrainChatbot = () => {
    setIsTraining(true);
    const data = {
      // source_id: source.source_id,
      bot_id: botId,
    };
    FetchWithToken(BASE_URL + `/cr/train-data`, 'POST', data)
      .then((data) => {
        setIsTraining(false);
        if (data.message) {
          showToast(
            'Training In Progress',
            'We’re training your chatbot with the content provided . Training might take a couple of minutes. ',
            'info'
          );
          // setStatus("Processing")
          fetchSources();
          fetchSourceCategory();
        } else if (data.error) {
          showToast('Something went Wrong', data.error, 'error');
        }
      })
      .catch((err) => {
        setIsTraining(false);
        showToast('Something went Wrong', err, 'error');
        console.log(err);
      });
    fetchSources();
  };

  // Handle Input change for  different input field for URL
  const handleUrlSwitchChange = (e) => {
    const value = e.target.checked;
    setIsSinglePage(value);
  };
  const handleInputUrlChange = (e) => {
    const value = e.target.value;
    setInputUrl(value);
  };

  const deleteAllSources = () => {
    FetchWithToken(
      BASE_URL +
        '/chatbot/delete_bot_sources?bot_id=' +
        botId +
        '&user_id=' +
        user_id,
      'DELETE'
    ).then((data) => {
      setSources([]);
      toast({
        title: data.message,
        duration: 4000,
        isClosable: true,
      });
    });
  };

  return (
    <>
      <Modal size={'5xl'} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Data Sources</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0} background={'#F4F4F4'} overflow={'hidden'}>
            <Box p={6}>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={2}
                mb={3}
              >
                <Flex alignItems={'center'} gap={3} width={'full'}>
                  <Text fontWeight={500} fontSize={'15px'}>
                    Website Link:
                  </Text>
                  <InputGroup flex={1}>
                    <Input
                      borderRadius={'full'}
                      value={inputUrl}
                      onChange={handleInputUrlChange}
                      variant='outline'
                      //placeholder="https://www.example.com"
                    />
                    <InputRightElement>
                      <Tooltip
                        label='This will crawl all the links starting with the URL (not including files on the website).'
                        fontSize='md'
                        placement='top'
                      >
                        <span>
                          <MdInfo size={20} color='gray' />
                        </span>
                      </Tooltip>
                    </InputRightElement>
                  </InputGroup>
                </Flex>

                <Box>
                  <Button
                    isLoading={status === 'Scanning' || isScanning}
                    isDisabled={
                      status === 'Training' ||
                      status === 'Processing' ||
                      !inputUrl ||
                      inputUrl === ''
                    }
                    variant={'outline'}
                    loadingText='Scanning'
                    spinner={<CustomLoader color={'blue'} />}
                    colorScheme={status === 'Scanning' ? 'facebook' : 'orange'}
                    borderRadius={'full'}
                    width={'189px'}
                    height={'44px'}
                    px={'7px'}
                    onClick={handleScanClick}
                    _hover={
                      !inputUrl || inputUrl === ''
                        ? {
                            backgroundColor: 'rgba(32, 32, 32, 0.25)',
                            color: 'white',
                          }
                        : {
                            backgroundColor: '#002298',
                            color: 'white',
                          }
                    }
                  >
                    Scan
                  </Button>
                </Box>
              </Flex>
              {/* singlepage */}
              <Flex justifyContent={'end'}>
                <Flex
                  my={1}
                  p={3}
                  justifyContent={'space-between'}
                  bgColor={'white'}
                  borderRadius={'10px'}
                  width={'20%'}
                >
                  <Flex alignItems={'center'}>
                    <div
                      style={{
                        fontSize: '12px',
                        marginRight: '4px',
                      }}
                      htmlFor='singlePage'
                      mb={0}
                    >
                      Scan Single URL
                    </div>
                    <Tooltip
                      label='Default Scheduled refresh will be Never.'
                      fontSize='md'
                      placement='top'
                    >
                      <span>
                        <MdInfoOutline size={18} />
                        {/* <MdInfo size={20} color="gray" /> */}
                      </span>
                    </Tooltip>
                    {/* <MdInfoOutline size={18} /> */}
                  </Flex>
                  <Switch
                    id='singlePage'
                    value={isSinglePage}
                    checked={isSinglePage}
                    onChange={(e) => handleUrlSwitchChange(e)}
                  />
                </Flex>
              </Flex>

              {/* progress box */}
              {(status === 'Scanning' ||
                status === 'Scanned' ||
                status === 'Training' ||
                status === 'Ready') &&
                sources.length > 0 && <ScanningProgressBar urlList={sources} />}
              {/* <ScanningProgressBar urlList={urlList} /> */}
            </Box>
            <Box p={6} backgroundColor={'white'}>
              <Flex
                width={'full'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Text fontWeight={500} fontSize={'18px'}>
                  All Included Sources
                </Text>
                <Flex alignItems={'center'}>
                  <Text fontWeight={700} fontSize={'18px'}>
                    {details?.source_pages}
                  </Text>
                  <Text
                    fontSize={'18px'}
                  >{`/${Package?.SOURCE_PAGES?.count}`}</Text>
                  <Text ms={1} fontWeight={500} fontSize={'18px'}>
                    {' '}
                    {/* Increased font size */}
                    Source Limit
                  </Text>
                </Flex>
                <Button
                  size={'sm'}
                  colorScheme='red'
                  borderRadius={'full'}
                  variant={'outline'}
                  _hover={{
                    background: 'red',
                    color: 'white',
                  }}
                  onClick={deleteAllSources}
                  disabled={sources?.length === 0}
                >
                  Delete all Sources
                </Button>

                <Box width={'30%'}>
                  <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                      <SearchIcon color='gray.300' />
                    </InputLeftElement>
                    <Input
                      borderRadius={'5px'}
                      size='sm'
                      type='text'
                      placeholder='Search sources...'
                    />
                  </InputGroup>
                </Box>
              </Flex>

              {/* Sources data Table */}
              <UrlsTable
                fetchSources={() => fetchSources()}
                fetchSourceCategory={fetchSourceCategory}
                sourceId
                botId={botId}
                status={status}
                sources={sources}
                showToast={showToast}
                handleCallback={handleCallback}
              />
            </Box>
            {/* <Lorem count={2} /> */}
          </ModalBody>

          <ModalFooter justifyContent={'center'}>
            <Button
              borderRadius={'full'}
              variant='outline'
              colorScheme='gray'
              mr={3}
              //   onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              isDisabled={status !== 'Ready'}
              isLoading={isTraining || status === 'Training'}
              loadingText={
                isAlreadyTrained ? 'Retraining Chatbot' : 'Training Chatbot'
              }
              borderRadius={'full'}
              spinner={<CustomLoader color={'#FF8321'} />}
              variant='solid'
              colorScheme='blue'
              sx={
                (isTraining || status === 'Training') && {
                  border: '1px solid',
                  borderColor: '#FF8321',
                  backgroundColor: '#002298',
                  color: '#FF8321',
                }
              }
              _hover={{
                border: '1px solid',
                borderColor: '#002298',
                backgroundColor: 'rgba(255, 131, 33, 0.08)',
                color: '#002298',
              }}
              onClick={handleTrainChatbot}
              disabled={sources?.length === 0}
            >
              {isAlreadyTrained ? 'Retrain Chatbot' : 'Train Chatbot'}
              {/* Train Chatbot */}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(SourcesCrawlerModal);
