import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  List,
  ListItem,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  Icon,
  Select,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { FaCircle, FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import FetchWithToken from '../../utils/FetchWithToken';

function BotDropdown({ platform, integrationDetails, checkConnectionStatus }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const userData = JSON.parse(localStorage.getItem('docsbot-users'));
  const location = useLocation();
  const toast = useToast();
  const [selectedTeam] = useState(() => {
    return JSON.parse(localStorage.getItem('docsbot-users'))?.selectedTeam;
  });
  const [selectedBotId, setSelectedBotId] = useState('');
  const [allBotsData, setAllBotsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState({});
  const [botToDisconnect, setBotToDisconnect] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const handleDisconnect = (connection_id) => {
    // FetchWithToken(
    //   BASE_URL +
    //     `/integrations/disconnect?connection_id=${connection_id}&platform=${platform}`,
    //   'DELETE'
    // ).then((data) => {
    //   console.log(data);
    // });

    FetchWithToken(
      BASE_URL +
        `/integrations/disconnect?connection_id=${connection_id}&platform=${platform}`,
      'DELETE'
    ).then((data) => {
      toast({
        title: data.success,
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      checkConnectionStatus();
      fetchData();
    });
  };

  const confirmDisconnect = async () => {
    if (!botToDisconnect) {
      setError('Bot to disconnect is not specified.');
      return;
    }

    // Find the connection_id for the bot in lineIntegrationDetails
    const botDetail = integrationDetails.find(
      (detail) => detail.bot_id === botToDisconnect.bot_id
    );

    if (!botDetail || !botDetail.connection_id) {
      setError('Connection ID for the bot not found.');
      return;
    }

    toggleConnection(botToDisconnect.bot_id);

    try {
      const response = await FetchWithToken(
        `${BASE_URL}/integrations/disconnect?connection_id=${botDetail.connection_id}&platform=${platform}`,
        'DELETE'
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error disconnecting bot:', errorData);
        setError(errorData.message || 'Failed to disconnect bot.');
        return;
      }

      console.log('Bot disconnected successfully');
    } catch (error) {
      console.error('Error disconnecting bot:', error);
      setError('Failed to disconnect bot.');
    }

    onConfirmClose();
  };

  const cancelDisconnect = () => {
    setBotToDisconnect(null);
    onConfirmClose();
  };

  const fetchData = () => {
    const team_id = selectedTeam?.team_id;
    if (!team_id) {
      return;
    }
    setIsLoading(true);
    FetchWithToken(`${BASE_URL}/chatbots?team_id=${team_id}`, 'GET')
      .then((data) => {
        setIsLoading(false);
        setAllBotsData(data.bots || []);
        // const initialStatus = {};
        // data.bots.forEach((bot) => {
        //   initialStatus[bot.bot_id] = true;
        // });
        // setConnectionStatus(initialStatus);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching bot data:', error);
        setError('Error fetching bots');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const connectBot = (bot_id, connection_id) => {
    FetchWithToken(`${BASE_URL}/bot-integration`, 'POST', {
      platform,
      bot_id,
      connection_id,
      user_id: userData.user_id,
    }).then((data) => {
      fetchData();
      checkConnectionStatus();
      toast({
        title: data.success,
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      console.log(data);
    });

    // const data = await response.json();

    // console.log(data);
  };

  const toggleConnection = (botId) => {
    const newStatus = !connectionStatus[botId];
    setConnectionStatus((prevStatus) => ({
      ...prevStatus,
      [botId]: newStatus,
    }));

    if (newStatus) {
      connectBot(botId);
    } else {
      setSelectedBotId('');
    }
  };

  return (
    <Box>
      <Button
        bg='gray.100'
        h='34px'
        w='100%'
        onClick={onOpen}
        _hover={{ bg: 'gray.200' }}
        border='1px solid rgba(33, 33, 33, 0.21)'
      >
        <Flex justify='center' align='center' w='100%'>
          Manage Connections
        </Flex>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
        <ModalOverlay />
        <ModalContent borderRadius='lg'>
          <ModalHeader>Manage Connections</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <Flex justify='center' p='8px'>
                <Spinner />
              </Flex>
            ) : error ? (
              <Box p='8px' color='red.500'>
                {error}
              </Box>
            ) : (
              <List spacing={4}>
                {integrationDetails && integrationDetails.length > 0 ? (
                  integrationDetails?.map((channelDetail) => (
                    <Box key={channelDetail.channel_id}>
                      <ListItem
                        p='8px'
                        borderRadius='xl'
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Flex align='center' flex='1' justify='space-between'>
                          <Text
                            fontSize='14px'
                            fontWeight='normal'
                            mr='8px'
                            color={'#333'}
                          >
                            {platform === 'Line'
                              ? channelDetail.line_bot_name
                              : channelDetail.page_name}{' '}
                          </Text>

                          <Flex align='center' mr='24px'>
                            {/* {channelDetail.bot_id ? (
                              <Text
                                bg="gray.100"
                                p="1"
                                borderRadius="3xl"
                                display="flex"
                                alignItems="center"
                                width="180px"
                                fontSize="sm"
                                justifyContent="space-between"
                              >
                                <Icon
                                  as={FaCircle}
                                  color="green.500"
                                  boxSize={3}
                                  mr="8px"
                                />

                                {allBotsData.find(
                                  (bot) => bot.bot_id === channelDetail.bot_id
                                )?.bot_name || channelDetail.line_bot_name}
                                <Icon
                                  as={FaTimes}
                                  color="green.500"
                                  boxSize={3}
                                  mr="8px"
                                />
                              </Text>
                            ) : ( */}
                            <Select
                              onChange={(e) =>
                                connectBot(
                                  e.target.value,
                                  channelDetail.connection_id
                                )
                              }
                              value={channelDetail.bot_id}
                              width='180px'
                              mr='8px'
                              fontSize='sm'
                              defaultValue='select'
                            >
                              <option value='select' disabled>
                                Select a bot
                              </option>
                              {allBotsData
                                // .filter(
                                //   (bot) => bot.team_id === selectedTeam?.team_id
                                // ) // Filter bots by selected team
                                .map((botOption) => (
                                  <option
                                    key={botOption.bot_id}
                                    value={botOption.bot_id}
                                    disabled={botOption.activity === 'true'}
                                  >
                                    {botOption.bot_name}
                                  </option>
                                ))}
                            </Select>
                            {/* )} */}
                          </Flex>
                        </Flex>
                        <Flex>
                          {/* <Button
                            size="xs"
                            variant="outline"
                            colorScheme="green"
                            // onClick={() =>
                            //   connectBot(
                            //     botDetail.bot_id,
                            //     botDetail.connection_id
                            //   )
                            // }
                            mr="2"
                          >
                            Connect
                          </Button> */}

                          <Button
                            size='xs'
                            variant='outline'
                            colorScheme='red'
                            onClick={() =>
                              handleDisconnect(channelDetail.connection_id)
                            }
                            ml='2'
                          >
                            Disconnect
                          </Button>

                          {/* {!connectionStatus[botDetail.bot_id] &&
                            selectedBotId === botDetail.bot_id && (
                              <Button
                                size="xs"
                                variant="outline"
                                colorScheme="green"
                                onClick={() =>
                                  toggleConnection(botDetail.bot_id)
                                }
                                mr="2"
                              >
                                Connect
                              </Button>
                            )}
                          {connectionStatus[botDetail.bot_id] && (
                            <Button
                              size="xs"
                              variant="outline"
                              colorScheme="red"
                              onClick={() => handleDisconnect(botDetail)}
                              ml="2"
                            >
                              Disconnect
                            </Button>
                          )} */}
                        </Flex>
                      </ListItem>
                      <Divider orientation='horizontal' />
                    </Box>
                  ))
                ) : (
                  <Box p='8px' textAlign='center' color='gray.500'>
                    No bots available.
                  </Box>
                )}
              </List>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isConfirmOpen} onClose={onConfirmClose} size='sm'>
        <ModalOverlay />
        <ModalContent borderRadius='lg'>
          <ModalHeader textAlign='center'>
            @{botToDisconnect?.username}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign='center'>
            <Text>
              Are you sure you want to disconnect{' '}
              {botToDisconnect?.line_bot_name}?
            </Text>
          </ModalBody>
          <ModalFooter justifyContent='center'>
            <Button colorScheme='green' mr={3} onClick={cancelDisconnect}>
              Keep connected
            </Button>
            <Button variant='outline' onClick={confirmDisconnect}>
              Disconnect
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default BotDropdown;
