import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import FetchWithToken from '../../utils/FetchWithToken';
import { Spinner } from '@chakra-ui/react';
import { t } from 'i18next';

const ShowConfirmationModal = ({ isOpen, onClose }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const toast = useToast();

  const [deleting, setDeleting] = useState(false);

  const selectedTeam = JSON.parse(
    localStorage.getItem('docsbot-users')
  )?.selectedTeam;

  const comfirmDeletion = async () => {
    const body = {
      team_id: selectedTeam.team_id,
      user_role: selectedTeam.user_role,
    };
    setDeleting(true);
    FetchWithToken(BASE_URL + '/account', 'DELETE', body).then((data) => {
      setDeleting(false);
      onClose();
      toast({
        title: data.message,
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    });
  };
  // const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        motionPreset='slideInBottom'
        isCentered
      >
        <ModalOverlay />
        <ModalContent pt={4}>
          {/* <ModalHeader><WarningTwoIcon w={7} h={7} color="red.500" /> Delete team account</ModalHeader> */}
          {/* <ModalCloseButton /> */}
          <ModalBody display={'flex'} ms={'10px'}>
            <WarningTwoIcon w={7} h={7} color='red.500' />
            <Box mx={3}>
              <Text fontSize='lg' as='b'>
                {t('Accounts.confirmationModel.Delete team account')}
              </Text>
              <Text fontSize='sm'>
                {t(
                  'Accounts.confirmationModel.Are you sure want to delete your team account? All of your data will be permanently removed from our servers forever. This action cannot be undone.'
                )}
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' variant='outline' onClick={onClose}>
              {/* Cancel */}
              {t('Accounts.confirmationModel.cancel')}
            </Button>
            <Button
              colorScheme='red'
              mx={3}
              minWidth='100px'
              onClick={comfirmDeletion}
            >
              {deleting ? <Spinner /> : t('Accounts.confirmationModel.confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ShowConfirmationModal;
