import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  IconButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Avatar,
  Select,
} from '@chakra-ui/react';

import {
  useNavigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { TbLogout, TbPackages, TbLanguage } from 'react-icons/tb';
import { ChevronRightIcon } from '@chakra-ui/icons';
import glt from '../assets/GL_logo.png';
// import gltIcon from "../assets/icon.png";
import { ImMenu } from 'react-icons/im';

import { ReactComponent as PromptIcon } from '../assets/dashboard/Vector.svg';
import { ReactComponent as DashboardIcon } from '../assets/dashboard/dashboard.svg';
import { ReactComponent as DashboardIconActive } from '../assets/dashboard/category2.svg';

import { ReactComponent as ChatBotIcon } from '../assets/dashboard/Chatbot.svg';
import { ReactComponent as ChatBotIconActive } from '../assets/dashboard/layer.svg';

import { ReactComponent as IntegrationIcon } from '../assets/dashboard/integration1.svg';
import { ReactComponent as IntegrationIconActive } from '../assets/dashboard/integration.svg';

import { ReactComponent as TeamIcon } from '../assets/dashboard/profile2user.svg';

import { ReactComponent as AccountIcon } from '../assets/dashboard/account.svg';
import { ReactComponent as AccountIconActive } from '../assets/dashboard/card.svg';

import FetchWithToken from '../utils/FetchWithToken';
import { FaUsersCog } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

const AdminDashboard = () => {
  //   const { lang } = useParams();
  let lang = navigator.language;
  const { t, i18n } = useTranslation();
  //api to get subscription data
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  //states

  const { isOpen, onToggle } = useDisclosure();
  const [selected, setSelected] = useState('dashboard');
  const [userSubscriptionData, setUserSubscriptionData] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const email = JSON.parse(localStorage.getItem('docsbot-users'))?.email;
  const superadmin = JSON.parse(
    localStorage.getItem('docsbot-users')
  )?.superadmin;

  const [urlParams, seturlParams] = useState(
    queryString.parse(window.location.search)
  );

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    // localStorage.setItem("language", e.target.value);

    const urlParams = queryString.parse(window.location.search);
    urlParams.lang = e.target.value;
    const newUrl = `${window.location.pathname}?${queryString.stringify(
      urlParams
    )}`;
    window.history.replaceState(null, '', newUrl);
  };

  function fetchUserSubscription() {
    const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;
    // setIsLoading(true);
    FetchWithToken(
      BASE_URL + `/user_subscriptions?user_id=${user_id}`,
      'GET'
    ).then((data) => {
      // setIsLoading(false);
      setUserSubscriptionData(data.subscription);
      var existingDataString = localStorage.getItem('docsbot-users');
      var existingData = JSON.parse(existingDataString);
      existingData.subscription = data.subscription;
      var updatedDataString = JSON.stringify(existingData);
      localStorage.setItem('docsbot-users', updatedDataString);
    });
  }

  useEffect(() => {
    // Get lang from searchParams
    const searchLang = searchParams.get('lang');
    const currentLang = searchLang || lang;
    if (currentLang) {
      i18n.changeLanguage(currentLang);
    }
  }, [searchParams, lang, i18n]);

  useEffect(() => {
    fetchUserSubscription();
  }, []);

  const handleItemClick = (item) => {
    setSelected(item);
    if (urlParams.lang) {
      navigate(`${item}?lang=${urlParams.lang}`);
    } else {
      navigate(`${item}`);
    }
    onToggle();
  };

  const getBreadcrumbs = () => {
    const pathname = location.pathname;
    const segments = pathname.split('/').filter((segment) => segment !== '');

    // Map the segments to breadcrumbs
    const breadcrumbs = segments.map((segment, index) => {
      const decodedName = decodeURIComponent(segment);
      const path = `/${segments.slice(0, index + 1).join('/')}`;

      return {
        name: decodedName,
        path: path,
      };
    });

    return breadcrumbs.slice(1); // Exclude the first breadcrumb ("admin-dashboard")
  };

  useEffect(() => {
    const pathname = location.pathname;
    const segments = pathname.split('/');
    let endpoint = segments[2];
    if (endpoint === '') {
      endpoint = 'dashboard';
    }
    setSelected(endpoint);
  }, [location]);

  return (
    <Flex h='100vh'>
      <Flex
        as='nav'
        bg='var(--Web-color-white)'
        color='#6e6e6e'
        h='100vh'
        w='220px'
        minW='200px'
        borderRightWidth='1px'
        borderRightColor='gray.200'
        flexShrink={0}
        flexDirection='column'
        position='fixed'
        display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }}
        zIndex='1'
      >
        <Flex
          justify='space-between'
          align='center'
          px='4'
          py='2'
          borderBottomWidth='1px'
          borderBottomColor='gray.200'
          p={'10px 20px'}
        >
          {/* <Text display="flex" alignItems="center" fontSize="22px" fontWeight="bold" >
                        Bambu.bot AI
                    </Text> */}
          {/* <Image src={logoDark} alt="logodark" h={"36px"} /> */}
          {/*<Image src={gltIcon} alt="gltIcon" h={"35px"} />*/}
          <Image src={glt} alt='logodark' h={'35px'} mx={'auto'} />
        </Flex>

        <VStack mt='6' spacing='2' mb={'4'} align='stretch' flexGrow={1}>
          {/* <Box
            as="button"
            px="2"
            py="1"
            display="flex"
            alignItems="center"
            borderRadius="5px"
            // color={selected === 'dashboard' ? 'var(--Web-color-orange)' : ''}
            textAlign="left"
            fontWeight={"500"}
            fontSize={"15px"}
            p={"6px 15px"}
            // onClick={() => handleItemClick('dashboard')}
          >
            Change Language
            <Select placeholder="Select option" onChange={handleLanguageChange}>
              <option value="en">English(en)</option>
              <option value="es">Spanish(es)</option>
            </Select>
          </Box> */}
          <Box
            as='button'
            px='2'
            py='1'
            display='flex'
            alignItems='center'
            borderRadius='5px'
            // color={selected === "dashboard" ? "var(--Web-color-orange)" : ""}
            color={selected === 'dashboard' ? '#091D62' : ''}
            textAlign='left'
            fontWeight={'500'}
            fontSize={'15px'}
            p={'6px 15px'}
            onClick={() => handleItemClick('dashboard')}
          >
            {selected === 'dashboard' ? (
              <DashboardIconActive style={{ marginRight: '10px' }} />
            ) : (
              <DashboardIcon style={{ marginRight: '10px' }} />
            )}
            {t('AdminDashboard.dashboard')}
            {/* Dashboard */}
          </Box>
          <Box
            as='button'
            px='2'
            py='1'
            display='flex'
            alignItems='center'
            borderRadius='5px'
            fontWeight={'500'}
            p={'6px 15px'}
            // color={selected === "chatbots" ? "var(--Web-color-orange)" : ""}
            color={selected === 'chatbots' ? '#091D62' : ''}
            textAlign='left'
            fontSize={'15px'}
            onClick={() => handleItemClick('chatbots')}
          >
            {selected === 'chatbots' ? (
              <ChatBotIconActive style={{ marginRight: '10px' }} />
            ) : (
              <ChatBotIcon style={{ marginRight: '10px' }} />
            )}
            {t('AdminDashboard.chatbots')}
          </Box>
          <Box
            as='button'
            px='2'
            py='1'
            display='flex'
            alignItems='center'
            borderRadius='5px'
            fontWeight={'500'}
            p={'6px 15px'}
            // color={selected === "integrations" ? "var(--Web-color-orange)" : ""}
            color={selected === 'integrations' ? '#091D62' : ''}
            textAlign='left'
            fontSize={'15px'}
            onClick={() => handleItemClick('integrations')}
          >
            {selected === 'integrations' ? (
              <IntegrationIconActive style={{ marginRight: '10px' }} />
            ) : (
              <IntegrationIcon style={{ marginRight: '10px' }} />
            )}
            {t('AdminDashboard.integrations')}
          </Box>
          <Box
            as='button'
            px='2'
            py='1'
            display='flex'
            alignItems='center'
            borderRadius='5px'
            fontWeight={'500'}
            p={'6px 15px'}
            // color={selected === "team" ? "var(--Web-color-orange)" : ""}
            color={selected === 'team' ? '#091D62' : ''}
            textAlign='left'
            fontSize={'15px'}
            onClick={() => handleItemClick('team')}
          >
            <TeamIcon
              style={{ marginRight: '10px' }}
              stroke={selected === 'team' ? 'var(--Web-color-orange)' : 'none'}
            />
            {t('AdminDashboard.teams')}
          </Box>
          <Box
            as='button'
            px='2'
            py='1'
            display='flex'
            alignItems='center'
            borderRadius='5px'
            fontWeight={'500'}
            p={'6px 15px'}
            // color={selected === "account" ? "var(--Web-color-orange)" : ""}
            color={selected === 'account' ? '#091D62' : ''}
            textAlign='left'
            fontSize={'15px'}
            onClick={() => handleItemClick('account')}
          >
            {selected === 'account' ? (
              <AccountIconActive style={{ marginRight: '10px' }} />
            ) : (
              <AccountIcon style={{ marginRight: '10px' }} />
            )}
            {t('AdminDashboard.account')}
          </Box>

          {/* <Box
            as="button"
            px="2"
            py="1"
            display="flex"
            alignItems="center"
            borderRadius="5px"
            color={selected === "api" ? "#091D62" : ""}
            textAlign="left"
            fontSize={"15px"}
            fontWeight={"500"}
            p={"6px 15px"}
            onClick={() => handleItemClick("api")}
          >
            {selected === "api" ? (
              <ApiIconActive style={{ marginRight: "10px" }} />
            ) : (
              <ApiIcon style={{ marginRight: "10px" }} />
            )}
            {t("AdminDashboard.Api")}
          </Box> */}
          {superadmin && (
            <>
              <Box
                as='button'
                px='2'
                py='1'
                display='flex'
                alignItems='center'
                borderRadius='5px'
                // color={selected === "package" ? "var(--Web-color-orange)" : ""}
                color={selected === 'package' ? '#091D62' : ''}
                textAlign='left'
                fontSize={'15px'}
                fontWeight={'500'}
                p={'6px 15px'}
                onClick={() => handleItemClick('package')}
              >
                <TbPackages size={'20px'} style={{ marginRight: '10px' }} />
                {t('AdminDashboard.package_management')}
              </Box>
              <Box
                as='button'
                px='2'
                py='1'
                display='flex'
                alignItems='center'
                borderRadius='5px'
                // color={
                //   selected === "user-management"
                //     ? "var(--Web-color-orange)"
                //     : ""
                // }
                color={selected === 'user-management' ? '#091D62' : ''}
                textAlign='left'
                fontSize={'15px'}
                fontWeight={'500'}
                p={'6px 15px'}
                onClick={() => handleItemClick('user-management')}
              >
                <FaUsersCog size={'20px'} style={{ marginRight: '10px' }} />
                {t('AdminDashboard.user_management')}
              </Box>
            </>
          )}
          <Box
            as='button'
            px='2'
            py='1'
            display='flex'
            alignItems='center'
            borderRadius='5px'
            color={selected === 'prompt' ? '#091D62' : ''}
            textAlign='left'
            fontSize={'15px'}
            fontWeight={'500'}
            p={'6px 15px'}
            onClick={() => handleItemClick('prompt')}
          >
            {/* Replace the icon with an image */}
            <PromptIcon size={'10px'} style={{ marginRight: '10px' }} />
            {t('AdminDashboard.prompt')}
          </Box>
          {/* <Spacer />
                    <Box
                        as="button"
                        px="2"
                        py="1"
                        display="flex"
                        alignItems="center"
                        borderRadius="5px"
                        justifyContent={'space-between'}
                        color={'var(--Web-color-black)'}
                        textAlign="left"
                        fontSize={'15px'}
                        fontWeight={'500'}
                        p={'6px 15px'}
                        onClick={AdminDashboard() => { localStorage.removeItem('docsbot-users'); navigate('/') }}
                    >
                        <Text>Logout</Text>
                        <FiLogOut size={20} style={{ marginRight: '7px' }} />
                    </Box> */}
        </VStack>
      </Flex>

      <Flex
        flexDirection='column'
        flex='1'
        marginLeft={{ base: '0', md: '220px' }}
      >
        <Flex
          as='nav'
          bg='white'
          p={2}
          px='6'
          borderBottomWidth='1px'
          borderBottomColor='gray.200'
          justify='space-between'
          align='center'
        >
          <Flex>
            <Box display='flex' flexDirection='row'>
              {getBreadcrumbs().map((breadcrumb, index) => (
                <Text
                  key={index}
                  fontSize={{ base: 'm', md: '2xl' }}
                  fontWeight='bold'
                  cursor={index === 0 ? 'pointer' : 'default'}
                  mr={2}
                  onClick={() => {
                    if (index === 0) {
                      // Navigate to the first breadcrumb's path
                      if (urlParams.lang) {
                        navigate(breadcrumb.path + `?lang=${urlParams.lang}`);
                      } else {
                        navigate(breadcrumb.path);
                      }
                    }
                  }}
                >
                  {index > 0 && <ChevronRightIcon />}
                  {breadcrumb.name.charAt(0).toUpperCase() +
                    breadcrumb.name.slice(1)}
                </Text>
              ))}
            </Box>
          </Flex>

          <Flex>
            <Menu>
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <Text
                  color='#6e6e6e'
                  fontWeight={'500'}
                  fontSize={'12px'}
                  display={{ base: 'none', md: 'block' }}
                >
                  {email}
                </Text>
                <MenuButton
                  borderRadius='full'
                  as={IconButton}
                  aria-label='Options'
                  variant='ghost'
                  icon={
                    <Avatar
                      size={'sm'}
                      name={email}
                      src='https://bit.ly/tioluwani-kolawole'
                    />
                  }
                />
              </Box>

              <MenuList>
                <MenuItem
                  icon={<TeamIcon />}
                  onClick={() => {
                    if (urlParams.lang) {
                      navigate(`/admin-dashboard/team?lang=${urlParams.lang}`);
                    } else {
                      navigate(`/admin-dashboard/team`);
                    }
                  }}
                >
                  {t('AdminDashboard.team')}
                  {/* Team */}
                </MenuItem>
                {/* <MenuItem> */}
                <Box display={'flex'} alignItems={'center'} ps={3}>
                  <TbLanguage color='grey' size={20} />
                  <Text ms={3}>{t('AdminDashboard.language')}</Text>

                  {/* Language */}
                  <Select
                    ms={3}
                    width={'50%'}
                    //placeholder="--Languages--"
                    onChange={handleLanguageChange}
                  >
                    <option value='en'>
                      {t('AdminDashboard.english')}(en)
                    </option>
                    {/* <option value="es">
                      {t('AdminDashboard.spanish')}(es)
                    </option> */}
                    {/* <option value='hi'>Hindi(hi)</option> */}
                    <option value='th'>{t('AdminDashboard.thai')}(th)</option>
                  </Select>
                </Box>
                {/* </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    localStorage.removeItem('docsbot-users');
                    if (urlParams.lang) {
                      navigate(`/?lang=${urlParams.lang}`);
                    } else {
                      navigate(`/`);
                    }
                  }}
                  icon={<TbLogout color='grey' size={20} />}
                >
                  {t('AdminDashboard.logout')}
                  {/* Logout */}
                </MenuItem>
              </MenuList>
            </Menu>
            <IconButton
              aria-label='menu'
              icon={<ImMenu />}
              size='sm'
              onClick={onToggle}
              display={{ base: 'flex', md: 'none' }}
              right='-10px'
              colorScheme='white'
              variant='outline'
            />
          </Flex>
        </Flex>
        <Box
          flex='1'
          p={{ base: '3', md: '7' }}
          bg={'var(--Web-color-lightgrey)'}
        >
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};

export default AdminDashboard;
