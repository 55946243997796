import React from 'react';
import './CustomLoader.css';
import { Box } from '@chakra-ui/react';

const CustomLoader = ({ color }) => {
  // const LoaderStyle ={
  //     borderColor:`${color} #0000 ${color} #0000`,
  // }

  return (
    <>
      {/* <span style={LoaderStyle} className="loader"></span> */}

      <Box
        className='loader'
        borderColor={`${color} #0000 ${color} #0000`}
        _after={{
          borderBottomColor: `${color}`,
          borderColor: `${color} #0000 #0000 #0000`,
        }}
        _before={{
          borderBottomColor: `${color}`,
        }}
      ></Box>
    </>
  );
};

export default CustomLoader;
