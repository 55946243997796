import { Box } from '@chakra-ui/react';
import { Users } from './Users';

// import { useToast } from "@chakra-ui/react"

const Analytics = () => {
  //   const handleCardCLick = () => {
  //     setShowUsers((prev) => !prev);
  //   };

  return (
    <>
      <Box>
        <Users />
      </Box>
    </>
  );
};

export default Analytics;
