import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { BiCalendar, BiInfoCircle } from 'react-icons/bi';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Area,
  AreaChart,
  Tooltip,
} from 'recharts';
import FetchWithToken from '../../../utils/FetchWithToken';

//Created a tooltip for the chart data info. ChakraUi Tooltip is unable to render inside the Legend component
const CustomTooltip = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const tooltipStyles = {
    visibility: showTooltip ? 'visible' : 'hidden',
    backgroundColor: 'black',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '4px 4px 4px 3px',
    padding: '4px 8px',
    position: 'absolute',
    zIndex: 1,
    bottom: 'calc(100% + 5px)', // Adjust the vertical offset between the icon and tooltip
    left: '100px',
    transform: 'translateX(-50%)',
    transition: 'opacity 0.3s',
    width: '200px',
  };

  const tooltipArrowStyles = {
    visibility: showTooltip ? 'visible' : 'hidden',
    position: 'absolute',
    width: '0',
    height: '0',
    borderTop: '6px solid black',
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    bottom: '100%',
    left: '50%',
    transition: 'opacity 0.3s',
    transform: 'translateX(-50%)',
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <BiInfoCircle
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: 'pointer' }}
      />
      <div style={tooltipStyles}>{text}</div>
      <div style={tooltipArrowStyles} />
    </div>
  );
};

const CustomLegend = ({
  payload,
  avg_daily_chat,
  avg_daily_ans,
  avg_daily_user_session,
}) => {
  const descriptions = {
    avg_daily_chat: 'AVG Daily Chats',
    avg_daily_answer: 'AVG Daily Answers',
    user_session: 'AVG Number of User Sessions',
  };

  return (
    <Flex p={1} alignItems={'center'} justifyContent={'space-between'}>
      <Flex pl={4} pb={6} gap={3}>
        <Box>
          <Text fontSize={16} fontWeight={700}>
            {isNaN(avg_daily_chat) ? 0 : Number(avg_daily_chat)?.toFixed(3)}
          </Text>
          <Text
            fontSize={12}
            fontWeight={500}
            display={'flex'}
            alignItems={'center'}
            gap={1}
          >
            AVG Daily Chats
            <CustomTooltip text='The average number of chats initiated in a given period of time' />
          </Text>
        </Box>
        <Box>
          <Text fontSize={16} fontWeight={700}>
            {isNaN(avg_daily_ans) ? 0 : Number(avg_daily_ans)?.toFixed(3)}
          </Text>
          <Text
            fontSize={12}
            fontWeight={500}
            display={'flex'}
            alignItems={'center'}
            gap={1}
          >
            AVG Daily Answers
            <CustomTooltip text='The average number of answers per chat in a given period of time' />
          </Text>
        </Box>
        <Box>
          <Text fontSize={16} fontWeight={700}>
            {isNaN(avg_daily_user_session)
              ? 0
              : Number(avg_daily_user_session)?.toFixed(3)}
          </Text>
          <Text
            fontSize={12}
            fontWeight={500}
            display={'flex'}
            alignItems={'center'}
            gap={1}
          >
            AVG Number of User Sessions
            <CustomTooltip text='The average number of user sessions in a given period of time' />
          </Text>
        </Box>
      </Flex>
      <Box display='flex' alignItems='center' justifyContent='center'>
        {payload.map((entry, index) => (
          <Box key={index} display='flex' alignItems='center' mr={4}>
            <Box
              width='20px'
              height='12px'
              backgroundColor={entry.color}
              borderRadius='2px'
              marginRight={1}
            />
            <Text fontSize={13} fontWeight={500}>{`${
              descriptions[entry.dataKey]
            }`}</Text>
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

const ChartModal = ({ isOpen, onClose, botData }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [graphData, setgraphData] = useState([]);
  const [AVGData, setAVGData] = useState({
    average_daily_chat: 0,
    average_daily_answer: 0,
    average_session: 0,
  });
  const [csvExportButton, setCSVExportButton] = useState(true);

  const currentDate = new Date();

  const dateOptions = [
    { label: 'Today', daysAgo: 0 },
    { label: 'Yesterday', daysAgo: 0 },
    { label: 'Last 7 days', daysAgo: 7 },
    { label: 'Last 30 days', daysAgo: 30 },
    { label: 'This Month', daysAgo: currentDate.getDate() - 1 },
    {
      label: 'Last Month',
      daysAgo: 0 /* you need to implement logic for last month */,
    },
  ];
  const [selectedDateRange, setSelectedDateRange] = useState(dateOptions[0]);

  const handleDateRangeChange = (dateOption) => {
    setSelectedDateRange(dateOption);
  };
  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const calculateStartDate = () => {
    const startDate = new Date(currentDate);
    if (selectedDateRange.label === 'Yesterday') {
      startDate.setDate(startDate.getDate() - 1);
      startDate.setHours(0, 0, 0, 0); // Start of the day
    } else if (selectedDateRange.label === 'Last Month') {
      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setHours(0, 0, 0, 0); // Start of the day
    } else {
      startDate.setDate(startDate.getDate() - selectedDateRange.daysAgo);
      startDate.setHours(0, 0, 0, 0); // Start of the day
    }
    return formatDateTime(startDate);
  };

  const calculateEndDate = () => {
    const endDate = new Date(currentDate);
    if (selectedDateRange.label === 'Yesterday') {
      endDate.setDate(endDate.getDate() - 1);
      endDate.setHours(23, 59, 59, 999); // End of the day
    } else if (selectedDateRange.label === 'Last Month') {
      endDate.setDate(0); // Last day of the previous month
      endDate.setHours(23, 59, 59, 999);
      // End of the day
    } else if (selectedDateRange.label === 'Today') {
      endDate.setHours(
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds(),
        currentDate.getMilliseconds()
      ); // Time of selection
    } else {
      endDate.setHours(23, 59, 59, 999); // End of the day
    }
    return formatDateTime(endDate);
  };

  const formattedStartDate = calculateStartDate();
  const formattedEndDate = calculateEndDate();

  // const highestValue = graphData?.reduce((maxValue, item) => {
  //   Object.values(item).forEach((value) => {
  //     if (value > maxValue) {
  //       maxValue = value;
  //     }
  //   });
  //   return maxValue;
  // }, 0);

  // const avg_daily_chat = 4781;
  // const avg_daily_ans = 1902;
  // const avg_daily_user_session = 2342;

  const getHoursOrDays = (label) => {
    if (label === 'Today' || label === 'Yesterday') {
      return 'hours';
    } else {
      return 'days';
    }
  };

  //Formatin the the X-Axis tick label based on the selected drop
  const getFormattedDate = (date) => {
    const currentDate = new Date();

    const givenDate = new Date(date);

    const isToday = currentDate.toDateString() === givenDate.toDateString();
    const isYesterday =
      currentDate.toDateString() ===
      new Date(givenDate.setDate(givenDate.getDate() + 1)).toDateString();

    if (isToday) {
      return givenDate.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    } else if (isYesterday) {
      return givenDate.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    } else {
      return givenDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
      });
    }
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fill='#666' fontSize={10} textAnchor='middle'>
          {getFormattedDate(payload.value)}
        </text>
      </g>
    );
  };
  const [interval, setInterval] = useState(0);
  // const getInterval = () => {
  //   return selectedDateRange.label === 'Today' ||
  //     selectedDateRange.label === 'Yesterday' ||
  //     selectedDateRange.label === 'Last 7 days'
  //     ? 0
  //     : 1;
  // };
  useEffect(() => {
    // Function to calculate the interval based on the selectedDateRange
    const calculateInterval = () => {
      if (
        selectedDateRange.label === 'Today' ||
        selectedDateRange.label === 'Yesterday' ||
        selectedDateRange.label === 'Last 7 days'
      ) {
        return 0;
      } else {
        return 1;
      }
    };

    // Calculate the interval and update the state
    const newInterval = calculateInterval();
    setInterval(newInterval);
  }, [selectedDateRange]);

  useEffect(() => {
    const durationCal = getHoursOrDays(selectedDateRange.label);
    FetchWithToken(
      BASE_URL +
        `/chatbot/reports?bot_id=${botData.bot_id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&duration=${durationCal}`,
      'GET'
    ).then((data) => {
      setgraphData(data.data);
      setAVGData(data.average_data);
      setCSVExportButton(false);
    });
  }, [isOpen, selectedDateRange]);

  const exportToCSV = () => {
    const headers = [
      'Date',
      'Avg_daily_answer',
      'Avg_daily_chat',
      'User_session',
    ];
    const rows = [headers.join(',')];

    graphData.forEach((data) => {
      const formattedData = [
        data.date,
        data.avg_daily_answer,
        data.avg_daily_chat,
        data.user_session,
      ];
      rows.push(formattedData.join(','));
    });

    const formattedData = [
      'Average Data',
      AVGData.average_daily_answer,
      AVGData.average_daily_chat,
      AVGData.average_session,
    ];
    rows.push(formattedData.join(','));

    const csvData = rows.join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'graph_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pl={4} fontSize={'24px'} pb={0}>
          Report
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={4}>
          <Flex justifyContent={'space-between'} pb={4}>
            <Text fontSize={15} fontWeight={600}>
              All Conversations
            </Text>
            <Flex gap={2}>
              <Button
                size={'sm'}
                isDisabled={csvExportButton}
                onClick={exportToCSV}
                leftIcon={<BiCalendar />}
                border={'1px solid #408CFD'}
                color={'#408CFD'}
                _hover={{ bg: '#408CFD', color: 'white' }}
                variant='outline'
              >
                Export to CSV
              </Button>
              <Menu>
                <MenuButton
                  as={Button}
                  size={'sm'}
                  leftIcon={<BiCalendar />}
                  rightIcon={<ChevronDownIcon />}
                  border={'1px solid #408CFD'}
                  color={'#408CFD'}
                  _hover={{ bg: '#408CFD', color: 'white' }}
                  variant='outline'
                >
                  {selectedDateRange.label}
                </MenuButton>
                <MenuList>
                  {dateOptions.map((option) => (
                    <MenuItem
                      key={option.label}
                      borderBottom={'1px solid #f0f0f0'}
                      _hover={{
                        bg: 'hsla(28, 100%, 64%, 0.4)',
                        color: '#FF8321',
                      }}
                      onClick={() => handleDateRangeChange(option)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
                {/* <div>
                                    <p>Start Date: {formattedStartDate}</p>
                                    <p>End Date: {formattedEndDate}</p>
                                </div> */}
              </Menu>
            </Flex>
          </Flex>
          <Box borderWidth={1} borderRadius={8} bg={'rgba(217, 217, 217, 0.1)'}>
            <AreaChart width={1100} height={300} data={graphData}>
              <defs>
                <linearGradient id='areaGradient1' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='1%' stopColor='#FF8321' stopOpacity={0.1} />
                  <stop offset='100%' stopColor='#FF8321' stopOpacity={0} />
                </linearGradient>
                <linearGradient id='areaGradient2' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='1%' stopColor='#555555' stopOpacity={0.1} />
                  <stop offset='100%' stopColor='#555555' stopOpacity={0} />
                </linearGradient>
                <linearGradient id='areaGradient3' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='1%' stopColor='#408CFD' stopOpacity={0.1} />
                  <stop offset='100%' stopColor='#408CFD' stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                horizontal={true}
                vertical={false}
                strokeWidth={'1px'}
              />
              <XAxis
                interval={interval}
                tick={<CustomizedAxisTick />}
                tickLine={false}
                axisLine={false}
                dataKey='date'
              />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Legend
                content={
                  <CustomLegend
                    avg_daily_chat={AVGData?.average_daily_chat}
                    avg_daily_ans={AVGData?.average_daily_answer}
                    avg_daily_user_session={AVGData?.average_session}
                  />
                }
                verticalAlign='top'
              />
              <Area
                type='linear'
                dataKey='avg_daily_chat'
                stroke='#FF8321'
                fill='url(#areaGradient1)'
                dot={{ stroke: '#FF8321', strokeWidth: 2, fill: '#fff' }}
              />
              <Area
                type='linear'
                dataKey='avg_daily_answer'
                stroke='#555555'
                fill='url(#areaGradient2)'
                dot={{ stroke: '#555555', strokeWidth: 2, fill: '#fff' }}
              />
              <Area
                type='linear'
                dataKey='user_session'
                stroke='#408CFD'
                fill='url(#areaGradient3)'
                dot={{ stroke: '#408CFD', strokeWidth: 2, fill: '#fff' }}
              />
            </AreaChart>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChartModal;
