import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Text,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import FetchWithToken from '../../utils/FetchWithToken';
import { t } from 'i18next';
import BotDropdown from './MenuItems';

const LineIntegration = ({
  isOpen,
  onClose,
  setQRData,
  checkConnectionStatus,
}) => {
  const userData = JSON.parse(localStorage.getItem('docsbot-users'));

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [formData, setFormData] = useState({
    channel_id: '',
    channel_secret: '',
    channel_access_token: '',
  });

  const [errors, setErrors] = useState({
    channel_id: '',
    channel_secret: '',
    channel_access_token: '',
  });

  // State for AlertDialog
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef();

  const validateForm = () => {
    const newErrors = {};

    if (formData.channel_id === '') {
      newErrors.channel_id = 'Channel ID is required';
    }
    if (formData.channel_secret === '') {
      newErrors.channel_secret = 'Channel Secret is required';
    }
    if (formData.channel_access_token === '') {
      newErrors.channel_access_token = 'Channel Access Token is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      const data = await FetchWithToken(
        `${process.env.REACT_APP_BASE_URL}/integrations/line`,
        'POST',
        { ...formData, user_id: userData.user_id }
      );

      if (data.success) {
        toast({ title: data.success });
        setQRData(data.data);
        onClose();
        setFormData({
          channel_id: '',
          channel_secret: '',
          channel_access_token: '',
        });
        checkConnectionStatus();
        // setIsAlertOpen(true); // Show AlertDialog
      } else {
        toast({ title: data.error });
      }
    } catch (error) {
      console.error('Error during submission:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setFormData({
            channel_id: '',
            channel_secret: '',
            channel_access_token: '',
          });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('Integrations.LineIntegration.Add Line Bot')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
              <FormControl id='channel_id' isRequired>
                <FormLabel>Channel ID</FormLabel>
                <Input
                  type='text'
                  name='channel_id'
                  value={formData.channel_id}
                  onChange={onChange}
                  placeholder={t(
                    'Integrations.LineIntegration.Enter Channel ID'
                  )}
                />
                {errors.channel_id && (
                  <Text color={'red'}>{errors.channel_id}</Text>
                )}
              </FormControl>
              <FormControl id='channel_secret' isRequired>
                <FormLabel>
                  {t('Integrations.LineIntegration.Channel Secret')}
                </FormLabel>
                <Input
                  type='text'
                  name='channel_secret'
                  value={formData.channel_secret}
                  onChange={onChange}
                  placeholder={t(
                    'Integrations.LineIntegration.Enter Channel Secret'
                  )}
                />
                {errors.channel_secret && (
                  <Text color={'red'}>{errors.channel_secret}</Text>
                )}
              </FormControl>
              <FormControl id='channel_access_token' isRequired>
                <FormLabel>
                  {t('Integrations.LineIntegration.Channel Access Token')}
                </FormLabel>
                <Input
                  type='text'
                  name='channel_access_token'
                  value={formData.channel_access_token}
                  onChange={onChange}
                  placeholder={t(
                    'Integrations.LineIntegration.Enter Channel Access Token'
                  )}
                />
                {errors.channel_access_token && (
                  <Text color={'red'}>{errors.channel_access_token}</Text>
                )}
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant={'ghost'} mr={3} onClick={onClose}>
              {t('Integrations.LineIntegration.Close')}
            </Button>
            <Button
              bg='#002298'
              color='#fff'
              onClick={handleSubmit}
              isDisabled={isLoading}
            >
              {t('Integrations.LineIntegration.Submit')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* AlertDialog for success message */}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>
              <BotDropdown />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default LineIntegration;
