import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('docsbot-users') ? true : false;

  console.log(isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
