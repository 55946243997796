import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { AiOutlineRight } from "react-icons/ai";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import CreateNewBot from "./Dashboard_components/createNewBot";
import { useNavigate } from "react-router-dom";
import BotIcon from "../assets/dashboard/botIcon.png";
import SourceIcon from "../assets/dashboard/sourceIcon.png";
import SourcePageIcon from "../assets/dashboard/SourcePageIcon.png";
import CurrentPlanIcon from "../assets/dashboard/currentPlanIcon.png";
import ViewBotIcon from "../assets/dashboard/ViewBotIcon.png";
import NewBotIcon from "../assets/dashboard/NewBotIcon.png";
import PlanAndBillingIcon from "../assets/dashboard/PlanAndBillingIcon.png";
import TeamManagementIcon from "../assets/dashboard/TeamManagementIcon.png";

import withAccessControl from "../utils/withAccessControl";

import { PricingChartModel } from "./PricingChartModel";

import FetchWithToken from "../utils/FetchWithToken";
import { useTranslation } from "react-i18next";

const Dashboard = ({ hasAccess, handleCallback, details, Package }) => {
  const { t, i18n } = useTranslation();
  //api
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const [currentPackage, setCurrentPackage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  // const [userSubscription, setUserSubscription] = useState(null);

  // const { globalTeam, setGlobalTeam } = useContext(AppContext);

  // const [result, setresult] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [showPricingModal, setShowPricingModal] = useState(false);

  // const handleShowPricingChart = () => {
  //   setShowPricingModal(true);
  // };

  const handleClosePricingModal = () => {
    setShowPricingModal(false);
  };

  const handleOpenModal = (data) => {
    if (data) {
      setShowModal(true);
    } else if (data === false) {
      setShowPricingModal(true);
    }
    // navigate("/admin-dashboard/account");
    // toast({
    //     title: 'Update your plan',
    //     status: 'info',
    //     duration: 4000,
    //     isClosable: true,
    // });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // fetchData();
    handleCallback();
  };
  const handleCancel = () => {
    setShowModal(false);
    handleCallback();
  };
  // function fetchUserSubcriptionData() {
  //   const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
  //   setIsLoading(true);
  //   FetchWithToken(BASE_URL + `/user_subscriptions?user_id=${user_id}`, 'GET')
  //     .then(data => {
  //       setUserSubscription(data.subscription);
  //     });
  // }
  // function fetchData() {
  //   // const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
  //   const selectedTeam = JSON.parse(localStorage.getItem("docsbot-users")).selectedTeam;
  //   // Check if data has already been fetched

  //   setIsLoading(true);
  //   FetchWithToken(BASE_URL + `/stats?team_id=${selectedTeam.team_id}`, 'GET')
  //     .then(data => {
  //       setDashboardData(data);
  //       console.log(data);
  //     });
  // }

  // function fetchCurrentPackage(current_plan) {
  //   FetchWithToken(BASE_URL + `/packages?plan=${current_plan}`, "GET").then(
  //     (data) => {
  //       setCurrentPackage(data.features);
  //       setIsLoading(false);
  //     }
  //   );
  // }

  useEffect(() => {
    setDashboardData(details);
    if (currentPackage) {
      setIsLoading(false);
    }
  }, [details, currentPackage]);

  useEffect(() => {
    if (dashboardData?.current_plan && Package) {
      //  fetchCurrentPackage(dashboardData.current_plan);
      setCurrentPackage(Package);
    }
  }, [dashboardData, Package]);

  // const user_planName = JSON.parse(localStorage.getItem("docsbot-users")).subscription?.plan_name;

  return (
    <>
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" mt={10}>
          <Spinner color="blue.500" />
        </Box>
      ) : (
        <Box>
          <SimpleGrid
            spacing={4}
            templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          >
            <Card height="130px" bg={"rgba(64, 140, 253, 0.08)"}>
              <CardBody
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                padding={"0px 15px"}
              >
                <Box padding="0">
                  <Box display="flex" alignItems={"center"}>
                    <Text fontWeight={700} fontSize={36}>
                      {dashboardData?.bots_count}
                    </Text>
                    <Text pt={"12px"}>/{currentPackage?.BOTS.count}</Text>
                  </Box>
                </Box>
                <Box display={"flex"} h={"100%"} alignItems={"center"}>
                  {/* <BsRobot color={"blue"} size={24} /> */}
                  <Image src={BotIcon} alt="BotIcon" />
                </Box>
              </CardBody>
              <CardFooter
                justifyContent="space-between"
                padding={"0px 10px 13px 10px"}
              >
                <Text fontWeight={500} color={"#212121"}>
                  {t("Dashboard.all_bots")}
                  {/* All Bots */}
                </Text>
                <Text
                  color="#6e6e6e"
                  fontWeight={500}
                  fontSize={"12px"}
                  onClick={() => navigate("/admin-dashboard/chatbots")}
                  gap={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent="flex-start"
                  borderRadius={0}
                  cursor="pointer"
                  // _hover={{ color: "var(--Web-color-orange)" }}
                  _hover={{ color: "#002298" }}
                >
                  {t("Dashboard.view_all")} <AiOutlineRight />
                  {/* View all <AiOutlineRight /> */}
                </Text>
              </CardFooter>
            </Card>

            <Card height="130px" bg={"rgba(255, 184, 59, 0.08)"}>
              <CardBody
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                padding={"0px 15px"}
              >
                <Box padding="0">
                  <Box display="flex" alignItems={"center"}>
                    <Text fontWeight={700} fontSize={36}>
                      {dashboardData?.sources_count}
                    </Text>
                  </Box>
                </Box>
                <Box display={"flex"} h={"100%"} alignItems={"center"}>
                  <Image src={SourceIcon} alt="BotIcon" />
                </Box>
              </CardBody>
              <CardFooter
                justifyContent="flex-start"
                padding={"0px 10px 13px 10px"}
              >
                <Text fontWeight={500} color={"#212121"}>
                  {t("Dashboard.sources")}
                  {/* Sources */}
                </Text>
              </CardFooter>
            </Card>

            <Card height="130px" bg={"rgba(233, 225, 254, 0.4)"}>
              <CardBody
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                padding={"0px 15px"}
              >
                <Box padding="0">
                  <Box display="flex" alignItems={"center"}>
                    <Text fontWeight={700} fontSize={36}>
                      {dashboardData?.source_pages}
                    </Text>
                    <Text pt={"12px"}>
                      /{currentPackage?.SOURCE_PAGES.count}
                    </Text>
                  </Box>
                </Box>
                <Box display={"flex"} h={"100%"} alignItems={"center"}>
                  <Image src={SourcePageIcon} alt="BotIcon" />
                </Box>
              </CardBody>
              <CardFooter
                justifyContent="space-between"
                padding={"0px 10px 13px 10px"}
              >
                <Text fontWeight={500} fontSize={"15px"} color={"#212121"}>
                  {t("Dashboard.source_pages")}
                  {/* Souce pages */}
                </Text>
              </CardFooter>
            </Card>

            <Card height="130px" bg={"rgba(255, 62, 24, 0.05)"}>
              <CardBody
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                padding={"0px 15px"}
              >
                <Box padding="0">
                  <Box display="flex" alignItems={"center"}>
                    <Text fontWeight={700} fontSize={36}>
                      {dashboardData?.questions}
                    </Text>
                    <Text pt={"12px"}>/{currentPackage?.QUESTIONS.count}</Text>
                  </Box>
                </Box>
                <Box display={"flex"} h={"100%"} alignItems={"center"}>
                  <FaRegQuestionCircle color={"red"} size={24} />
                </Box>
              </CardBody>
              <CardFooter
                justifyContent="space-between"
                padding={"0px 10px 13px 10px"}
              >
                <Text fontWeight={500} fontSize={"15px"} color={"#212121"}>
                  {t("Dashboard.questions")}
                  {/* Questions */}
                </Text>
              </CardFooter>
            </Card>

            <Card height="130px" bg={"rgba(228, 246, 242, 0.82)"}>
              <CardBody
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                padding={"0px 15px"}
              >
                <Box padding="0">
                  <Box display="flex" alignItems={"center"}>
                    <Text fontWeight={700} fontSize={36}>
                      {dashboardData?.current_plan}
                    </Text>
                  </Box>
                </Box>
                <Box display={"flex"} h={"100%"} alignItems={"center"}>
                  <Image src={CurrentPlanIcon} alt="BotIcon" />
                </Box>
              </CardBody>
              <CardFooter
                justifyContent="space-between"
                padding={"0px 10px 13px 10px"}
              >
                <Text fontWeight={500} fontSize={"15px"} color={"#212121"}>
                  {t("Dashboard.current_plan")}
                  {/* Current Plan */}
                </Text>
                <Text
                  color="#6e6e6e"
                  fontWeight={500}
                  fontSize={"12px"}
                  onClick={() => navigate("/admin-dashboard/account")}
                  gap={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent="flex-start"
                  borderRadius={0}
                  cursor="pointer"
                  // _hover={{ color: "var(--Web-color-orange)" }}
                  _hover={{ color: "#002298" }}
                >
                  {t("Dashboard.manage_plan")} <AiOutlineRight />
                  {/* Manage plan <AiOutlineRight /> */}
                </Text>
              </CardFooter>
            </Card>
          </SimpleGrid>

          <SimpleGrid
            paddingTop={10}
            spacing={6}
            templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          >
            <Card p={"20px 25px"} shadow={0}>
              <CardHeader p={0} display="flex" justifyContent="space-between">
                <Text fontWeight={700}>{t("Dashboard.view_bots")}</Text>
                {/* <ImArrowUpRight2 style={{ color: "#bfbfbf" }} /> */}
              </CardHeader>
              <CardBody
                m={"39px 0 40px 0"}
                p={0}
                display="flex"
                flexDirection="column"
                alignItems="start"
                gap={3}
              >
                <Image src={ViewBotIcon} alt="BotIcon" />
                <Text fontSize={13} fontWeight={500} color="gray.500">
                  {t(
                    "Dashboard.Manage, test, use, and deploy trained Chatbots"
                  )}
                  .
                </Text>
              </CardBody>

              <CardFooter p={0}>
                <Button
                  onClick={() => navigate("/admin-dashboard/chatbots")}
                  bg={""}
                  w={"100%"}
                  h={"34px"}
                  border={"1px solid rgba(33, 33, 33, 0.21)"}
                  borderRadius={"80px"}
                  // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                  _hover={{ bg: "#002298", color: "white" }}
                >
                  {t("Dashboard.view")}
                  {/* View */}
                </Button>
              </CardFooter>
            </Card>

            <Card p={"20px 25px"} shadow={0}>
              <CardHeader p={0} display="flex" justifyContent="space-between">
                <Text fontWeight={700}>{t("Dashboard.new_bots")}</Text>
                {/* <ImArrowUpRight2 style={{ color: "#bfbfbf" }} /> */}
              </CardHeader>
              <CardBody
                m={"39px 0 40px 0"}
                p={0}
                display="flex"
                flexDirection="column"
                alignItems="start"
                gap={3}
              >
                <Image src={NewBotIcon} alt="BotIcon" />
                <Text fontSize={13} fontWeight={500} color="gray.500">
                  {t(
                    "Dashboard.Train a new knowledge base with your custom documentation and content"
                  )}
                </Text>
              </CardBody>
              <CardFooter p={0}>
                <Button
                  onClick={() => handleOpenModal(hasAccess?.BOTS.enabled)}
                  bg={""}
                  w={"100%"}
                  h={"34px"}
                  border={"1px solid rgba(33, 33, 33, 0.21)"}
                  borderRadius={"80px"}
                  // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                  _hover={{ bg: "#002298", color: "white" }}
                >
                  {t("Dashboard.view")}
                  {/* View */}
                </Button>
              </CardFooter>
            </Card>

            <Card p={"20px 25px"} shadow={0}>
              <CardHeader p={0} display="flex" justifyContent="space-between">
                <Text fontWeight={700}>{t("Dashboard.Plan & Billing")}</Text>
              </CardHeader>
              <CardBody
                m={"39px 0 40px 0"}
                p={0}
                display="flex"
                flexDirection="column"
                alignItems="start"
                gap={3}
              >
                <Image src={PlanAndBillingIcon} alt="BotIcon" />
                <Text fontSize={13} fontWeight={500} color="gray.500">
                  {t(
                    "Dashboard.Manage your plans and billing information in your billing dashbaord"
                  )}
                  .
                </Text>
              </CardBody>
              <CardFooter p={0}>
                <Button
                  onClick={() => navigate("/admin-dashboard/account")}
                  bg={""}
                  w={"100%"}
                  h={"34px"}
                  border={"1px solid rgba(33, 33, 33, 0.21)"}
                  borderRadius={"80px"}
                  // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                  _hover={{ bg: "#002298", color: "white" }}
                >
                  {t("Dashboard.view")}
                  {/* View */}
                </Button>
              </CardFooter>
            </Card>

            <Card p={"20px 25px"} shadow={0}>
              <CardHeader p={0} display="flex" justifyContent="space-between">
                <Text fontWeight={700}>{t("Dashboard.team_management")}</Text>
              </CardHeader>
              <CardBody
                m={"39px 0 40px 0"}
                p={0}
                display="flex"
                flexDirection="column"
                alignItems="start"
                gap={3}
              >
                <Image src={TeamManagementIcon} alt="BotIcon" />
                <Text fontSize={13} fontWeight={500} color="gray.500">
                  {t(
                    "Dashboard.Manage your team members and their roles in your team dashbaord"
                  )}
                  .
                </Text>
              </CardBody>
              <CardFooter p={0}>
                <Button
                  onClick={() => navigate("/admin-dashboard/team")}
                  bg={""}
                  w={"100%"}
                  h={"34px"}
                  border={"1px solid rgba(33, 33, 33, 0.21)"}
                  borderRadius={"80px"}
                  // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                  _hover={{ bg: "#002298", color: "white" }}
                >
                  {t("Dashboard.view")}
                  {/* View */}
                </Button>
              </CardFooter>
            </Card>
          </SimpleGrid>
          <CreateNewBot
            isOpen={showModal}
            onClose={handleCloseModal}
            onCancel={handleCancel}
            hasAccess={hasAccess}
          />
          {showPricingModal && (
            <PricingChartModel
              isOpen={showPricingModal}
              onClose={handleClosePricingModal}
            />
          )}
        </Box>
      )}
    </>
  );
};
const DashboardWithAccessControl = withAccessControl(Dashboard);
export default DashboardWithAccessControl;
