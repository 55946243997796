import {
  Box,
  Grid,
  Text,
  Button,
  IconButton,
  useDisclosure,
  useToast,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  Image,
  Badge,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaStar, FaPlus, FaArrowRight } from "react-icons/fa"; // Icons for star, plus, and arrow
import { useState, useEffect, useRef } from "react";

const Prompt = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const USER_ID = "a68fce7e-313f-4755-8ce2-5dee8c14e98e";

  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newPrompt, setNewPrompt] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure(); // Disclosure for Drawer
  const toast = useToast();
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const btnRef = useRef();
  const [categoryName, setCategoryName] = useState("");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const superadmin = JSON.parse(
    localStorage.getItem("docsbot-users")
  )?.superadmin;

  const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${BASE_URL}/prompt?user_id=${user_id}`);
      const data = await response.json();
      console.log("Fetched Categories:", data);
      setCategories(data);
    } catch (error) {
      toast({
        title: "Error fetching categories.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Add a new category
  const handleAddCategory = async () => {
    if (!newCategoryName || !newPrompt) {
      toast({
        title: "Please fill in both fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/prompt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          category_name: newCategoryName,
          prompt: newPrompt,
        }),
      });

      const data = await response.json();
      if (data?.prompt_id) {
        toast({
          title: "Category added successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setNewCategoryName("");
        setNewPrompt("");

        fetchCategories();
        onClose();
      } else {
        toast({
          title: "Failed to add category.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error adding category.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Delete a category
  const handleDeleteCategory = async (prompt_id) => {
    try {
      await fetch(`${BASE_URL}/prompt?prompt_id=${prompt_id}`, {
        method: "DELETE",
      });
      toast({
        title: "Category deleted successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      window.location.reload();
      fetchCategories();
    } catch (error) {
      toast({
        title: "Error deleting category.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // View details of a category
  // View details of a category
  const handleViewDetails = (category) => {
    setSelectedCategory(category);
    setCategoryName(category.category_name);
    setPrompt(category.prompt);
    onDrawerOpen();
  };

  // Update a category
  const handleUpdateCategory = async (prompt_id) => {
    if (!prompt_id) return;

    const updatedData = {
      user_id: user_id,
      prompt_id,
      category_name: categoryName,
      prompt: prompt,
    };

    try {
      const response = await fetch(`${BASE_URL}/prompt`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      console.log("Full Response:", response);

      const data = await response.json();
      console.log("Update Response JSON:", data); // Log JSON response

      if (response.ok && data?.prompt_id) {
        // Include the response message in the success toast
        toast({
          title: data?.message || "Category updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Update the local state
        const updatedCategories = categories.map((category) =>
          category.prompt_id === prompt_id
            ? { ...category, ...updatedData }
            : category
        );

        setCategories(updatedCategories);
       // window.location.reload();
        fetchCategories();
        onDrawerClose();
      } else {
        const errorMessage = data?.message || "Failed to update category.";
        toast({
          title: errorMessage,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message || "Error updating category.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      console.log("Selected Category:", selectedCategory);
      setCategoryName(selectedCategory.category_name);
      setPrompt(selectedCategory.prompt);
    }
  }, [selectedCategory]);

  return (
    <Box>
      {/* Add Category Button */}

      {superadmin && (
        <Button
          onClick={onOpen}
          colorScheme="transparent"
          mb={5}
          color={"black"}
          border={"1px solid rgba(0, 0, 0, 0.15)"}
          leftIcon={
            <Box bg={"#30C496"} p={1} borderRadius="xl">
              <FaPlus color="white" />
            </Box>
          }
        >
          Add Category
        </Button>
      )}

      {/* Add Category Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a New Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter category name"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              mb={3}
            />
            <Textarea
              value={newPrompt}
              placeholder="Enter a new Prompt"
              onChange={(e) => setNewPrompt(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddCategory}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Display categories in a grid */}
      {categories.length > 0 ? (
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {categories.map((category, index) => (
            <Box
              key={index}
              border="1px solid #e2e8f0"
              p={4}
              rounded="md"
              boxShadow="sm"
              position="relative"
              width={"313px"}
              height={"251px"}
              bg="white"
              borderColor={activeCategory === index ? "blue.500" : "#e2e8f0"}
              onClick={() => setActiveCategory(index)}
              cursor="pointer"
            >
              {/* Label at the top of the card */}
              <Badge
                position="absolute"
                top={2}
                left={2}
                colorScheme="#F2FFFB"
                textColor={"#2BAF6B"}
                border={"1px solid #2BAF6B "}
                borderRadius={"xl"}
              >
                Category
              </Badge>

              {/* Category Name */}
              <Text fontWeight="bold" mb={2} fontSize="lg" mt={6}>
                {category.category_name || "Lorem Ipsum"}
              </Text>

              {/* Category Prompt */}
              <Text mb={4} noOfLines={3}>
                {category.prompt ||
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
              </Text>

              {/* Star Icon and Plus Icon */}
              <Image
                src="/prompt.svg"
                alt="Star Icon"
                boxSize="50px"
                position="absolute"
                top={2}
                right={1}
              />

              {/* Arrow Icon at Bottom Right */}
              <IconButton
                aria-label="View details"
                icon={<FaArrowRight />}
                bg={"#2BAF6B"}
                size="sm"
                position="absolute"
                bottom={2}
                right={2}
                rounded={"3xl"}
                color={"white"}
                onClick={() => handleViewDetails(category)} // Open drawer on click
              />
            </Box>
          ))}
        </Grid>
      ) : (
        <Text>No categories added yet.</Text>
      )}

      {/* Drawer for category details */}
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={onDrawerClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            {/* Conditional Rendering for Superadmin */}
            {superadmin ? (
              <>
                <Text fontSize="md" fontWeight="700" mb={1} mt={7}>
                  Edit Category Name
                </Text>
                <Input
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  mb={6}
                />
              </>
            ) : (
              <>
                <Text fontSize="md" fontWeight="700" mb={1} mt={7}>
                  View Category Name
                </Text>
                <Input
                  value={categoryName}
                  isDisabled // Disable the input for regular users
                  mb={6}
                />
              </>
            )}

            {/* Prompt Editing for All Users */}
            <Text fontSize="md" fontWeight="700" mb={1}>
              Edit Prompt
            </Text>
            <Textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              height="200px"
              style={{ scrollbarWidth: "none" }}
            />
          </DrawerBody>

          {/* Footer with Delete and Update Buttons */}
          <DrawerFooter justifyContent="end" bg="#FFFFFF" gap="4px">
            {superadmin && (
              <Button
                colorScheme="white"
                border="1px solid #FF6666"
                color="#FF6666"
                onClick={() =>
                  handleDeleteCategory(selectedCategory?.prompt_id)
                }
              >
                Delete
              </Button>
            )}
            <Button
              bg="#2BAF6B"
              color="white"
              onClick={() => {
                // Check if superadmin or valid prompt_id to update
                if (
                  superadmin ||
                  (selectedCategory && selectedCategory.prompt_id)
                ) {
                  handleUpdateCategory(selectedCategory.prompt_id).then(() => {
                    
                    window.location.reload();
                  });
                } else {
                  toast({
                    title: "Error: No category selected.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
            >
              Update
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Prompt;
