import React, { useEffect, useState } from 'react';
import {
  Button,
  ListItem,
  Text,
  Textarea,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
// import { extendTheme } from '@chakra-ui/react';
// import { ChakraProvider } from '@chakra-ui/provider';
import FetchWithToken from '../../../utils/FetchWithToken';

// const theme = extendTheme({
//   colors: {
//     custom: "#F33281", // Set the custom color
//   },
// });

export const DefaultPromptModal = ({ isOpen, onClose, botData }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const toast = useToast();

  const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;

  const [promptvalue, setPromptValue] = useState(botData.prompt || '');

  // Load saved prompt from localStorage when the component loads
  useEffect(() => {
    const savedPrompt = localStorage.getItem(`prompt_${botData.bot_id}`);
    if (savedPrompt) {
      setPromptValue(savedPrompt); // If a saved prompt exists, set it to the state
    }
  }, [botData.bot_id]); // Ensure it runs when the bot ID changes

  // Handle prompt input change
  const handlePromptChange = (e) => {
    const inputValue = e.target.value;
    setPromptValue(inputValue);
    localStorage.setItem(`prompt_${botData.bot_id}`, inputValue); // Save to localStorage immediately
  };

  const handleSaveClick = () => {
    const payload = {
      bot_id: botData.bot_id,
      prompt: promptvalue,
      user_id: user_id,
    };

    FetchWithToken(BASE_URL + `/chatbot/update_prompt`, 'PUT', payload).then(
      (data) => {
        onClose();
        toast({
          title: data.message,
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
        // Clear saved prompt from localStorage after saving
        localStorage.removeItem(`prompt_${botData.bot_id}`);
      }
    );
  };

  const PromptModalBody = () => {
    return (
      <>
        <Text>
          Tailor your bot's answers to fit your unique needs by adding custom
          instructions to your prompts. With this powerful tool, you can make
          highly specific changes to achieve the desired output.
        </Text>
        <Text my={2} fontSize='3xl'>
          Examples
        </Text>

        <UnorderedList mt={2} ml='25px'>
          <ListItem>
            "Politely refuse to answer questions not related to the
            documentation provided."
          </ListItem>
          <ListItem>
            "Begin each answer with the phrase 'Here is some information that
            might help you' "
          </ListItem>
          <ListItem>
            "End each answer with the phrase 'Is there anything else I can help
            you with?' "
          </ListItem>
          <ListItem>
            "If the answer isn't found in the documentation provided, kindly
            suggest reaching out to the support team for assistance through the
            page: https://yourdomain.com/support/"
          </ListItem>
        </UnorderedList>
        <Text my={2} fontSize='1xl'>
          Custom Prompt
        </Text>
      </>
    );
  };

  return (
    <>
      <Modal size={'3xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Customize Prompt (advanced) </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PromptModalBody />
            <Textarea
              colorScheme='teal'
              onChange={(e) => handlePromptChange(e)}
              placeholder='Enter any custom promt here...'
              value={promptvalue}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              _hover={{ bg: '#EB6F0D' }}
              w={'130px'}
              h={'34px'}
              borderRadius={'65px'}
              onClick={() => handleSaveClick(onClose)}
              bg='#FF8321'
              color={'white'}
              mr={3}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
