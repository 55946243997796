import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import FetchWithToken from '../../../utils/FetchWithToken';

const LeadsModal = ({ isOpen, onClose, botData, setShowToast, setmessage }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [data, setData] = useState();
  const [isLoading, setisloading] = useState(false);

  const [formData, setFormData] = useState({
    numberOfMessages: 0,
    message: 'In case we get disconnected, what is your name and email?',
    collectAfterMessages: false,
    collectAtStart: false,
    toggleName: false,
    toggleEmail: false,
    togglePhone: false,
  });

  useEffect(() => {
    if (formData?.collectAfterMessages || formData?.collectAtStart) {
      if (
        !formData?.toggleName &&
        !formData?.toggleEmail &&
        !formData?.togglePhone
      ) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          toggleEmail: true,
        }));
      }
    } else if (!formData?.collectAfterMessages && !formData?.collectAtStart) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        toggleEmail: false,
        togglePhone: false,
        toggleName: false,
      }));
    }
  }, [
    formData?.collectAfterMessages,
    formData?.collectAtStart,
    formData?.toggleName,
    formData?.toggleEmail,
    formData?.togglePhone,
  ]);

  const handleInputChange = (event, setData) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToggleChange = (fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [`toggle${fieldName}`]: !prevData[`toggle${fieldName}`],
    }));
  };

  useEffect(() => {
    if (data) {
      const collectAfterMessages =
        data.collect_leads_after_message === '0' ? false : true;
      const messages =
        data.collect_leads_after_message === '0'
          ? '0'
          : data.collect_leads_after_message;

      setFormData({
        message: data.message,
        toggleName: data.name,
        togglePhone: data.phone,
        toggleEmail: data.email,
        collectAtStart: data.collect_leads_at_start,
        collectAfterMessages: collectAfterMessages,
        numberOfMessages: messages,
      });
    }
  }, [data]);
  useEffect(() => {
    if (
      formData?.collectAfterMessages &&
      !data?.collect_leads_after_message > 0 &&
      !formData?.numberofMessages > 0
    ) {
      setFormData((prevData) => ({
        ...prevData,
        numberOfMessages: 1,
      }));
    }
  }, [formData.collectAfterMessages]);

  useEffect(() => {
    if (isOpen) {
      FetchWithToken(
        BASE_URL + '/get-bot-leads?bot_id=' + botData.bot_id,
        'GET'
      ).then((data) => {
        setData(data?.settings[0]);
      });
    }
  }, [isOpen]);

  const handleSubmit = () => {
    const messages = formData?.collectAfterMessages
      ? formData?.numberOfMessages
      : '0';

    const payload = {
      bot_id: botData.bot_id,
      message: formData?.message,
      name: formData?.toggleName,
      email: formData?.toggleEmail,
      phone: formData?.togglePhone,
      collect_leads_after_message: messages,
      collect_leads_at_start: formData?.collectAtStart,
    };
    setisloading(true);
    FetchWithToken(BASE_URL + '/generate-leads', 'POST', payload).then(
      (data) => {
        if (data?.leads_id) {
          setisloading(false);
          if (!formData.collectAfterMessages && !formData.collectAtStart) {
            setmessage('disabled');
          } else {
            setmessage('enabled');
          }
          setShowToast(true);
          onClose();
        }
      }
    );
  };

  return (
    <Modal size={'xl'} isOpen={isOpen} onClose={onClose} color={'#202020'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p={4}
          height={'103px'}
          bg={'rgba(255, 131, 33, 0.05)'}
          cursor={'pointer'}
        >
          <Text fontSize={'24px'} height={'32px'} fontWeight={700}>
            Leads Capture
          </Text>
          <Text fontSize={'13px'} height={'20px'} fontWeight={500}>
            Configure your chatbot to gather customer's data while providing a
            personalised experience
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={4} width={'577px'} height={'559px'} cursor={'pointer'}>
          <Flex justifyContent={'space-between'} pb={4}>
            <Text fontSize={14} height={'19px'} fontWeight={700}>
              Choose What Data TO Collect From Your Users
            </Text>
            <Text fontSize={14} height={'19px'} fontWeight={700}>
              ON/OFF
            </Text>
          </Flex>
          <Divider borderColor='rgba(32, 32, 32, 0.15)' borderWidth='1px' />
          <Flex direction='column' pt={4}>
            <Box>
              <Flex alignItems='center'>
                <Text fontSize={'14px'} fontWeight={500}>
                  Message <InfoOutlineIcon />
                </Text>
              </Flex>
              <Input
                mt={4}
                fontSize={14}
                fontWeight={500}
                value={formData.message}
                name='message'
                onChange={(event) => handleInputChange(event, setFormData)}
              />
            </Box>
            <Flex mt={4} justifyContent={'space-between'}>
              <Text fontSize={'14px'} fontWeight={500}>
                Name
              </Text>
              <Switch
                colorScheme='orange'
                size='md'
                isChecked={formData.toggleName}
                onChange={() => handleToggleChange('Name')}
              />
            </Flex>
            <Input
              mt={4}
              placeholder='Name'
              name='name'
              isDisabled={true}
              _disabled={{
                opacity: 1,
                cursor: 'not-allowed',
              }}
            />

            <Flex mt={4} justifyContent={'space-between'}>
              <Text fontSize={'14px'} fontWeight={500}>
                Email
              </Text>
              <Switch
                colorScheme='orange'
                size='md'
                isChecked={formData.toggleEmail}
                onChange={() => handleToggleChange('Email')}
              />
            </Flex>
            <Input
              mt={4}
              placeholder='Email address'
              name='email'
              isDisabled={true}
              _disabled={{
                opacity: 1,
                cursor: 'not-allowed',
              }}
            />

            <Flex mt={4} justifyContent={'space-between'}>
              <Text fontSize={'14px'} fontWeight={500}>
                Phone Number
              </Text>
              <Switch
                colorScheme='orange'
                size='md'
                isChecked={formData.togglePhone}
                onChange={() => handleToggleChange('Phone')}
              />
            </Flex>
            <Input
              mt={4}
              placeholder='Phone number'
              name='phone'
              _disabled={{
                opacity: 1,
                cursor: 'not-allowed',
              }}
              isDisabled={true}
            />
            <Text mt={4} mb={4} fontSize={15} fontWeight={'bold'}>
              When Do You Want To Collect Leads? <InfoOutlineIcon />
            </Text>
            <Flex justifyContent={'space-between'} height={'28px'} p={2}>
              <Text
                fontSize={'14px'}
                fontWeight={500}
                display='flex'
                alignItems='center'
              >
                Collect Leads After{' '}
                <NumberInput
                  id='amountInput'
                  type='number'
                  min='1'
                  borderColor={'#FF8321'}
                  value={formData.numberOfMessages}
                  name='numberOfMessages'
                  disabled={!formData.collectAfterMessages}
                  _disabled={{
                    opacity: 1,
                    cursor: 'not-allowed',
                  }}
                  onChange={(valueString, valueNumber) =>
                    handleInputChange(
                      {
                        target: {
                          name: 'numberOfMessages',
                          value: valueString,
                        },
                      },
                      setFormData,
                      setFormData
                    )
                  }
                  _hover={{
                    borderColor: '#FF8321',
                  }}
                  width='82px'
                  height='28px'
                  ml='2'
                  mr='2'
                  fontSize='sm'
                >
                  <NumberInputField height='100%' />{' '}
                  <NumberInputStepper height='100%'>
                    {' '}
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                Messages
              </Text>

              <Switch
                colorScheme='orange'
                size='md'
                isChecked={formData.collectAfterMessages}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    collectAfterMessages: !prevData.collectAfterMessages,
                    collectAtStart: false, // Set the other switch to false
                  }))
                }
              />
            </Flex>
            <Flex justifyContent={'space-between'} p={2} mt={4}>
              <Text fontSize={'14px'} fontWeight={500}>
                Collect Leads At The Start Of The Conversation
              </Text>
              <Switch
                colorScheme='orange'
                size='md'
                isChecked={formData.collectAtStart}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    collectAfterMessages: false,
                    collectAtStart: !prevData.collectAtStart,
                  }))
                }
              />
            </Flex>
            <ButtonGroup
              spacing='2'
              pt={6}
              display={'flex'}
              justifyContent={'center'}
            >
              <Button
                border={'1px solid rgba(33, 33, 33, 0.15)'}
                w={'128px'}
                h={'33px'}
                fontSize={'14px'}
                variant='outline'
                borderRadius={'65px'}
                color='#979797'
                onClick={onClose}
              >
                {/* {t("Chatbots.Details.Cancel")} */}
                Cancel
              </Button>
              <Button
                w={'128px'}
                h={'33px'}
                fontSize={'14px'}
                _hover={{ bg: '#091D62' }}
                isLoading={isLoading}
                // loadingText="loading"
                type='submit'
                borderRadius={'65px'}
                variant='solid'
                bg={'#002298'}
                color={'white'}
                onClick={handleSubmit}
              >
                {/* {t("Chatbots.Details.Add source")} */}
                Save
              </Button>
            </ButtonGroup>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LeadsModal;
