import { createPortal } from "react-dom";
import { Box, Button, Text, Heading, VStack } from "@chakra-ui/react";
import FetchWithToken from "../../utils/FetchWithToken";
import { useState } from "react";

const DisconnectPopup = ({ platform, onClose }) => {
  const userData = JSON.parse(localStorage.getItem("docsbot-users"));
  const [QRData, setQRData] = useState();

  const handleDisconnect = async (platform) => {
    try {
      await FetchWithToken(
        `${process.env.REACT_APP_BASE_URL}/integrations/disconnect?user_id=${userData.user_id}&platform=${platform}`,
        { method: "DELETE" }
      );
      setQRData(undefined); 
    } catch (error) {
      console.error("Error disconnecting provider:", error);
    }
  };

  const portalRoot = document.getElementById("popup-root");
  if (!portalRoot) return null; 

  return createPortal(
    <Box
      position="fixed"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      backgroundColor="#fff"
      borderRadius="10px"
      boxShadow="lg"
      width="300px"
      padding="20px"
    >
      <VStack spacing={4} align="start">
        <Heading size="md">Disconnect from {platform}?</Heading>
        <Text>Are you sure you want to disconnect from {platform}?</Text>

        <Box display="flex" justifyContent="space-between" width="100%">
          <Button
            colorScheme="green"
            onClick={() => {
              handleDisconnect();
              onClose();
            }}
          >
            Disconnect
          </Button>

          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </VStack>
    </Box>,
    portalRoot
  );
};

export default DisconnectPopup;


