import { useState } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
// import logoDark from '../assets/logoDark.png';
import glt from '../assets/GL_logo.png';
// import gltIcon from "../assets/icon.png";

const ForgetPassword = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetWithEmailLink = (event) => {
    event.preventDefault();
    if (email) {
      fetch(BASE_URL + '/forget_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      })
        .then((response) => response.json())
        .then((data) => {
          setIsEmailSent(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleBackToLogin = () => {
    setIsEmailSent(false);
    setEmail('');
    navigate('/');
  };

  return (
    <Flex minHeight='100vh' width='full' align='center'>
      <Box width='full' align='center'>
        {/* <Image
                  src={logoDark}
                  alt='logodark'
                  h={'40px'}
              /> */}
        <Flex align='center' justify='center'>
          {/* <Image src={gltIcon} alt="gltIcon" h={"45px"} /> */}
          <Image src={glt} alt='glt' h={'50px'} />
        </Flex>

        <Box
          my={4}
          p={8}
          maxWidth='450px'
          borderWidth={1}
          borderRadius={10}
          boxShadow='lg'
        >
          {/* <Image src="https://docsbot.ai/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdocsbot-logo.c6071a27.png&w=256&q=75" alt="Logo" mb={4} /> */}
          {/* <Image
                    
                         src={logoDark}
                         alt='logodark'
                         h={'40px'}
                     /> */}

          {!isEmailSent ? (
            <>
              <Box>
                <Heading as='h3' size='lg'>
                  Forgot password?
                </Heading>
              </Box>
              <Text fontSize='13px' fontWeight='bold' color='gray.500' mt={3}>
                No worries! Just enter your email and we will send you login
                instructions.
              </Text>
              <Box mt={3}>
                <form onSubmit={handleResetWithEmailLink}>
                  <FormControl>
                    <FormLabel>Email Address</FormLabel>
                    <Input
                      required
                      type='email'
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </FormControl>
                  <Button
                    w='100%'
                    color={'white'}
                    // _hover={{ bg: "#EB6F0D" }}
                    // bg={"#FF8321"}
                    _hover={{ bg: '#002298' }}
                    bg={'#000F40'}
                    mt={4}
                    type='submit'
                  >
                    Send Reset Link
                  </Button>
                  {/* <Flex justifyContent={'flex-end'}>
                                    <Button variant="link" onClick={() => navigate('/')} colorScheme="teal">
                                        Sign In
                                    </Button>
                                    </Flex> */}
                </form>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Heading as='h3' size='lg'>
                  Help is on the way!
                </Heading>
              </Box>
              <Text fontSize='13px' fontWeight='bold' color='gray.500' mt={3}>
                Check your inbox. We have send you instructions on how to reset
                your password on the email ({email}) provided.
              </Text>

              <Button
                w='100%'
                variant={'link'}
                color={'#FF8321'}
                mt={4}
                onClick={handleBackToLogin}
                gap={1}
              >
                <MdArrowBack />
                Go back
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default ForgetPassword;
