const FetchWithToken = async (url, method, body = null, signal = false) => {
  const controller = new AbortController();
  const jwtToken = JSON.parse(localStorage.getItem('docsbot-users')).jwt_token;
  const timeout = 25 * 60 * 1000;
  setTimeout(() => controller.abort(), timeout);
  // Set headers with the JWT token
  const headers = {
    jwtToken: jwtToken,
    'Content-Type': 'application/json',
  };

  // Request options
  const requestOptions = {
    method: method,
    headers: headers,
  };
  if (body) {
    requestOptions['body'] = JSON.stringify(body);
  }
  if (signal) {
    requestOptions['signal'] = controller.signal;
  }

  return await fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('docsbot-users');
          window.location.href = '/login';
        }
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error:', error);
      return error;
    });
};

export default FetchWithToken;
